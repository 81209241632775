import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 24px;
`;

export interface TemplateCardRowProps {
    children: React.ReactNode;
}

const TemplateCardRow: React.FC<TemplateCardRowProps> = ({ children }) => <Container>{children}</Container>;

export default TemplateCardRow;
