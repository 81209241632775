import React, { useContext, useState } from 'react';

import Dialog from '@/components/Dialog/Dialog';
import DialogFooter from '@/components/Dialog/DialogFooter';
import DialogHeader from '@/components/Dialog/DialogHeader';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { useSessionLinkForTheNativeApp } from '@/util/broadcastLinkUtils';

import ControlOptions from './controlOptions/ControlOptions';
import { CancelButton, Column, Columns, FooterActions, SaveButton } from './DeviceOptionsDialogComponents';
import PlaybackOptions from './playbackOptions/PlaybackOptions';
import { PlayerChoiceContext } from './PlayerChoiceContext';

interface DeviceOptionsDialogProps {
    sessionId: string;
    broadcastIdentifier: string;
    isPlayerAppSupported: boolean;
    isWebPlayerSupported: boolean;
}

export default function DeviceOptionsDialog({
    sessionId,
    broadcastIdentifier,
    isPlayerAppSupported,
    isWebPlayerSupported,
}: DeviceOptionsDialogProps): React.ReactElement {
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const [whereToControl, setWhereToControl] = useState(playerChoiceContext.whereToControl);
    const [whereToPlayMusic, setWhereToPlayMusic] = useState(playerChoiceContext.whereToPlayMusic);
    const [playerChoice, setPlayerChoice] = useState(playerChoiceContext.playerChoice);
    const [browserPlayerOutputDevice, setBrowserPlayerOutputDevice] = useState(
        playerChoiceContext.browserPlayerOutputDevice,
    );

    const mayBeDismissed = whereToPlayMusic !== undefined;
    const haveChanges =
        whereToControl !== playerChoiceContext.whereToControl ||
        whereToPlayMusic !== playerChoiceContext.whereToPlayMusic ||
        playerChoice !== playerChoiceContext.playerChoice ||
        browserPlayerOutputDevice !== playerChoiceContext.browserPlayerOutputDevice;

    const onCancel = () => {
        if (mayBeDismissed && haveChanges) {
            playerChoiceContext.closeDialog();
            setWhereToControl(playerChoiceContext.whereToControl);
            setWhereToPlayMusic(playerChoiceContext.whereToPlayMusic);
            setPlayerChoice(playerChoiceContext.playerChoice);
            setBrowserPlayerOutputDevice(playerChoiceContext.browserPlayerOutputDevice);
        }
    };

    const globalSnackbarContext = useContext(GlobalSnackbarContext);

    const appLink = useSessionLinkForTheNativeApp({
        broadcastIdentifier,
    });

    const launchPlayerAppSnackbar = () => {
        setTimeout(() => {
            globalSnackbarContext.setSnackbarContent(
                'Music playing in Player app',
                undefined,
                'Open',
                () => {
                    window.open(appLink, '_blank');
                },
                true,
                10000,
            );
        }, 500);
    };

    const onSave = () => {
        if (mayBeDismissed) {
            playerChoiceContext.closeDialogWithUpdate(
                {
                    whereToControl,
                    whereToPlayMusic,
                    playerChoice,
                    browserPlayerOutputDevice,
                },
                () => {
                    if (whereToPlayMusic === 'thisDevice' && playerChoice === 'native') {
                        launchPlayerAppSnackbar();
                    }
                },
            );
        }
    };

    return (
        <Dialog
            isOpen={playerChoiceContext.isDialogOpen}
            stopClickPropagation={false /* punch through to audio player permission unblockers */}
        >
            <DialogHeader text={'Controls and playback'} />
            <Columns>
                <Column>
                    <ControlOptions
                        sessionId={sessionId}
                        selectedControlOption={whereToControl}
                        onSetSelectedControlOption={setWhereToControl}
                    />
                </Column>
                <Column>
                    <PlaybackOptions
                        broadcastIdentifier={broadcastIdentifier}
                        whereToPlayMusic={whereToPlayMusic}
                        playerChoice={playerChoice}
                        browserPlayerOutputDevice={browserPlayerOutputDevice}
                        isPlayerAppSupported={isPlayerAppSupported}
                        isWebPlayerSupported={isWebPlayerSupported}
                        onSetWhereToPlayMusic={setWhereToPlayMusic}
                        onSetPlayerChoice={setPlayerChoice}
                        onSetBrowserPlayerOutputDevice={setBrowserPlayerOutputDevice}
                    />
                </Column>
            </Columns>
            <DialogFooter>
                <FooterActions>
                    <CancelButton
                        onClick={onCancel}
                        disabled={!mayBeDismissed || !haveChanges || playerChoiceContext.isInitialDialog}
                    >
                        Cancel
                    </CancelButton>
                    <SaveButton onClick={onSave} disabled={!mayBeDismissed}>
                        Save
                    </SaveButton>
                </FooterActions>
            </DialogFooter>
        </Dialog>
    );
}
