import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React from 'react';

import IconClose from '../../../src/images/icons/icon-close.svg';

const Overlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    height: 44px;
    width: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0;
    background: var(--color-background-highlight);
    border: none;
    border-radius: 22px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    color: var(--color-primary);
    cursor: pointer;
`;

const Icon = styled.span`
    display: block;
    width: 16px;
    height: 16px;
    img {
        display: block;
        width: 100%;
    }
`;

const LightboxContent = styled(motion.div)`
    background: white;
    width: 100%;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    iframe {
        width: 100%;
        height: 100%;
        border: none;
        aspect-ratio: 16 / 9;
    }
`;

interface LightboxProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Lightbox: React.FC<LightboxProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <Overlay initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={onClose}>
            <CloseButton onClick={onClose} role="button" aria-label="Close">
                <Icon>
                    <img src={IconClose} alt="Upgrade" />
                </Icon>
            </CloseButton>
            <LightboxContent
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </LightboxContent>
        </Overlay>
    );
};

export default Lightbox;
