/* tslint:disable */
/* eslint-disable */
import itly, {
    Environment,
    Event as BaseEvent,
    Properties as BaseProperties,
    Plugin as BasePlugin,
    PluginBase as BasePluginBase,
    ValidationOptions as BaseValidationOptions,
    ValidationResponse as BaseValidationResponse,
} from '@itly/sdk';
import SchemaValidatorPlugin from '@itly/plugin-schema-validator';
import MixpanelPlugin, { MixpanelOptions } from '@itly/plugin-mixpanel';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type Event = BaseEvent;
export type Properties = BaseProperties;
export interface Plugin extends BasePlugin {}
export class PluginBase extends BasePluginBase {}
export type ValidationOptions = BaseValidationOptions;
export type ValidationResponse = BaseValidationResponse;

export interface IdentifyProperties {
    /**
     * The email address of the user
     */
    $email: string;
    /**
     * The membership name of the user (i.e the name of the subscription type/product).
     */
    currentMembershipName?: string;
    /**
     * Different segments of users
     */
    groups?: string[];
    /**
     * The date when users membership starts. This is after the trial period ends.
     */
    membershipStarted?: string;
    /**
     * The membership status of the user. These are coming from Stripe
     *
     *
     * `incomplete`**,** `incomplete_expired`**,** `trialing`**,** `active`**,** `past_due`**,** `canceled`**, or** `unpaid`
     */
    membershipStatus?: string;
    /**
     * The name of the user
     */
    name: string;
    roles: string[];
    /**
     * The date for when the users trial date starts
     */
    trialStarted?: string;
    /**
     * User Id
     */
    userId: string;
}
export interface AddWaveProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
}
export interface AddWaveInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
}
export interface CancelWaveUpdateProperties {}
export interface CEASelectedProperties {
    coreEmotionalAtmosphere: string;
}
export interface CEASelectionCanceledProperties {
    coreEmotionalAtmosphere: string;
}
export interface CeaSelectionSkippedQueueProperties {
    coreEmotionalAtmosphere: string;
}
export interface ClickProperties {
    ariaLabel?: string;
    baseURI: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    componentTree?: string[];
    componentTreeText?: string;
    /**
     * unique feedback id to identify it later and get more data
     */
    id?: string;
    innerText: string;
    /**
     * Text label or text content of the HTML element
     */
    label: string;
    /**
     * The name session of the name as entered by the guide
     */
    name?: string;
    nodeName: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    x?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    y?: number;
}
export interface ClickExperimentalWaveFunctionInfoProperties {}
export interface ClickWaveTypeInfoProperties {}
export interface CloseSessionEditorModalProperties {}
export interface CloseWaveViaQueueProperties {}
export interface CloseWaveViaSparklineProperties {}
export interface CompletedSessionAudioPlayedProperties {
    sessionId: string;
}
export interface CopiedSessionProperties {
    /**
     * Indicated whether they used a feature is Completed sessions Tab or in Scheduled sessions tab
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Completed, Scheduled |
     */
    tab: 'Completed' | 'Scheduled';
}
export interface CurrentWaveExtendedProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    effectiveTimeSeconds?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    extendedBySeconds: number;
    pathScoreId: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    timeUntilWaveEndSeconds?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    waveDurationSeconds?: number;
}
export interface DepthChangeCanceledProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    depth: number;
}
export interface DepthChangeSkippedQueueProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    depth: number;
}
export interface DepthChangedProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    depth: number;
}
export interface DragsIndividualSparklinesInQueueProperties {}
export interface FeedbackRecordedProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    easeOfUseRating?: number;
    /**
     * The text feedback that the user have entered
     */
    feedback: string;
    /**
     * unique feedback id to identify it later and get more data
     */
    id?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    musicQualityRating?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 1 |
     * | Max Value | 5 |
     */
    rating: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 1 |
     * | Max Value | 5 |
     */
    technicalQualityRating?: number;
    type?: string;
}
export interface GoingOfflineProperties {}
export interface InstrumentRefreshCanceledProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Unique Items | true |
     * | Item Type | number |
     */
    layers: number[];
}
export interface InstrumentsRefreshSkippedQueueProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Unique Items | true |
     * | Item Type | number |
     */
    layers: number[];
}
export interface InstrumentsRefreshedProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Unique Items | true |
     * | Item Type | object |
     */
    layers: {
        [k: string]: unknown;
    }[];
}
export interface IntegrationLinkCopiedProperties {
    sessionId: string;
}
export interface IntegrationLinkOpenedProperties {
    sessionId: string;
}
export interface JumpedRightInProperties {}
export interface MoveWaveDownProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface MoveWaveDownInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface MoveWaveUpProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * index of a wave moved
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface MoveWaveUpInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface NewSessionFromTemplateClickedProperties {}
export interface OpenSessionEditorModalProperties {}
export interface OpenWaveViaQueueProperties {}
export interface OpenWaveViaSparklineProperties {}
export interface OverwriteSettingsDialogTriggeredInEditorProperties {}
export interface PageViewedProperties {
    /**
     * Some page locations contain IDs and parameters like for example "/logs/<log id here>". This field contain such parameter if it exists
     */
    parameter?: string;
    /**
     * The path of page viewed (for example /login
     */
    path: string;
}
export interface RemoveWaveProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * index of a wave removed (which one it is in the wave queue)
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface RemoveWaveInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface SessionCanceledProperties {
    /**
     * Session duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration: number;
    /**
     * The ID identifying which intro session was played
     */
    introSessionId?: string;
    /**
     * Name of the medicine
     *
     * Examples:
     * ketamine, mdma
     */
    medicine?: string;
    /**
     * Musical preference of the guide
     *
     * Examples:
     * 'gentle, peaceful and grounding`
     */
    musicalPreference: string;
    /**
     * The name session of the name as entered by the guide
     */
    name?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | realTime, preRendered |
     */
    renderType?: 'realTime' | 'preRendered';
    /**
     * The date, time of the session if scheduled
     */
    scheduleTime?: string;
    /**
     * Whether the session is to start now, at a specified time in the future, or unspecified
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | now, later, specificTime |
     */
    scheduleType?: 'now' | 'later' | 'specificTime';
    /**
     * session completness percentage 1-100
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    sessionCompletness?: number;
    /**
     * The time that session was ended
     */
    sessionEndTime?: string;
    /**
     * The template that the guide selects
     *
     * Examples:
     * Mixed Acoustic / Electronic
     */
    therapeuticTemplate?: string;
    /**
     * A type of a session (eg oneOnOne, intro)
     */
    type?: string;
    /**
     * How is the session going to be used by the guide
     *
     * Examples:
     * Testing, Remote Client
     */
    use: string;
}
export interface SessionCompletedProperties {
    /**
     * Session duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration: number;
    /**
     * The ID identifying which intro session was played
     */
    introSessionId?: string;
    /**
     * Name of the medicine
     *
     * Examples:
     * ketamine, mdma
     */
    medicine?: string;
    /**
     * Musical preference of the guide
     *
     * Examples:
     * 'gentle, peaceful and grounding`
     */
    musicalPreference: string;
    /**
     * The name session of the name as entered by the guide
     */
    name?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | realTime, preRendered |
     */
    renderType?: 'realTime' | 'preRendered';
    /**
     * The date, time of the session if scheduled
     */
    scheduleTime?: string;
    /**
     * Whether the session is to start now, at a specified time in the future, or unspecified
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | now, later, specificTime |
     */
    scheduleType?: 'now' | 'later' | 'specificTime';
    /**
     * session completness percentage 1-100
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    sessionCompletness?: number;
    /**
     * The template that the guide selects
     *
     * Examples:
     * Mixed Acoustic / Electronic
     */
    therapeuticTemplate?: string;
    /**
     * A type of a session (eg oneOnOne, intro)
     */
    type?: string;
    /**
     * How is the session going to be used by the guide
     *
     * Examples:
     * Testing, Remote Client
     */
    use: string;
}
export interface SessionCreatedProperties {
    /**
     * Session duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration: number;
    /**
     * The ID identifying which intro session was played
     */
    introSessionId?: string;
    /**
     * Has user change session score? Has template been customised by the user? Or is he/she just using default one
     */
    isTemplateCustom: boolean;
    /**
     * Name of the medicine
     *
     * Examples:
     * ketamine, mdma
     */
    medicine?: string;
    /**
     * Musical preference of the guide
     *
     * Examples:
     * 'gentle, peaceful and grounding`
     */
    musicalPreference: string;
    /**
     * The name session of the name as entered by the guide
     */
    name?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | realTime, preRendered |
     */
    renderType?: 'realTime' | 'preRendered';
    /**
     * The date, time of the session if scheduled
     */
    scheduleTime?: string;
    /**
     * Whether the session is to start now, at a specified time in the future, or unspecified
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | now, later, specificTime |
     */
    scheduleType?: 'now' | 'later' | 'specificTime';
    /**
     * session completness percentage 1-100
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    sessionCompletness?: number;
    /**
     * The template that the guide selects
     *
     * Examples:
     * Mixed Acoustic / Electronic
     */
    therapeuticTemplate?: string;
    /**
     * A type of a session (eg oneOnOne, intro)
     */
    type?: string;
    /**
     * How is the session going to be used by the guide
     *
     * Examples:
     * Testing, Remote Client
     */
    use: string;
}
export interface SessionStartedProperties {
    /**
     * Session duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration: number;
    /**
     * The ID identifying which intro session was played
     */
    introSessionId?: string;
    /**
     * Name of the medicine
     *
     * Examples:
     * ketamine, mdma
     */
    medicine?: string;
    /**
     * Musical preference of the guide
     *
     * Examples:
     * 'gentle, peaceful and grounding`
     */
    musicalPreference: string;
    /**
     * The name session of the name as entered by the guide
     */
    name?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | realTime, preRendered |
     */
    renderType?: 'realTime' | 'preRendered';
    /**
     * The date, time of the session if scheduled
     */
    scheduleTime?: string;
    /**
     * Whether the session is to start now, at a specified time in the future, or unspecified
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | now, later, specificTime |
     */
    scheduleType?: 'now' | 'later' | 'specificTime';
    /**
     * session completness percentage 1-100
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    sessionCompletness?: number;
    /**
     * The template that the guide selects
     *
     * Examples:
     * Mixed Acoustic / Electronic
     */
    therapeuticTemplate?: string;
    /**
     * A type of a session (eg oneOnOne, intro)
     */
    type?: string;
    /**
     * How is the session going to be used by the guide
     *
     * Examples:
     * Testing, Remote Client
     */
    use: string;
}
export interface SessionTemplateCreatedProperties {}
export interface SessionTemplateDeletedProperties {}
export interface SessionTemplateSelectedProperties {}
export interface SignInProperties {}
export interface SignOutProperties {}
export interface SignUpProperties {}
export interface SkipQueueWaveUpdateProperties {}
export interface SkipToWaveProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    lostMinutes: number;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface StreamingNetworkGlitchProperties {}
export interface SubscribeClickedProperties {
    priceId: string;
}
export interface UpdateWaveProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Deepen, R&D, Release, Soothe, Activate, Bridge, Silence, Guide |
     */
    newDirection?: 'Deepen' | 'R&D' | 'Release' | 'Soothe' | 'Activate' | 'Bridge' | 'Silence' | 'Guide';
    newDuration?: string;
    newEmotion?: string;
    newIntensity?: string;
    newName?: string;
    newPathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Generative, Curated, Experimental |
     */
    newType?: 'Generative' | 'Curated' | 'Experimental';
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, Release, Soothe, R&D, Bridge, Silence, Guide |
     */
    oldDirection?: 'Activate' | 'Deepen' | 'Release' | 'Soothe' | 'R&D' | 'Bridge' | 'Silence' | 'Guide';
    oldDuration?: string;
    oldEmotion?: string;
    oldIntensity?: string;
    oldName?: string;
    oldPathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Generative, Curated, Experimental |
     */
    oldType?: 'Generative' | 'Curated' | 'Experimental';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
}
export interface UpdateWaveInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Deepen, R&D, Release, Soothe, Activate, Bridge, Silence, Guide |
     */
    newDirection?: 'Deepen' | 'R&D' | 'Release' | 'Soothe' | 'Activate' | 'Bridge' | 'Silence' | 'Guide';
    newDuration?: string;
    newEmotion?: string;
    newIntensity?: string;
    newName?: string;
    newPathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Generative, Curated, Experimental |
     */
    newType?: 'Generative' | 'Curated' | 'Experimental';
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Deepen, R&D, Release, Soothe, Activate, Bridge, Silence, Guide |
     */
    oldDirection?: 'Deepen' | 'R&D' | 'Release' | 'Soothe' | 'Activate' | 'Bridge' | 'Silence' | 'Guide';
    oldDuration?: string;
    oldEmotion?: string;
    oldIntensity?: string;
    oldName?: string;
    oldPathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Generative, Curated, Experimental |
     */
    oldType?: 'Generative' | 'Curated' | 'Experimental';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
}
export interface WaveCannotBeMovedFurtherDownProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface WaveCannotBeMovedFurtherDownInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface WaveCannotBeMovedFurtherUpProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface WaveCannotBeMovedFurtherUpInEditorProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Activate, Deepen, R&D, Soothe, Release, Bridge, Silence, Guide |
     */
    direction?: 'Activate' | 'Deepen' | 'R&D' | 'Soothe' | 'Release' | 'Bridge' | 'Silence' | 'Guide';
    /**
     * duration in minutes
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     *
     * Examples:
     * 28
     */
    duration?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Stillness, Bittersweet, Tension, Vitality, Dreamy, Silence |
     */
    emotion?: 'Stillness' | 'Bittersweet' | 'Tension' | 'Vitality' | 'Dreamy' | 'Silence';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    fromTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    index: number;
    intensity?: string;
    name?: string;
    pathId?: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | pre, post, scheduled |
     */
    phase: 'pre' | 'post' | 'scheduled';
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    totalNumberOfWavesInSession: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    toTime?: number;
    /**
     * | Rule | Value |
     * |---|---|
     * | Enum Values | Curated, Generative, Experimental |
     */
    type: 'Curated' | 'Generative' | 'Experimental';
    /**
     * duration set by user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userSetDuration?: number;
}
export interface WaveSkippedProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    waveIndex?: number;
}

export class AddWave implements Event {
    name = 'Add Wave';
    id = 'bcefa9c7-864c-4cf1-89e5-0312e97b0edb';
    version = '4.0.0';
    constructor(public properties: AddWaveProperties) {}
}

export class AddWaveInEditor implements Event {
    name = 'Add Wave In Editor';
    id = 'fedf246f-00cf-4310-8966-0fe3e60afedf';
    version = '1.0.0';
    constructor(public properties: AddWaveInEditorProperties) {}
}

export class CancelWaveUpdate implements Event {
    name = 'Cancel Wave Update';
    id = '960d546f-3c0e-40fe-9fbd-9efbce86e120';
    version = '1.0.0';
}

export class CeaSelected implements Event {
    name = 'CEA Selected';
    id = '1e2c1d35-1f1f-4b90-a145-925d287ab75e';
    version = '1.0.0';
    constructor(public properties: CEASelectedProperties) {}
}

export class CeaSelectionCanceled implements Event {
    name = 'CEA Selection Canceled';
    id = '654c1db8-c889-44f6-a9b0-f2b70753770e';
    version = '1.0.0';
    constructor(public properties: CEASelectionCanceledProperties) {}
}

export class CeaSelectionSkippedQueue implements Event {
    name = 'Cea Selection Skipped Queue';
    id = '145ffa12-a1bf-4cdf-ae11-1fab0b44bfe3';
    version = '1.0.0';
    constructor(public properties: CeaSelectionSkippedQueueProperties) {}
}

export class Click implements Event {
    name = 'Click';
    id = 'e5bcd8ff-8e72-4347-8d9e-f2d6bc450b96';
    version = '0.0.1';
    constructor(public properties: ClickProperties) {}
}

export class ClickExperimentalWaveFunctionInfo implements Event {
    name = 'Click Experimental Wave Function Info';
    id = '37f6730b-8a08-4251-86da-761961867a18';
    version = '1.0.0';
}

export class ClickWaveTypeInfo implements Event {
    name = 'Click Wave Type Info';
    id = 'fd186ba5-7cb2-445e-8815-cc9ddf8891d3';
    version = '1.0.0';
}

export class CloseSessionEditorModal implements Event {
    name = 'Close Session Editor Modal';
    id = '9fcbda27-8f96-42f8-be44-d60b5a114b66';
    version = '1.0.0';
}

export class CloseWaveViaQueue implements Event {
    name = 'Close Wave Via Queue';
    id = '48fa6fa1-acc2-44f2-bfd0-ccc942949450';
    version = '1.0.0';
}

export class CloseWaveViaSparkline implements Event {
    name = 'Close Wave Via Sparkline';
    id = 'c1332a86-030a-4d6e-b57a-9e42eed0f96d';
    version = '2.0.0';
}

export class CompletedSessionAudioPlayed implements Event {
    name = 'Completed Session Audio Played';
    id = 'ea307276-074b-4630-8bfa-73d84f03eb0a';
    version = '1.0.0';
    constructor(public properties: CompletedSessionAudioPlayedProperties) {}
}

export class CopiedSession implements Event {
    name = 'Copied Session';
    id = '4d245555-f18b-4da1-a09d-9460453e11a8';
    version = '1.0.0';
    constructor(public properties: CopiedSessionProperties) {}
}

export class CurrentWaveExtended implements Event {
    name = 'Current Wave Extended';
    id = '799dcbc3-8982-4a26-8544-de0a1dc11a3e';
    version = '2.0.0';
    constructor(public properties: CurrentWaveExtendedProperties) {}
}

export class DepthChangeCanceled implements Event {
    name = 'Depth Change Canceled';
    id = 'a280f814-4071-4fcf-b459-1cce19496e53';
    version = '1.0.0';
    constructor(public properties: DepthChangeCanceledProperties) {}
}

export class DepthChangeSkippedQueue implements Event {
    name = 'Depth Change Skipped Queue';
    id = '80b4de55-d398-42f3-a9a8-5b5bad56896b';
    version = '1.0.0';
    constructor(public properties: DepthChangeSkippedQueueProperties) {}
}

export class DepthChanged implements Event {
    name = 'Depth Changed';
    id = 'cd3e2a72-009c-4cf2-9589-d64233a89371';
    version = '1.0.0';
    constructor(public properties: DepthChangedProperties) {}
}

export class DragsIndividualSparklinesInQueue implements Event {
    name = 'Drags Individual Sparklines In Queue';
    id = '918219f5-7afd-4b0a-912d-1d5c468bdd27';
    version = '1.0.0';
}

export class FeedbackRecorded implements Event {
    name = 'Feedback Recorded';
    id = '969b6bf8-ffce-4ad1-bf15-5ebeedefc05c';
    version = '4.0.0';
    constructor(public properties: FeedbackRecordedProperties) {}
}

export class GoingOffline implements Event {
    name = 'Going Offline';
    id = '3b3c1aff-7e27-4d44-be7b-4e2633602419';
    version = '1.0.0';
}

export class InstrumentRefreshCanceled implements Event {
    name = 'Instrument Refresh Canceled';
    id = 'ac43c2dc-1759-4b2f-906a-5f4bc645f08e';
    version = '1.0.0';
    constructor(public properties: InstrumentRefreshCanceledProperties) {}
}

export class InstrumentsRefreshSkippedQueue implements Event {
    name = 'Instruments Refresh Skipped Queue';
    id = 'eb3b94eb-a985-402d-88c2-bf3e25742be0';
    version = '1.0.0';
    constructor(public properties: InstrumentsRefreshSkippedQueueProperties) {}
}

export class InstrumentsRefreshed implements Event {
    name = 'Instruments Refreshed';
    id = '5d6481d1-8126-4589-9824-f1f756b73cd2';
    version = '3.0.0';
    constructor(public properties: InstrumentsRefreshedProperties) {}
}

export class IntegrationLinkCopied implements Event {
    name = 'Integration Link Copied';
    id = '05a962f0-4ef8-4cdb-bb09-922f0bcfe077';
    version = '1.0.0';
    constructor(public properties: IntegrationLinkCopiedProperties) {}
}

export class IntegrationLinkOpened implements Event {
    name = 'Integration Link Opened';
    id = '8d8c3101-418a-4b65-93f4-a20b9c5b9f01';
    version = '1.0.0';
    constructor(public properties: IntegrationLinkOpenedProperties) {}
}

export class JumpedRightIn implements Event {
    name = 'Jumped Right In';
    id = '0f34699b-fc6d-4813-b5cd-d1817fffc3b7';
    version = '1.0.0';
}

export class MoveWaveDown implements Event {
    name = 'Move Wave Down';
    id = 'fb7b5e76-7012-4c9a-a084-5be952547948';
    version = '6.0.0';
    constructor(public properties: MoveWaveDownProperties) {}
}

export class MoveWaveDownInEditor implements Event {
    name = 'Move Wave Down In Editor';
    id = '8dd70ef1-8e19-4c5e-86cf-a1f142414c14';
    version = '4.0.0';
    constructor(public properties: MoveWaveDownInEditorProperties) {}
}

export class MoveWaveUp implements Event {
    name = 'Move Wave Up';
    id = 'a95da5b5-7830-4742-955b-e7a33d8dc943';
    version = '6.0.0';
    constructor(public properties: MoveWaveUpProperties) {}
}

export class MoveWaveUpInEditor implements Event {
    name = 'Move Wave Up In Editor';
    id = '9d093367-fd15-48de-b39b-8b208e26ac1e';
    version = '4.0.0';
    constructor(public properties: MoveWaveUpInEditorProperties) {}
}

export class NewSessionFromTemplateClicked implements Event {
    name = 'New Session From Template Clicked';
    id = 'ee31497c-f6d7-423c-ac09-8ba4f2a4fb82';
    version = '1.0.0';
}

export class OpenSessionEditorModal implements Event {
    name = 'Open Session Editor Modal';
    id = 'c5b287b7-4878-47f8-b2cb-2a551129d7d2';
    version = '1.0.0';
}

export class OpenWaveViaQueue implements Event {
    name = 'Open Wave Via Queue';
    id = '2c287414-7d1e-4184-b911-4e2663136c10';
    version = '1.0.0';
}

export class OpenWaveViaSparkline implements Event {
    name = 'Open Wave Via Sparkline';
    id = 'c5117dcf-c381-4f5b-a779-39b5ebc01ea2';
    version = '2.0.0';
}

export class OverwriteSettingsDialogTriggeredInEditor implements Event {
    name = 'Overwrite Settings Dialog Triggered In Editor';
    id = '6f5a7837-0646-41a1-83f9-da7386fe9948';
    version = '1.0.0';
}

export class PageViewed implements Event {
    name = 'Page Viewed';
    id = '06f4fe90-2872-47b2-84df-e8cf741eb004';
    version = '3.0.0';
    constructor(public properties: PageViewedProperties) {}
}

export class RemoveWave implements Event {
    name = 'Remove Wave';
    id = '68c4f838-99d9-4812-b861-3e0b130f6c61';
    version = '6.0.0';
    constructor(public properties: RemoveWaveProperties) {}
}

export class RemoveWaveInEditor implements Event {
    name = 'Remove Wave In Editor';
    id = 'efa8cd67-7e69-489b-9638-63ce7a3ff0ab';
    version = '4.0.0';
    constructor(public properties: RemoveWaveInEditorProperties) {}
}

export class SessionCanceled implements Event {
    name = 'Session Canceled';
    id = '47f11d26-1eec-4159-8766-b14339c308aa';
    version = '4.0.2';
    constructor(public properties: SessionCanceledProperties) {}
}

export class SessionCompleted implements Event {
    name = 'Session Completed';
    id = 'e89ca881-0c93-41a9-a766-5cf6c3320d48';
    version = '4.0.2';
    constructor(public properties: SessionCompletedProperties) {}
}

export class SessionCreated implements Event {
    name = 'Session Created';
    id = '09aee1a3-315d-46dc-906b-e5bc0d6fc9dd';
    version = '3.0.1';
    constructor(public properties: SessionCreatedProperties) {}
}

export class SessionStarted implements Event {
    name = 'Session Started';
    id = '10b8d901-95c3-4036-aeec-cabf0053c3bc';
    version = '4.0.2';
    constructor(public properties: SessionStartedProperties) {}
}

export class SessionTemplateCreated implements Event {
    name = 'Session Template Created';
    id = '6b9c2b49-ab52-4431-9480-34bd7b452dcc';
    version = '1.0.0';
}

export class SessionTemplateDeleted implements Event {
    name = 'Session Template Deleted';
    id = '80b03053-322e-42a6-9d0b-fd572b006f17';
    version = '1.0.0';
}

export class SessionTemplateSelected implements Event {
    name = 'Session Template Selected';
    id = 'b8036b38-6038-438e-bf13-9002ea55ba91';
    version = '1.0.0';
}

export class SignIn implements Event {
    name = 'Sign In';
    id = 'd031f5cb-9f77-46f6-97c9-ee6cc5dd19e1';
    version = '1.0.0';
}

export class SignOut implements Event {
    name = 'Sign Out';
    id = 'f35e8967-5bbc-4899-a52a-1eb72085e3d5';
    version = '1.0.1';
}

export class SignUp implements Event {
    name = 'Sign Up';
    id = '5d778fac-a353-4993-a9e5-bb4b5b65e025';
    version = '1.0.0';
}

export class SkipQueueWaveUpdate implements Event {
    name = 'Skip Queue Wave Update';
    id = 'e0591dd2-18f5-4519-ab0f-14dc8a73fde5';
    version = '1.0.0';
}

export class SkipToWave implements Event {
    name = 'Skip To Wave';
    id = '80b0c6b1-f910-4eec-a6df-e93ec6468162';
    version = '7.0.0';
    constructor(public properties: SkipToWaveProperties) {}
}

export class StreamingNetworkGlitch implements Event {
    name = 'Streaming Network Glitch';
    id = '3dd796d8-f4fc-4ef1-afdf-f585b30ca0f7';
    version = '1.0.0';
}

export class SubscribeClicked implements Event {
    name = 'Subscribe Clicked';
    id = '89b101ba-a109-4b94-bcdf-c75b31b5d4bb';
    version = '2.0.0';
    constructor(public properties: SubscribeClickedProperties) {}
}

export class UpdateWave implements Event {
    name = 'Update Wave';
    id = '9da0ec6c-059d-447f-90ec-127c067a42af';
    version = '11.0.0';
    constructor(public properties: UpdateWaveProperties) {}
}

export class UpdateWaveInEditor implements Event {
    name = 'Update Wave In Editor';
    id = '850c8f40-0447-461e-b173-4b715f812902';
    version = '5.0.0';
    constructor(public properties: UpdateWaveInEditorProperties) {}
}

export class WaveCannotBeMovedFurtherDown implements Event {
    name = 'Wave Cannot Be Moved Further Down';
    id = 'f1cd4a2b-cf20-4344-b410-1a851c6ae49a';
    version = '6.0.0';
    constructor(public properties: WaveCannotBeMovedFurtherDownProperties) {}
}

export class WaveCannotBeMovedFurtherDownInEditor implements Event {
    name = 'Wave Cannot Be Moved Further Down In Editor';
    id = '3ea004c0-464f-4584-b78d-24a4bdf505d5';
    version = '4.0.0';
    constructor(public properties: WaveCannotBeMovedFurtherDownInEditorProperties) {}
}

export class WaveCannotBeMovedFurtherUp implements Event {
    name = 'Wave Cannot Be Moved Further Up';
    id = '568c34e8-7e9e-42a1-a910-e51bdcd38236';
    version = '6.0.0';
    constructor(public properties: WaveCannotBeMovedFurtherUpProperties) {}
}

export class WaveCannotBeMovedFurtherUpInEditor implements Event {
    name = 'Wave Cannot Be Moved Further Up In Editor';
    id = 'a8895106-ba0c-4e37-a51f-ec18d1518a30';
    version = '4.0.0';
    constructor(public properties: WaveCannotBeMovedFurtherUpInEditorProperties) {}
}

export class WaveSkipped implements Event {
    name = 'Wave Skipped';
    id = '223194b3-967e-4c65-87f2-f401d3ecc96c';
    version = '2.0.0';
    constructor(public properties: WaveSkippedProperties) {}
}

// prettier-ignore
export interface Options {
  /**
   * The current environment (development or production). Default is development.
   */
  environment?: Environment;
  /**
   * Whether calls to the Itly SDK should be no-ops. Default is false.
   */
  disabled?: boolean;
  /**
   * Analytics provider-specific configuration. Default is null.
   */
  plugins?: Plugin[];
  /**
   * Configure validation handling
   */
  validation?: ValidationOptions;
  /**
   * Analytics provider-specific configuration. Default is null.
   */
  destinations?: {
    mixpanel?: MixpanelOptions;
    all?: {
      disabled?: boolean;
    };
  };
}

// prettier-ignore
class Itly {
  load(options: Options) {
    const {
      destinations = {},
      plugins = [],
      ...baseOptions
    } = options;

    (window as any).MIXPANEL_CUSTOM_LIB_URL = "/vendor/mixpanel-2-latest.min.js";


    const destinationPlugins = destinations.all && destinations.all.disabled
      ? []
      : [
        
        new MixpanelPlugin(options.environment === 'production'
          ? '2544073dcdb30dd286b0d6e3d3879f31'
          : 'd4f754536b6dfb0b00388f4007177c9d',
          destinations.mixpanel,
        ),
      ];

    itly.load({
      ...baseOptions,
      plugins: [
        new SchemaValidatorPlugin({
          'identify': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/identify","$schema":"http://json-schema.org/draft-07/schema#","title":"Identify","description":"","type":"object","properties":{"$email":{"description":"The email address of the user","type":"string"},"currentMembershipName":{"description":"The membership name of the user (i.e the name of the subscription type/product).","type":"string"},"groups":{"items":{"type":"string"},"uniqueItems":true,"description":"Different segments of users","type":"array"},"membershipStarted":{"description":"The date when users membership starts. This is after the trial period ends.","type":"string"},"membershipStatus":{"description":"The membership status of the user. These are coming from Stripe\n\n\n`incomplete`**,** `incomplete_expired`**,** `trialing`**,** `active`**,** `past_due`**,** `canceled`**, or** `unpaid`","type":"string"},"name":{"description":"The name of the user","type":"string"},"roles":{"items":{"type":"string"},"uniqueItems":true,"description":"","type":"array"},"trialStarted":{"description":"The date for when the users trial date starts","type":"string"},"userId":{"description":"User Id","type":"string"}},"additionalProperties":false,"required":["$email","name","roles","userId"]},
          'Add Wave': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Add%20Wave/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Add Wave","description":"Adding new wave in the session view (through upcoming wave queue)","type":"object","properties":{"index":{"type":"integer","description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""}},"additionalProperties":false,"required":["index","totalNumberOfWavesInSession"]},
          'Add Wave In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Add%20Wave%20In%20Editor/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Add Wave In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"index":{"type":"integer","description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""}},"additionalProperties":false,"required":["index","totalNumberOfWavesInSession"]},
          'Cancel Wave Update': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Cancel%20Wave%20Update/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Cancel Wave Update","description":"When wave update is pending user cancels it","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'CEA Selected': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/CEA%20Selected/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"CEA Selected","description":"Owner: Chris Yeoward","type":"object","properties":{"coreEmotionalAtmosphere":{"description":"","type":"string"}},"additionalProperties":false,"required":["coreEmotionalAtmosphere"]},
          'CEA Selection Canceled': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/CEA%20Selection%20Canceled/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"CEA Selection Canceled","description":"Owner: Tero Parviainen","type":"object","properties":{"coreEmotionalAtmosphere":{"description":"","type":"string"}},"additionalProperties":false,"required":["coreEmotionalAtmosphere"]},
          'Cea Selection Skipped Queue': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Cea%20Selection%20Skipped%20Queue/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Cea Selection Skipped Queue","description":"Owner: Mikolaj Torz","type":"object","properties":{"coreEmotionalAtmosphere":{"description":"","type":"string"}},"additionalProperties":false,"required":["coreEmotionalAtmosphere"]},
          'Click': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Click/version/0.0.1","$schema":"http://json-schema.org/draft-07/schema#","title":"Click","description":"General click on HTML element","type":"object","properties":{"ariaLabel":{"description":"","type":"string"},"baseURI":{"description":"","type":"string"},"componentTree":{"items":{"type":"string"},"description":"","type":"array"},"componentTreeText":{"description":"","type":"string"},"id":{"description":"unique feedback id to identify it later and get more data","type":"string"},"innerText":{"description":"","type":"string"},"label":{"description":"Text label or text content of the HTML element","type":"string"},"name":{"description":"The name session of the name as entered by the guide","type":"string"},"nodeName":{"description":"","type":"string"},"x":{"type":"integer","description":""},"y":{"type":"integer","description":""}},"additionalProperties":false,"required":["baseURI","innerText","label","nodeName"]},
          'Click Experimental Wave Function Info': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Click%20Experimental%20Wave%20Function%20Info/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Click Experimental Wave Function Info","description":"When a user opens the Info dialog in the Experimental table of a wave card","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Click Wave Type Info': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Click%20Wave%20Type%20Info/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Click Wave Type Info","description":"Owner: Chris Yeoward","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Close Session Editor Modal': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Close%20Session%20Editor%20Modal/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Close Session Editor Modal","description":"Owner: Rafal Pastuszak","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Close Wave Via Queue': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Close%20Wave%20Via%20Queue/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Close Wave Via Queue","description":"closes wave by pressing on it in the queue","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Close Wave Via Sparkline': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Close%20Wave%20Via%20Sparkline/version/2.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Close Wave Via Sparkline","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Completed Session Audio Played': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Completed%20Session%20Audio%20Played/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Completed Session Audio Played","description":"Owner: Chris Yeoward","type":"object","properties":{"sessionId":{"description":"","type":"string"}},"additionalProperties":false,"required":["sessionId"]},
          'Copied Session': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Copied%20Session/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Copied Session","description":"User pressed on 'Make A Copy Of This Session' button","type":"object","properties":{"tab":{"enum":["Completed","Scheduled"],"description":"Indicated whether they used a feature is Completed sessions Tab or in Scheduled sessions tab"}},"additionalProperties":false,"required":["tab"]},
          'Current Wave Extended': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Current%20Wave%20Extended/version/2.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Current Wave Extended","description":"Owner: Maximilian Schnupp","type":"object","properties":{"effectiveTimeSeconds":{"description":"","type":"number"},"extendedBySeconds":{"description":"","type":"number"},"pathScoreId":{"description":"","type":"string"},"timeUntilWaveEndSeconds":{"description":"","type":"number"},"waveDurationSeconds":{"description":"","type":"number"}},"additionalProperties":false,"required":["extendedBySeconds","pathScoreId"]},
          'Depth Change Canceled': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Depth%20Change%20Canceled/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Depth Change Canceled","description":"Owner: Tero Parviainen","type":"object","properties":{"depth":{"type":"integer","description":""}},"additionalProperties":false,"required":["depth"]},
          'Depth Change Skipped Queue': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Depth%20Change%20Skipped%20Queue/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Depth Change Skipped Queue","description":"Owner: Mikolaj Torz","type":"object","properties":{"depth":{"type":"integer","description":""}},"additionalProperties":false,"required":["depth"]},
          'Depth Changed': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Depth%20Changed/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Depth Changed","description":"Owner: Chris Yeoward","type":"object","properties":{"depth":{"type":"integer","description":""}},"additionalProperties":false,"required":["depth"]},
          'Drags Individual Sparklines In Queue': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Drags%20Individual%20Sparklines%20In%20Queue/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Drags Individual Sparklines In Queue","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Feedback Recorded': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Feedback%20Recorded/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Feedback Recorded","description":"The feedback that user enters during a session","type":"object","properties":{"easeOfUseRating":{"description":"","type":"number"},"feedback":{"description":"The text feedback that the user have entered","type":"string"},"id":{"description":"unique feedback id to identify it later and get more data","type":"string"},"musicQualityRating":{"description":"","type":"number"},"rating":{"type":"integer","maximum":5,"minimum":1,"description":""},"technicalQualityRating":{"type":"integer","maximum":5,"minimum":1,"description":""},"type":{"description":"","type":"string"}},"additionalProperties":false,"required":["feedback","rating"]},
          'Going Offline': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Going%20Offline/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Going Offline","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Instrument Refresh Canceled': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Instrument%20Refresh%20Canceled/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Instrument Refresh Canceled","description":"Owner: Tero Parviainen","type":"object","properties":{"layers":{"items":{"type":"number"},"uniqueItems":true,"description":"","type":"array"}},"additionalProperties":false,"required":["layers"]},
          'Instruments Refresh Skipped Queue': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Instruments%20Refresh%20Skipped%20Queue/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Instruments Refresh Skipped Queue","description":"Owner: Mikolaj Torz","type":"object","properties":{"layers":{"items":{"type":"number"},"uniqueItems":true,"description":"","type":"array"}},"additionalProperties":false,"required":["layers"]},
          'Instruments Refreshed': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Instruments%20Refreshed/version/3.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Instruments Refreshed","description":"Owner: Chris Yeoward","type":"object","properties":{"layers":{"items":{"type":"object"},"uniqueItems":true,"description":"","type":"array"}},"additionalProperties":false,"required":["layers"]},
          'Integration Link Copied': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Integration%20Link%20Copied/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Integration Link Copied","description":"Owner: Maximilian Schnupp","type":"object","properties":{"sessionId":{"description":"","type":"string"}},"additionalProperties":false,"required":["sessionId"]},
          'Integration Link Opened': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Integration%20Link%20Opened/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Integration Link Opened","description":"Owner: Maximilian Schnupp","type":"object","properties":{"sessionId":{"description":"","type":"string"}},"additionalProperties":false,"required":["sessionId"]},
          'Jumped Right In': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Jumped%20Right%20In/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Jumped Right In","description":"Pressed the jump right in button","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Move Wave Down': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Move%20Wave%20Down/version/6.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Move Wave Down","description":"Moves wave down in the queue","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Move Wave Down In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Move%20Wave%20Down%20In%20Editor/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Move Wave Down In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Move Wave Up': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Move%20Wave%20Up/version/6.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Move Wave Up","description":"Moves wave up in a queue","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":"index of a wave moved"},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Move Wave Up In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Move%20Wave%20Up%20In%20Editor/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Move Wave Up In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'New Session From Template Clicked': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/New%20Session%20From%20Template%20Clicked/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"New Session From Template Clicked","description":"Owner: Chris Yeoward","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Open Session Editor Modal': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Open%20Session%20Editor%20Modal/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Open Session Editor Modal","description":"Owner: Rafal Pastuszak","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Open Wave Via Queue': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Open%20Wave%20Via%20Queue/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Open Wave Via Queue","description":"Open wave by pressing on it in the queue","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Open Wave Via Sparkline': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Open%20Wave%20Via%20Sparkline/version/2.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Open Wave Via Sparkline","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Overwrite Settings Dialog Triggered In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Overwrite%20Settings%20Dialog%20Triggered%20In%20Editor/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Overwrite Settings Dialog Triggered In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Page Viewed': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Page%20Viewed/version/3.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Page Viewed","description":"Triggers when a page loads. No additional tracking is required for this as the Tag Manager does this for us","type":"object","properties":{"parameter":{"description":"Some page locations contain IDs and parameters like for example \"/logs/<log id here>\". This field contain such parameter if it exists","type":"string"},"path":{"description":"The path of page viewed (for example /login","type":"string"}},"additionalProperties":false,"required":["path"]},
          'Remove Wave': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Remove%20Wave/version/6.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Remove Wave","description":"Removing a wave in the session view (through upcoming wave queue)","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":"index of a wave removed (which one it is in the wave queue)"},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Remove Wave In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Remove%20Wave%20In%20Editor/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Remove Wave In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Session Canceled': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Canceled/version/4.0.2","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Canceled","description":"When the user clicks on the X to end the session","type":"object","properties":{"duration":{"description":"Session duration in minutes","type":"number"},"introSessionId":{"description":"The ID identifying which intro session was played","type":"string"},"medicine":{"description":"Name of the medicine","type":"string"},"musicalPreference":{"description":"Musical preference of the guide","type":"string"},"name":{"description":"The name session of the name as entered by the guide","type":"string"},"renderType":{"enum":["realTime","preRendered"],"description":""},"scheduleTime":{"description":"The date, time of the session if scheduled","type":"string"},"scheduleType":{"enum":["now","later","specificTime"],"description":"Whether the session is to start now, at a specified time in the future, or unspecified"},"sessionCompletness":{"type":"integer","description":"session completness percentage 1-100"},"sessionEndTime":{"description":"The time that session was ended","type":"string"},"therapeuticTemplate":{"description":"The template that the guide selects","type":"string"},"type":{"description":"A type of a session (eg oneOnOne, intro)","type":"string"},"use":{"description":"How is the session going to be used by the guide","type":"string"}},"additionalProperties":false,"required":["duration","musicalPreference","use"]},
          'Session Completed': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Completed/version/4.0.2","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Completed","description":"When the session is finished","type":"object","properties":{"duration":{"description":"Session duration in minutes","type":"number"},"introSessionId":{"description":"The ID identifying which intro session was played","type":"string"},"medicine":{"description":"Name of the medicine","type":"string"},"musicalPreference":{"description":"Musical preference of the guide","type":"string"},"name":{"description":"The name session of the name as entered by the guide","type":"string"},"renderType":{"enum":["realTime","preRendered"],"description":""},"scheduleTime":{"description":"The date, time of the session if scheduled","type":"string"},"scheduleType":{"enum":["now","later","specificTime"],"description":"Whether the session is to start now, at a specified time in the future, or unspecified"},"sessionCompletness":{"type":"integer","description":"session completness percentage 1-100"},"therapeuticTemplate":{"description":"The template that the guide selects","type":"string"},"type":{"description":"A type of a session (eg oneOnOne, intro)","type":"string"},"use":{"description":"How is the session going to be used by the guide","type":"string"}},"additionalProperties":false,"required":["duration","musicalPreference","use"]},
          'Session Created': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Created/version/3.0.1","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Created","description":"When a user creates a session through the session planner","type":"object","properties":{"duration":{"description":"Session duration in minutes","type":"number"},"introSessionId":{"description":"The ID identifying which intro session was played","type":"string"},"isTemplateCustom":{"description":"Has user change session score? Has template been customised by the user? Or is he/she just using default one","type":"boolean"},"medicine":{"description":"Name of the medicine","type":"string"},"musicalPreference":{"description":"Musical preference of the guide","type":"string"},"name":{"description":"The name session of the name as entered by the guide","type":"string"},"renderType":{"enum":["realTime","preRendered"],"description":""},"scheduleTime":{"description":"The date, time of the session if scheduled","type":"string"},"scheduleType":{"enum":["now","later","specificTime"],"description":"Whether the session is to start now, at a specified time in the future, or unspecified"},"sessionCompletness":{"type":"integer","description":"session completness percentage 1-100"},"therapeuticTemplate":{"description":"The template that the guide selects","type":"string"},"type":{"description":"A type of a session (eg oneOnOne, intro)","type":"string"},"use":{"description":"How is the session going to be used by the guide","type":"string"}},"additionalProperties":false,"required":["duration","isTemplateCustom","musicalPreference","use"]},
          'Session Started': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Started/version/4.0.2","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Started","description":"When user clicks on `session start now` button\n\nhttps://cln.sh/myoVsP\n\n```\ndataLayer.push({\n  'event': 'Session Started',\n  'medicine': 'mdma',\n  'musicalPreference': ' ',\n  'scheduleTime': ' ',\n  'duration': ' ',\n  'name': ' ',\n  'use': ' ',\n  'therapeuticTemplate': ' '\n});\n```","type":"object","properties":{"duration":{"description":"Session duration in minutes","type":"number"},"introSessionId":{"description":"The ID identifying which intro session was played","type":"string"},"medicine":{"description":"Name of the medicine","type":"string"},"musicalPreference":{"description":"Musical preference of the guide","type":"string"},"name":{"description":"The name session of the name as entered by the guide","type":"string"},"renderType":{"enum":["realTime","preRendered"],"description":""},"scheduleTime":{"description":"The date, time of the session if scheduled","type":"string"},"scheduleType":{"enum":["now","later","specificTime"],"description":"Whether the session is to start now, at a specified time in the future, or unspecified"},"sessionCompletness":{"type":"integer","description":"session completness percentage 1-100"},"therapeuticTemplate":{"description":"The template that the guide selects","type":"string"},"type":{"description":"A type of a session (eg oneOnOne, intro)","type":"string"},"use":{"description":"How is the session going to be used by the guide","type":"string"}},"additionalProperties":false,"required":["duration","musicalPreference","use"]},
          'Session Template Created': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Template%20Created/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Template Created","description":"Owner: Chris Yeoward","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Session Template Deleted': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Template%20Deleted/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Template Deleted","description":"Owner: Chris Yeoward","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Session Template Selected': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Session%20Template%20Selected/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Session Template Selected","description":"Owner: Chris Yeoward","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Sign In': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Sign%20In/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Sign In","description":"When user signs in the app\n\n```\ndataLayer.push({\n  'event': 'Sign In'\n});\n```","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Sign Out': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Sign%20Out/version/1.0.1","$schema":"http://json-schema.org/draft-07/schema#","title":"Sign Out","description":"When users clicks on sign out\n\n```\ndataLayer.push({\n  'event': 'Sign Out'\n});\n```","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Sign Up': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Sign%20Up/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Sign Up","description":"When a new account is created in the app. This call should be sent with the identify call\n\n```\ndataLayer.push({\n  'event': 'Sign Up'\n});\n```","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Skip Queue Wave Update': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Skip%20Queue%20Wave%20Update/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Skip Queue Wave Update","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Skip To Wave': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Skip%20To%20Wave/version/7.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Skip To Wave","description":"Skips to a particular wave using a button in upcoming wave queue","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"lostMinutes":{"description":"","type":"number"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","lostMinutes","phase","totalNumberOfWavesInSession","type"]},
          'Streaming Network Glitch': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Streaming%20Network%20Glitch/version/1.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Streaming Network Glitch","description":"Owner: Mikolaj Torz","type":"object","properties":{},"additionalProperties":false,"required":[]},
          'Subscribe Clicked': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Subscribe%20Clicked/version/2.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Subscribe Clicked","description":"When user clicks on the subscribe button\n\nhttps://cln.sh/YUMC7T\n\n```\ndataLayer.push({\n  'event': 'Subscription Clicked'\n});\n```","type":"object","properties":{"priceId":{"description":"","type":"string"}},"additionalProperties":false,"required":["priceId"]},
          'Update Wave': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Update%20Wave/version/11.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Update Wave","description":"Owner: Mikolaj Torz","type":"object","properties":{"index":{"type":"integer","description":""},"newDirection":{"enum":["Deepen","R&D","Release","Soothe","Activate","Bridge","Silence","Guide"],"description":""},"newDuration":{"description":"","type":"string"},"newEmotion":{"description":"","type":"string"},"newIntensity":{"description":"","type":"string"},"newName":{"description":"","type":"string"},"newPathId":{"description":"","type":"string"},"newType":{"enum":["Generative","Curated","Experimental"],"description":""},"oldDirection":{"enum":["Activate","Deepen","Release","Soothe","R&D","Bridge","Silence","Guide"],"description":""},"oldDuration":{"description":"","type":"string"},"oldEmotion":{"description":"","type":"string"},"oldIntensity":{"description":"","type":"string"},"oldName":{"description":"","type":"string"},"oldPathId":{"description":"","type":"string"},"oldType":{"enum":["Generative","Curated","Experimental"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""}},"additionalProperties":false,"required":["index","totalNumberOfWavesInSession"]},
          'Update Wave In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Update%20Wave%20In%20Editor/version/5.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Update Wave In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"index":{"type":"integer","description":""},"newDirection":{"enum":["Deepen","R&D","Release","Soothe","Activate","Bridge","Silence","Guide"],"description":""},"newDuration":{"description":"","type":"string"},"newEmotion":{"description":"","type":"string"},"newIntensity":{"description":"","type":"string"},"newName":{"description":"","type":"string"},"newPathId":{"description":"","type":"string"},"newType":{"enum":["Generative","Curated","Experimental"],"description":""},"oldDirection":{"enum":["Deepen","R&D","Release","Soothe","Activate","Bridge","Silence","Guide"],"description":""},"oldDuration":{"description":"","type":"string"},"oldEmotion":{"description":"","type":"string"},"oldIntensity":{"description":"","type":"string"},"oldName":{"description":"","type":"string"},"oldPathId":{"description":"","type":"string"},"oldType":{"enum":["Generative","Curated","Experimental"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""}},"additionalProperties":false,"required":["index","totalNumberOfWavesInSession"]},
          'Wave Cannot Be Moved Further Down': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Wave%20Cannot%20Be%20Moved%20Further%20Down/version/6.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Wave Cannot Be Moved Further Down","description":"Attempted moving the next wave down when it is not possible triggering the warning snackbar","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Wave Cannot Be Moved Further Down In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Wave%20Cannot%20Be%20Moved%20Further%20Down%20In%20Editor/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Wave Cannot Be Moved Further Down In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Wave Cannot Be Moved Further Up': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Wave%20Cannot%20Be%20Moved%20Further%20Up/version/6.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Wave Cannot Be Moved Further Up","description":"Attempted moving first wave in the queue up, triggering a warning snackbar","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Wave Cannot Be Moved Further Up In Editor': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Wave%20Cannot%20Be%20Moved%20Further%20Up%20In%20Editor/version/4.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Wave Cannot Be Moved Further Up In Editor","description":"Owner: Mikolaj Torz","type":"object","properties":{"direction":{"enum":["Activate","Deepen","R&D","Soothe","Release","Bridge","Silence","Guide"],"description":""},"duration":{"type":"number","description":"duration in minutes"},"emotion":{"enum":["Stillness","Bittersweet","Tension","Vitality","Dreamy","Silence"],"description":""},"fromTime":{"description":"","type":"number"},"index":{"type":"integer","description":""},"intensity":{"description":"","type":"string"},"name":{"description":"","type":"string"},"pathId":{"description":"","type":"string"},"phase":{"enum":["pre","post","scheduled"],"description":""},"totalNumberOfWavesInSession":{"type":"integer","description":""},"toTime":{"description":"","type":"number"},"type":{"enum":["Curated","Generative","Experimental"],"description":""},"userSetDuration":{"description":"duration set by user","type":"number"}},"additionalProperties":false,"required":["index","phase","totalNumberOfWavesInSession","type"]},
          'Wave Skipped': {"$id":"https://data.amplitude.com/0b99c4a3-780e-404a-b2cf-2339135fa7d2/event/Wave%20Skipped/version/2.0.0","$schema":"http://json-schema.org/draft-07/schema#","title":"Wave Skipped","description":"User skips to a different wave","type":"object","properties":{"waveIndex":{"type":"integer","description":""}},"additionalProperties":false,"required":[]},
        }),
        ...destinationPlugins,
        ...plugins,
      ]
    });
  }

  /**
  * Alias a user ID to another user ID.
  * @param userId The user's new ID.
  * @param previousId The user's previous ID.
  */
  alias(userId: string, previousId?: string) {
    itly.alias(userId, previousId);
  }

  /**
  * Set or update a user's properties.
  * @param userId The user's ID.
  * @param properties Required and optional user properties.
  */
  identify(userId: string, properties: IdentifyProperties) {
    itly.identify(userId, properties)
  }

  group(groupId: string) {
    itly.group(groupId)
  }

  page(category: string, name: string) {
    itly.page(category, name);
  }

  /**
   * Adding new wave in the session view (through upcoming wave queue)
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. index)
   */
  addWave(properties: AddWaveProperties) {
    itly.track(new AddWave(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. index)
   */
  addWaveInEditor(properties: AddWaveInEditorProperties) {
    itly.track(new AddWaveInEditor(properties));
  }

  /**
   * When wave update is pending user cancels it
   *
   * Owner: Mikolaj Torz
   */
  cancelWaveUpdate() {
    itly.track(new CancelWaveUpdate());
  }

  /**
   * Owner: Chris Yeoward
   * @param properties The event's properties (e.g. coreEmotionalAtmosphere)
   */
  ceaSelected(properties: CEASelectedProperties) {
    itly.track(new CeaSelected(properties));
  }

  /**
   * Owner: Tero Parviainen
   * @param properties The event's properties (e.g. coreEmotionalAtmosphere)
   */
  ceaSelectionCanceled(properties: CEASelectionCanceledProperties) {
    itly.track(new CeaSelectionCanceled(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. coreEmotionalAtmosphere)
   */
  ceaSelectionSkippedQueue(properties: CeaSelectionSkippedQueueProperties) {
    itly.track(new CeaSelectionSkippedQueue(properties));
  }

  /**
   * General click on HTML element
   * @param properties The event's properties (e.g. ariaLabel)
   */
  click(properties: ClickProperties) {
    itly.track(new Click(properties));
  }

  /**
   * When a user opens the Info dialog in the Experimental table of a wave card
   *
   * Owner: Tero Parviainen
   */
  clickExperimentalWaveFunctionInfo() {
    itly.track(new ClickExperimentalWaveFunctionInfo());
  }

  /**
   * Owner: Chris Yeoward
   */
  clickWaveTypeInfo() {
    itly.track(new ClickWaveTypeInfo());
  }

  /**
   * Owner: Rafal Pastuszak
   */
  closeSessionEditorModal() {
    itly.track(new CloseSessionEditorModal());
  }

  /**
   * closes wave by pressing on it in the queue
   *
   * Owner: Mikolaj Torz
   */
  closeWaveViaQueue() {
    itly.track(new CloseWaveViaQueue());
  }

  /**
   * Owner: Mikolaj Torz
   */
  closeWaveViaSparkline() {
    itly.track(new CloseWaveViaSparkline());
  }

  /**
   * Owner: Chris Yeoward
   * @param properties The event's properties (e.g. sessionId)
   */
  completedSessionAudioPlayed(properties: CompletedSessionAudioPlayedProperties) {
    itly.track(new CompletedSessionAudioPlayed(properties));
  }

  /**
   * User pressed on 'Make A Copy Of This Session' button
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. tab)
   */
  copiedSession(properties: CopiedSessionProperties) {
    itly.track(new CopiedSession(properties));
  }

  /**
   * Owner: Maximilian Schnupp
   * @param properties The event's properties (e.g. effectiveTimeSeconds)
   */
  currentWaveExtended(properties: CurrentWaveExtendedProperties) {
    itly.track(new CurrentWaveExtended(properties));
  }

  /**
   * Owner: Tero Parviainen
   * @param properties The event's properties (e.g. depth)
   */
  depthChangeCanceled(properties: DepthChangeCanceledProperties) {
    itly.track(new DepthChangeCanceled(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. depth)
   */
  depthChangeSkippedQueue(properties: DepthChangeSkippedQueueProperties) {
    itly.track(new DepthChangeSkippedQueue(properties));
  }

  /**
   * Owner: Chris Yeoward
   * @param properties The event's properties (e.g. depth)
   */
  depthChanged(properties: DepthChangedProperties) {
    itly.track(new DepthChanged(properties));
  }

  /**
   * Owner: Mikolaj Torz
   */
  dragsIndividualSparklinesInQueue() {
    itly.track(new DragsIndividualSparklinesInQueue());
  }

  /**
   * The feedback that user enters during a session
   *
   * Owner: Yalcin Kaya
   * @param properties The event's properties (e.g. easeOfUseRating)
   */
  feedbackRecorded(properties: FeedbackRecordedProperties) {
    itly.track(new FeedbackRecorded(properties));
  }

  /**
   * Owner: Mikolaj Torz
   */
  goingOffline() {
    itly.track(new GoingOffline());
  }

  /**
   * Owner: Tero Parviainen
   * @param properties The event's properties (e.g. layers)
   */
  instrumentRefreshCanceled(properties: InstrumentRefreshCanceledProperties) {
    itly.track(new InstrumentRefreshCanceled(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. layers)
   */
  instrumentsRefreshSkippedQueue(properties: InstrumentsRefreshSkippedQueueProperties) {
    itly.track(new InstrumentsRefreshSkippedQueue(properties));
  }

  /**
   * Owner: Chris Yeoward
   * @param properties The event's properties (e.g. layers)
   */
  instrumentsRefreshed(properties: InstrumentsRefreshedProperties) {
    itly.track(new InstrumentsRefreshed(properties));
  }

  /**
   * Owner: Maximilian Schnupp
   * @param properties The event's properties (e.g. sessionId)
   */
  integrationLinkCopied(properties: IntegrationLinkCopiedProperties) {
    itly.track(new IntegrationLinkCopied(properties));
  }

  /**
   * Owner: Maximilian Schnupp
   * @param properties The event's properties (e.g. sessionId)
   */
  integrationLinkOpened(properties: IntegrationLinkOpenedProperties) {
    itly.track(new IntegrationLinkOpened(properties));
  }

  /**
   * Pressed the jump right in button
   *
   * Owner: Mikolaj Torz
   */
  jumpedRightIn() {
    itly.track(new JumpedRightIn());
  }

  /**
   * Moves wave down in the queue
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  moveWaveDown(properties: MoveWaveDownProperties) {
    itly.track(new MoveWaveDown(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  moveWaveDownInEditor(properties: MoveWaveDownInEditorProperties) {
    itly.track(new MoveWaveDownInEditor(properties));
  }

  /**
   * Moves wave up in a queue
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  moveWaveUp(properties: MoveWaveUpProperties) {
    itly.track(new MoveWaveUp(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  moveWaveUpInEditor(properties: MoveWaveUpInEditorProperties) {
    itly.track(new MoveWaveUpInEditor(properties));
  }

  /**
   * Owner: Chris Yeoward
   */
  newSessionFromTemplateClicked() {
    itly.track(new NewSessionFromTemplateClicked());
  }

  /**
   * Owner: Rafal Pastuszak
   */
  openSessionEditorModal() {
    itly.track(new OpenSessionEditorModal());
  }

  /**
   * Open wave by pressing on it in the queue
   *
   * Owner: Mikolaj Torz
   */
  openWaveViaQueue() {
    itly.track(new OpenWaveViaQueue());
  }

  /**
   * Owner: Mikolaj Torz
   */
  openWaveViaSparkline() {
    itly.track(new OpenWaveViaSparkline());
  }

  /**
   * Owner: Mikolaj Torz
   */
  overwriteSettingsDialogTriggeredInEditor() {
    itly.track(new OverwriteSettingsDialogTriggeredInEditor());
  }

  /**
   * Triggers when a page loads. No additional tracking is required for this as the Tag Manager does this for us
   *
   * Owner: Yalcin Kaya
   * @param properties The event's properties (e.g. parameter)
   */
  pageViewed(properties: PageViewedProperties) {
    itly.track(new PageViewed(properties));
  }

  /**
   * Removing a wave in the session view (through upcoming wave queue)
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  removeWave(properties: RemoveWaveProperties) {
    itly.track(new RemoveWave(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  removeWaveInEditor(properties: RemoveWaveInEditorProperties) {
    itly.track(new RemoveWaveInEditor(properties));
  }

  /**
   * When the user clicks on the X to end the session
   *
   * Owner: Yalcin Kaya
   * @param properties The event's properties (e.g. duration)
   */
  sessionCanceled(properties: SessionCanceledProperties) {
    itly.track(new SessionCanceled(properties));
  }

  /**
   * When the session is finished
   *
   * Owner: Yalcin Kaya
   * @param properties The event's properties (e.g. duration)
   */
  sessionCompleted(properties: SessionCompletedProperties) {
    itly.track(new SessionCompleted(properties));
  }

  /**
   * When a user creates a session through the session planner
   *
   * Owner: Chris Yeoward
   * @param properties The event's properties (e.g. duration)
   */
  sessionCreated(properties: SessionCreatedProperties) {
    itly.track(new SessionCreated(properties));
  }

  /**
   * When user clicks on `session start now` button
   *
   * https://cln.sh/myoVsP
   *
   * ```
   * dataLayer.push({
   *   'event': 'Session Started',
   *   'medicine': 'mdma',
   *   'musicalPreference': ' ',
   *   'scheduleTime': ' ',
   *   'duration': ' ',
   *   'name': ' ',
   *   'use': ' ',
   *   'therapeuticTemplate': ' '
   * });
   * ```
   *
   * Owner: Yalcin Kaya
   * @param properties The event's properties (e.g. duration)
   */
  sessionStarted(properties: SessionStartedProperties) {
    itly.track(new SessionStarted(properties));
  }

  /**
   * Owner: Chris Yeoward
   */
  sessionTemplateCreated() {
    itly.track(new SessionTemplateCreated());
  }

  /**
   * Owner: Chris Yeoward
   */
  sessionTemplateDeleted() {
    itly.track(new SessionTemplateDeleted());
  }

  /**
   * Owner: Chris Yeoward
   */
  sessionTemplateSelected() {
    itly.track(new SessionTemplateSelected());
  }

  /**
   * When user signs in the app
   *
   * ```
   * dataLayer.push({
   *   'event': 'Sign In'
   * });
   * ```
   *
   * Owner: Yalcin Kaya
   */
  signIn() {
    itly.track(new SignIn());
  }

  /**
   * When users clicks on sign out
   *
   * ```
   * dataLayer.push({
   *   'event': 'Sign Out'
   * });
   * ```
   *
   * Owner: Yalcin Kaya
   */
  signOut() {
    itly.track(new SignOut());
  }

  /**
   * When a new account is created in the app. This call should be sent with the identify call
   *
   * ```
   * dataLayer.push({
   *   'event': 'Sign Up'
   * });
   * ```
   *
   * Owner: Yalcin Kaya
   */
  signUp() {
    itly.track(new SignUp());
  }

  /**
   * Owner: Mikolaj Torz
   */
  skipQueueWaveUpdate() {
    itly.track(new SkipQueueWaveUpdate());
  }

  /**
   * Skips to a particular wave using a button in upcoming wave queue
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  skipToWave(properties: SkipToWaveProperties) {
    itly.track(new SkipToWave(properties));
  }

  /**
   * Owner: Mikolaj Torz
   */
  streamingNetworkGlitch() {
    itly.track(new StreamingNetworkGlitch());
  }

  /**
   * When user clicks on the subscribe button
   *
   * https://cln.sh/YUMC7T
   *
   * ```
   * dataLayer.push({
   *   'event': 'Subscription Clicked'
   * });
   * ```
   *
   * Owner: Yalcin Kaya
   * @param properties The event's properties (e.g. priceId)
   */
  subscribeClicked(properties: SubscribeClickedProperties) {
    itly.track(new SubscribeClicked(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. index)
   */
  updateWave(properties: UpdateWaveProperties) {
    itly.track(new UpdateWave(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. index)
   */
  updateWaveInEditor(properties: UpdateWaveInEditorProperties) {
    itly.track(new UpdateWaveInEditor(properties));
  }

  /**
   * Attempted moving the next wave down when it is not possible triggering the warning snackbar
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  waveCannotBeMovedFurtherDown(properties: WaveCannotBeMovedFurtherDownProperties) {
    itly.track(new WaveCannotBeMovedFurtherDown(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  waveCannotBeMovedFurtherDownInEditor(properties: WaveCannotBeMovedFurtherDownInEditorProperties) {
    itly.track(new WaveCannotBeMovedFurtherDownInEditor(properties));
  }

  /**
   * Attempted moving first wave in the queue up, triggering a warning snackbar
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  waveCannotBeMovedFurtherUp(properties: WaveCannotBeMovedFurtherUpProperties) {
    itly.track(new WaveCannotBeMovedFurtherUp(properties));
  }

  /**
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. direction)
   */
  waveCannotBeMovedFurtherUpInEditor(properties: WaveCannotBeMovedFurtherUpInEditorProperties) {
    itly.track(new WaveCannotBeMovedFurtherUpInEditor(properties));
  }

  /**
   * User skips to a different wave
   *
   * Owner: Mikolaj Torz
   * @param properties The event's properties (e.g. waveIndex)
   */
  waveSkipped(properties: WaveSkippedProperties) {
    itly.track(new WaveSkipped(properties));
  }

  track(event: Event) {
    itly.track(event);
  }

  reset() {
    itly.reset();
  }
}

export default new Itly();
