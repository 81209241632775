import { includes } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import TypographyV2 from '../typography/TypographyV2';
import Button from './Button';
import Heading from './Control/Heading';
import { DOSAGE_INFO } from './DosageInfo';
import PillOption from './PillOption';
import TextInput from './TextInput';

type Option<ValueType> = {
    label: string;
    value: ValueType;
};

export type FilterInputsProps<
    ModalityType,
    IntensityType,
    AdministrationType,
    DosageType,
    IntentionType,
    GuidanceType
> = {
    modalityOptions: Option<ModalityType>[];
    modalityValue?: ModalityType;
    onModalityValueChange: (newValue?: ModalityType) => void;

    intensityOptions: Option<IntensityType>[];
    intensityValues: IntensityType[];
    onIntensityValuesChange: (newValues: IntensityType[]) => void;

    intentionOptions: Option<IntentionType>[];
    intentionValues: IntentionType[];
    onIntentionValuesChange: (newValues: IntentionType[]) => void;

    guidanceOptions: Option<GuidanceType>[];
    guidanceValues: GuidanceType[];
    onGuidanceValuesChange: (newValues: GuidanceType[]) => void;

    administrationOptions: Option<AdministrationType>[];
    administration?: AdministrationType;
    onAdministrationChange: (newValue?: AdministrationType) => void;

    dosageOptions: Option<DosageType>[];
    dosage?: DosageType;
    onDosageChange: (newValue?: DosageType) => void;

    durationValue?: number | null;
    durationOptions: Option<number>[];

    onDurationChange: (duration: number | undefined) => void;
    program?: string;
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const PillOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
`;

const MultiInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const DurationContainer = styled.div``;

const DurationInputContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
`;

function MultiInput<ValueType>(props: {
    title: string;
    options: Option<ValueType>[];
    values: ValueType[];
    info?: { content: ReactNode; title: string };
    onItemClick?: (itemValue: ValueType) => void;
}) {
    const labelId = props.title && `${props.title}-segmented-label`;

    return (
        <MultiInputContainer>
            <Heading
                id={labelId}
                heading={props.title}
                info={props.info}
                canSave={false}
                inline={false}
                colour="dark"
            />
            <PillOptionsContainer>
                {props.options.map((opt) => {
                    const isSelected = includes(props.values, opt.value);
                    return (
                        <PillOption
                            key={`${opt.value}`}
                            name={opt.label}
                            selected={isSelected}
                            onClick={() => props.onItemClick && props.onItemClick(opt.value)}
                        />
                    );
                })}
            </PillOptionsContainer>
        </MultiInputContainer>
    );
}

function FilterInputDialog<
    ModalityType extends unknown,
    IntensityType extends unknown,
    IntentionType extends unknown,
    GuidanceType extends unknown,
    AdministrationType extends unknown,
    DosageType extends unknown
>({
    modalityOptions,
    modalityValue,
    onModalityValueChange,
    intensityOptions,
    intensityValues,
    onIntensityValuesChange,

    intentionOptions,
    intentionValues,
    onIntentionValuesChange,

    guidanceOptions,
    guidanceValues,
    onGuidanceValuesChange,

    administrationOptions,
    administration,
    onAdministrationChange,
    dosageOptions,
    dosage,
    onDosageChange,
    durationValue,
    durationOptions,
    onDurationChange,

    program,
}: FilterInputsProps<ModalityType, IntensityType, AdministrationType, DosageType, IntentionType, GuidanceType>) {
    const durationRef = React.useRef<HTMLInputElement>();
    const [changedDurationValue, setChangedDurationValue] = useState(durationValue);

    useEffect(() => {
        setChangedDurationValue(durationValue);
    }, [durationValue]);

    return (
        <Content>
            {!modalityValue && (
                <MultiInput
                    title="Choose modality"
                    options={modalityOptions}
                    values={modalityValue ? [modalityValue] : []}
                    onItemClick={(val) => onModalityValueChange(modalityValue === val ? undefined : val)}
                />
            )}
            {administrationOptions.length && !administration ? (
                <MultiInput
                    title="Choose administration method"
                    options={administrationOptions}
                    values={administration ? [administration] : []}
                    onItemClick={(val) => onAdministrationChange(administration === val ? undefined : val)}
                />
            ) : null}
            {dosageOptions.length && administration && !dosage ? (
                <MultiInput
                    title="Choose dosage"
                    info={DOSAGE_INFO}
                    options={dosageOptions}
                    values={dosage ? [dosage] : []}
                    onItemClick={(val) => onDosageChange(dosage === val ? undefined : val)}
                />
            ) : null}
            {modalityValue && (dosage || (!dosageOptions.length && !administrationOptions.length)) && (
                <DurationContainer>
                    <Heading
                        id={'duration-labe'}
                        heading={'Session duration'}
                        canSave={false}
                        inline={false}
                        colour="dark"
                    />
                    <DurationInputContainer>
                        <DurationInputContainer>
                            {durationOptions.length &&
                                durationOptions.map((x) => (
                                    <Button
                                        style={{ fontWeight: 'normal' }}
                                        key={x.label}
                                        onClick={(e) => e && onDurationChange(x.value)}
                                        size="s"
                                        variant={x.value == durationValue ? 'solid-blue' : 'outlined'}
                                    >
                                        {x.label}
                                    </Button>
                                ))}
                        </DurationInputContainer>
                        <DurationInputContainer>
                            <TypographyV2 color="grey-700" size="text-sm" weight="medium">
                                or specific:
                            </TypographyV2>
                            <TextInput
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', ref: durationRef }}
                                style={{ width: 60 }}
                                variant="outlined"
                                value={changedDurationValue}
                                onChange={(e) =>
                                    setChangedDurationValue(e.target.value ? Number(e.target.value) : undefined)
                                }
                                size="small"
                            />
                            <TypographyV2 color="grey-700" size="text-sm" weight="medium">
                                mins
                            </TypographyV2>
                            <Button
                                onClick={(e) =>
                                    e &&
                                    onDurationChange(
                                        durationRef?.current?.value ? Number(durationRef.current.value) : undefined,
                                    )
                                }
                                size="s"
                                variant="outlined"
                            >
                                OK
                            </Button>
                        </DurationInputContainer>
                    </DurationInputContainer>
                </DurationContainer>
            )}
            {durationValue && !guidanceValues?.length && guidanceOptions?.length ? (
                <MultiInput
                    title="Would you like to be guided?"
                    options={guidanceOptions}
                    values={guidanceValues}
                    onItemClick={(val) =>
                        onGuidanceValuesChange(
                            guidanceValues.includes(val)
                                ? guidanceValues.filter((v) => v !== val)
                                : [...guidanceValues, val],
                        )
                    }
                />
            ) : (
                <></>
            )}
            {!program &&
            !intensityValues?.length &&
            durationValue &&
            (guidanceValues?.length || !guidanceOptions?.length) ? (
                <MultiInput
                    title="Choose emotional intensity"
                    options={intensityOptions}
                    values={intensityValues}
                    onItemClick={(val) =>
                        onIntensityValuesChange(
                            intensityValues.includes(val)
                                ? intensityValues.filter((v) => v !== val)
                                : [...intensityValues, val],
                        )
                    }
                />
            ) : (
                <></>
            )}
            {!program &&
            !intentionValues?.length &&
            durationValue &&
            intentionOptions?.length &&
            (!intensityOptions?.length || intensityValues.length) ? (
                <MultiInput
                    title="Choose intention"
                    options={intentionOptions}
                    values={intentionValues}
                    onItemClick={(val) =>
                        onIntentionValuesChange(
                            intentionValues.includes(val)
                                ? intentionValues.filter((v) => v !== val)
                                : [...intentionValues, val],
                        )
                    }
                />
            ) : (
                <></>
            )}
        </Content>
    );
}

export default FilterInputDialog;
