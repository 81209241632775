//TS doesnt compile this?
/* eslint-disable */
const hlsParser = require('hls-parser');
import { fetchWithTimeout, ParallelPromises } from '@/util/asyncUtils';
/* eslint-enable */

import * as Sentry from '@sentry/browser';
//TS doesnt compile this?
// import { MasterPlaylist, MediaPlaylist } from 'hls-parser/types';
//const hlsParserTypes = require('hls-parser/types');
import { maxBy } from 'lodash';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import configs from '../../configs';

import { OnlineContext } from '../../pages/inSession/useIsOnline';
import { Session } from 'wavepaths-shared/core';
import { TestHooksContext } from './useTestHooks';
import { isSafari } from 'react-device-detect';

const SW_CACHE_NAME = 'session-audio';

interface CacheResult {
    url: string;
    status: 'cached' | 'fetched' | 'failed' | 'skipped' | 'miss' | 'deleted';
}

const init = () => {
    const CLIENT_URL = '/audioCacheWorker.js';

    if (typeof window !== 'undefined') {
        if (window.navigator.onLine) {
            console.debug('We are online');

            (async () => {
                try {
                    const registrations = await navigator.serviceWorker.getRegistrations();
                    for (const registration of registrations) {
                        if (registration.active?.scriptURL.indexOf(CLIENT_URL) != -1) {
                            console.debug('Deregistering', registration);
                            const unregisterResult = await registration.unregister();
                            console.debug('Unregistered old worker', unregisterResult);
                            break;
                        }
                    }
                    if (!isSafari) {
                        const registration = await navigator.serviceWorker.register(CLIENT_URL, { scope: '/' });
                        console.debug('Service Worker registered: ', registration);
                    } else {
                        console.debug('Skipping service worker registration on safari');
                    }
                } catch (e) {
                    console.debug('Service Worker registration failed: ', e);
                }
            })();
        } else {
            console.debug('We are offline');
        }
    }
};

async function checkIfCached(cache: Cache, url: string): Promise<boolean> {
    return cache.match(url).then(async (response) => {
        if (response !== undefined) {
            return true;
        } else {
            return false;
        }
    });
}

const cacheUrl = async (cache: Cache, url: string): Promise<CacheResult> => {
    try {
        const isInCache = await checkIfCached(cache, url);
        if (isInCache) {
            return { url, status: 'cached' };
        } else {
            const response = await fetchWithTimeout(url);
            if (response.body) {
                await cache.put(url, response);
                console.debug('Cache put', url);
            }
            return { url, status: 'fetched' };
        }
    } catch (e) {
        return { url, status: 'failed' };
    }
};

const cacheIndividualFile = async (url: string): Promise<CacheResult> => {
    const cacheStorage = await caches.open(SW_CACHE_NAME);

    return cacheUrl(cacheStorage, url);
};

export const useStaticFilesAudioCache = () => {
    const initialized = useRef(false);
    const preludeFileCacheResult = useRef<CacheResult | undefined>(undefined);

    if (!globallyInitialized) {
        init();
        globallyInitialized = true;
    }

    if (!initialized.current) {
        initialized.current = true;
        (async () => {
            try {
                preludeFileCacheResult.current = await cacheIndividualFile(configs.freud.PRELUDE_POSTLUDE_MUSIC);
            } catch (e: any) {
                console.debug('Fallback audio cache fail', e);
                Sentry.captureException(e);
            }
        })();
    }

    return {
        preludeFileCacheResult: preludeFileCacheResult.current,
    };
};
let globallyInitialized = false;

interface UseAudioCacheParams {
    streamUrl: string;
    broadcastIdentifier: string;
    estimatedDurationSec?: number;
}

const useAudioCache = ({ streamUrl, broadcastIdentifier, estimatedDurationSec }: UseAudioCacheParams) => {
    let duration = estimatedDurationSec;
    const testHooks = useContext(TestHooksContext);

    const [status, setStatus] = useState<
        'idle' | 'generating' | 'downloading' | 'done' | 'failed' | 'deleting' | 'stopped'
    >('idle');
    const [percentCompleted, setPercentCompleted] = useState<number>(0);
    const { onlineStatusRef } = useContext(OnlineContext);

    if (!globallyInitialized) {
        init();
        globallyInitialized = true;
    }

    const parseManifest = async (cache: Cache, url: string) => {
        const response = await fetch(url);
        if (response.status != 200) {
            throw new Error('Manifest error status ' + response.status);
        }
        const reader = response.body?.getReader();
        const responseHeaders = response.headers;

        let ageSeconds = 0;
        let lastModifiedTimestamp = Date.now();
        if (responseHeaders.has('Last-Modified')) {
            const lastModified = responseHeaders.get('Last-Modified');
            lastModifiedTimestamp = Date.parse(lastModified!);
            ageSeconds = (Date.now() - lastModifiedTimestamp) / 1000;
            testHooks?.debug && console.debug('Manifest age', url, ageSeconds);
        }

        if (!reader) throw new Error('No Reader');

        const { value } = await reader?.read();
        const rawManifest = new TextDecoder().decode(value);
        const manifest = rawManifest ? hlsParser.parse(rawManifest) : undefined;
        //if its a master playlist we can cache it if its valid
        //console.debug('Manifest ', manifest, manifest.isMasterPlaylist);
        if (manifest && manifest.isMasterPlaylist) {
            const isCached = await checkIfCached(cache, url);
            if (!isCached) {
                console.debug('Caching manifest', url);
                await cache.put(url, await fetchWithTimeout(url));
            }
        }

        return {
            manifest,
            ageSeconds,
        };
    };

    const rewriteUrlForResource = (resource: { uri: string }) => {
        return streamUrl.replace(new RegExp(`${broadcastIdentifier}.*`), `${broadcastIdentifier}/${resource?.uri}`);
    };

    const getHighestQualityVariantUrl = async (cache: Cache) => {
        const { manifest } = (await parseManifest(cache, streamUrl)) as any; //Stupid TS //MasterPlaylist;

        if (manifest && manifest.isMasterPlaylist) {
            const hqVariant = maxBy(manifest.variants, 'bandwidth') ?? manifest.variants[0];

            return rewriteUrlForResource(hqVariant);
        }
    };

    const deleteUrl = async (cache: Cache, url: string): Promise<CacheResult> => {
        try {
            const isInCache = await checkIfCached(cache, url);
            if (!isInCache) {
                return { url, status: 'miss' };
            }

            await cache.delete(url);
            return { url, status: 'deleted' };
        } catch (e) {
            return { url, status: 'failed' };
        }
    };

    async function checkAllFilesAreCached(): Promise<{ haveAllFiles: boolean; reason: string }> {
        const cacheStorage = await caches.open(SW_CACHE_NAME);
        try {
            const variantUrl = await getHighestQualityVariantUrl(cacheStorage);
            if (!variantUrl) return { haveAllFiles: false, reason: 'variantUrl' };
            const { manifest, ageSeconds } = (await parseManifest(cacheStorage, variantUrl)) as any; //MediaPlaylist;
            if (!manifest) return { haveAllFiles: false, reason: 'manifest missing' };

            testHooks?.debug && console.debug('Manifest ', manifest);

            // endlist sometimes is stuck as stream end are soemtimes missing end meta tag
            if (!manifest.endlist && ageSeconds < 60 * 2) return { haveAllFiles: false, reason: 'not end of stream' };

            const results = await Promise.all<{ isCached: boolean; segment: string }>(
                manifest.segments.map(async (segment: { uri: string }) => {
                    const url = rewriteUrlForResource(segment);

                    const isCached = await checkIfCached(cacheStorage, url);
                    testHooks?.debug && console.debug('Chunk isCached :', isCached, url);
                    return { isCached, segment };
                }),
            );
            const missingSegmentFiles = results.filter((x) => !x.isCached);
            if (missingSegmentFiles.length) {
                return {
                    haveAllFiles: false,
                    reason: missingSegmentFiles.map((x) => x.segment).join(', '),
                };
            }

            return {
                haveAllFiles: true,
                reason: 'ok',
            };
        } catch (err: any) {
            console.debug(err);
            return {
                haveAllFiles: false,
                reason: 'error',
            };
        }
    }

    //TODO move to consumer code as this will be triggered on demand
    useEffect(() => {
        if (globallyInitialized && status == 'idle') {
            testHooks?.debug && console.debug('Running a check on all chunks for this stream');
            checkAllFilesAreCached().then((result) => {
                testHooks?.debug && console.debug('Download status:', result);
                result.haveAllFiles && setStatus('done');
            });
        }
    }, [globallyInitialized, status]);

    async function cacheHlsPlaylist({
        offsetSeconds = undefined,
        maxCachedSeconds = Number.MAX_SAFE_INTEGER,
        watch = true,
    }: {
        offsetSeconds?: number;
        maxCachedSeconds?: number;
        watch?: boolean;
    }) {
        if (watch && offsetSeconds) {
            throw new Error('In Watch mode we dont support selective caching');
        }

        let abortCaching = false;

        const stopCaching = () => {
            abortCaching = true;
            setStatus('stopped');
        };

        setStatus('downloading');
        setPercentCompleted(0);
        if (navigator.storage && navigator.storage.persist) {
            const isPersisted = await navigator.storage.persist();
            if (!isPersisted) {
                Sentry.captureMessage('navigator.storage.persist() rejected');
            }
            testHooks?.debug && console.debug(`Persisted storage granted: ${isPersisted}`);
        }

        const cacheStorage = await caches.open(SW_CACHE_NAME);

        function bumpPercentCompleted(currentPositionSecs: number) {
            testHooks?.debug &&
                console.debug('Cache progress', {
                    currentPositionSecs,
                    estimatedDurationSec,
                    percentCompleted,
                    duration,
                });
            if (duration !== undefined && duration > 0) {
                setPercentCompleted((prev) => {
                    const newPercentCompleted = Math.min(100, Math.round((currentPositionSecs * 100.0) / duration!));
                    testHooks?.debug && console.debug('New', { newPercentCompleted, percentCompleted });
                    return newPercentCompleted > prev ? newPercentCompleted : prev;
                });
            }
        }

        const cacheWithRetry = async (totalCounter = 0, errorsInRow = 0) => {
            if (abortCaching) return;

            try {
                const variantUrl = await getHighestQualityVariantUrl(cacheStorage);
                if (!variantUrl) {
                    throw new Error('No variant URL');
                }

                if (!onlineStatusRef.current) {
                    setStatus('failed');
                    return;
                }
                setStatus('downloading');
                testHooks?.debug && console.debug('Resume download process', totalCounter);

                if (totalCounter > 10000) {
                    //to learn recurrence you have to learn recurrence
                    setStatus('failed');
                    return;
                }
                const { manifest, ageSeconds } = (await parseManifest(cacheStorage, variantUrl)) as any; //MediaPlaylist;

                if (!manifest || manifest.isMasterPlaylist) {
                    testHooks?.debug && console.debug('No valid manifest');
                    return;
                }
                //Downloading N chunks at a time to not kill playback
                const frustratingSegments = manifest.segments as { uri: string; duration: number }[];
                let currentPositionSecs = 0;
                let completedSecs = 0;
                let durationSoFar = 0;
                frustratingSegments.map((x) => (durationSoFar += x.duration));
                duration =
                    estimatedDurationSec != undefined ? Math.max(estimatedDurationSec, durationSoFar) : durationSoFar;

                const results = await ParallelPromises<CacheResult>({
                    tasks: frustratingSegments.map((x) => {
                        let segmentOperation: 'caching' | 'skip';
                        currentPositionSecs += x.duration;
                        if (offsetSeconds) {
                            if (currentPositionSecs < offsetSeconds) {
                                segmentOperation = 'skip';
                            } else {
                                if (currentPositionSecs <= offsetSeconds + maxCachedSeconds + x.duration) {
                                    segmentOperation = 'caching';
                                } else {
                                    segmentOperation = 'skip';
                                }
                            }
                        } else {
                            segmentOperation = 'caching';
                        }
                        return {
                            runPromise: async () => {
                                const url = rewriteUrlForResource(x);
                                if (abortCaching) return { status: 'skipped', url };

                                if (segmentOperation == 'caching') {
                                    testHooks?.debug && console.debug('Segment caching', x.uri);
                                    const result = await cacheUrl(cacheStorage, url);
                                    if (result.status == 'cached' || result.status == 'fetched') {
                                        completedSecs += x.duration;
                                        bumpPercentCompleted(completedSecs);
                                    }
                                    return result;
                                } else if (segmentOperation == 'skip') {
                                    return {
                                        url,
                                        status: 'skipped',
                                    };
                                } else {
                                    throw new Error('Unknown operation');
                                }
                            },
                            label: 'Chunk ' + x.uri,
                        };
                    }),
                    limit: 10,
                });

                bumpPercentCompleted(completedSecs);

                if (abortCaching) return;

                const failedChunks = results.filter((x) => x.status == 'rejected');
                failedChunks.length && console.error('Failed chunks ', failedChunks);
                if (failedChunks.length && !onlineStatusRef.current) {
                    testHooks?.debug && console.debug('Some chunks failed and now we are offline, skipping');
                    setStatus('failed');
                    return;
                    // we check age of the manifest per bug in freud-session related to closing stream properly with a meta tag
                    // checking ageSeconds is a workaround to detect end of generation
                } else if (watch) {
                    if ((!manifest.endlist && ageSeconds < 2 * 60) || failedChunks.length) {
                        failedChunks.length && testHooks?.debug && console.debug('Failed chunks', failedChunks);
                        setStatus('generating');

                        //check again in some time as the stream is not finished
                        setTimeout(() => cacheWithRetry(totalCounter + 1, 0), 2 * 1000);
                    } else {
                        // now that it is the end of generation, we can cache also the manifest files
                        await Promise.all([cacheUrl(cacheStorage, streamUrl), cacheUrl(cacheStorage, variantUrl)]);

                        //TODO respect offset and maximum seconds
                        const finalResult = await checkAllFilesAreCached();
                        testHooks?.debug && console.debug('Final download status', finalResult);
                        if (finalResult.haveAllFiles) {
                            setStatus('done');
                        } else {
                            setStatus('failed');
                        }
                    }
                } else {
                    //TODO verify if not watching if all selected chunks are there
                    setStatus('done');
                }
            } catch (e) {
                if (errorsInRow > 5 && onlineStatusRef.current) {
                    console.error('Consecutive download error while being online', e);
                    setStatus('failed');
                    Sentry.captureException(e);
                    return;
                }

                //try but with exponential back off
                setTimeout(() => cacheWithRetry(totalCounter + 1, errorsInRow + 1), errorsInRow * 5000);
            }
        };
        return { start: cacheWithRetry, stop: stopCaching };
    }

    async function deleteHlsPlaylist() {
        // caches.delete(SW_CACHE_NAME)
        setStatus('deleting');

        const cacheStorage = await caches.open(SW_CACHE_NAME);
        const variantUrl = await getHighestQualityVariantUrl(cacheStorage);
        if (!variantUrl) return;
        try {
            const { manifest } = (await parseManifest(cacheStorage, variantUrl)) as any; //MediaPlaylist;

            if (!manifest || manifest.isMasterPlaylist) return;

            //Downloading N chunks at a time to not kill playback
            const frustratingSegments = manifest.segments as { uri: string }[];

            const results = await ParallelPromises<CacheResult>({
                tasks: frustratingSegments.map((x) => {
                    return {
                        runPromise: async () => {
                            const url = rewriteUrlForResource(x);
                            return deleteUrl(cacheStorage, url);
                        },
                        label: 'Chunk ' + x.uri,
                    };
                }),
                limit: 5,
            });

            const failedChunks = results.filter((x) => x.status == 'rejected');
            if (failedChunks.length) {
                setStatus('failed');
                return;
            }
        } catch (e) {
            console.error('Error deleting', e);
            setStatus('failed');
            Sentry.captureException(e);
            return;
        }
    }

    return {
        cacheHlsPlaylist,
        deleteHlsPlaylist,
        status,
        percentCompleted,
        checkAllFilesAreCached,
    };
};

export const useSessionCache = ({
    audioCacheParams,
    session,
}: {
    audioCacheParams: UseAudioCacheParams;
    session: Session;
}) => {
    const audioCache = useAudioCache(audioCacheParams);
    const sessionCacheManager = useContext(SessionCacheManagerContext);
    if (!sessionCacheManager) {
        throw new Error('Missing SessionCacheManagerContext');
    }

    const cacheSessionInstance = useRef<any>(null);

    const cacheSession = async () => {
        sessionCacheManager.upsertSession({ session });
        cacheSessionInstance.current = await audioCache.cacheHlsPlaylist({ watch: true });
        cacheSessionInstance.current.start();
    };

    const deleteSession = () => {
        sessionCacheManager.removeSession({ sessionId: session.id });
        audioCache.deleteHlsPlaylist();
    };

    const stopCacheSession = () => {
        if (cacheSessionInstance.current) {
            cacheSessionInstance.current.stop();
        } else {
            console.error('No active cache session to stop');
        }
    };

    return {
        cacheSession,
        deleteSession,
        stopCacheSession,
        audioCache,
    };
};

export const useSessionCacheManager = () => {
    // const [cacheList, cacheListActions] = useList<ReturnType<typeof useAudioCache>>();
    const [sessionList, setSessionList] = useLocalStorage<Session[]>('sessionList', []);
    const sessionListSafe = sessionList || [];

    const upsertSession = ({ session }: { session: Session }) => {
        const otherSessions = sessionListSafe.filter((x) => x.id !== session.id) ?? [];
        setSessionList([...otherSessions, session]);
    };

    const removeSession = ({ sessionId }: { sessionId: string }) => {
        setSessionList(sessionListSafe.filter((x) => x.id != sessionId));
    };

    return {
        upsertSession,
        removeSession,
        sessionList: sessionListSafe,
    };
};

export const SessionCacheContext = createContext<ReturnType<typeof useSessionCache>>(
    (null as unknown) as ReturnType<typeof useSessionCache>,
);

export const SessionCacheManagerContext = createContext<ReturnType<typeof useSessionCacheManager>>(
    (null as unknown) as ReturnType<typeof useSessionCacheManager>,
);
