import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core/styles';
import firebase from 'firebase';
import React, { FormEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { Button, Typography } from '@/component-library';

import * as auth from '../../auth';
// import FacebookIcon from '../../images/facebook.svg';
import GoogleIcon from '../../images/google.png';

type LoginStatusType = auth.SignInStatus | auth.SignWithProviderStatus | 'init' | 'loggingIn';

const InputGroup = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '8px',
});

const InputLabel = styled.label({
    fontSize: '14px',
    fontWeight: 500,
    color: '#2C3958',
    marginBottom: 0,
});

const StyledInput = styled.input({
    height: '44px',
    padding: '0 16px',
    background: 'transparent',
    border: '1px solid #6980B4',
    borderRadius: '4px',
    fontSize: '16px',
    color: '#2C3958',
    '&:focus': {
        borderColor: '#2C3958',
    },
});

const InputHelper = styled(Typography)({
    textAlign: 'right',
    color: '#2c3958',
});

const InputLink = styled(Link)({
    color: '#2C3958',
});

const Icon = styled('img')({
    height: '16px',
    width: '16px',
    marginRight: '4px',
});

const LoginError = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
    backgroundColor,
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
    '& a': {
        color: 'white',
        textDecoration: 'underline',
    },
}));

const Main = styled.main({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '24px',
    padding: '16px 0 24px 0',
});

const Footer = styled.footer({
    padding: '0 0 16px 0',
    color: '#2C3958',
});

const FooterTypography = styled(Typography)({
    textAlign: 'center',
    color: '#2c3958',
});

const FooterLink = styled(Link)({
    color: '#2C3958',
});

const Social = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '8px',
});

const SocialButton = styled(Button)({
    color: '#2C3958',
    borderColor: '#ADB9D6',
    '&:hover': {
        borderColor: '#6980B4',
    },
});

const Form = styled.form({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '16px',
});

interface IExsitingUsersProps {
    initialStatus?: LoginStatusType;
}

const ExistingUsers = ({ initialStatus }: IExsitingUsersProps): JSX.Element => {
    const [state, setState] = useState<LoginStatusType>(initialStatus ?? 'init');

    const [defaultEmail] = useLocalStorage<string>('email', '');
    const [email, setEmail] = useState(defaultEmail || '');
    const [password, setPassword] = useState('');

    const signInWithProviderFn = auth.signInWithProviderV2;

    const onSubmit = (evt: FormEvent) => {
        evt.preventDefault();
        setState('loggingIn');
        auth.signIn(email, password).then(handleSignInAttempt);
    };

    const onSubmitWithProvider = async (evt: FormEvent, service: 'google' | 'fb') => {
        evt.preventDefault();
        setState('loggingIn');
        signInWithProviderFn(service).then(handleSignInAttempt);
    };

    const [{ loginReferrer }, , removeCookie] = useCookies(['loginReferrer']);

    const handleSignInAttempt = ({
        status,
    }: {
        status: auth.SignWithProviderStatus | auth.SignInStatus;
        user?: firebase.User;
    }) => {
        setState(status);
        if (status === 'success') {
            if (loginReferrer) {
                const referrer: { pathname: string; search: string; hash: string } = loginReferrer;
                const url = referrer.pathname + referrer.search + referrer.hash;
                console.debug('Redirecting to ', url);
                removeCookie('loginReferrer', { path: '/' });
                window.location.href = url;
            } else {
                window.location.href = '/';
            }
        }
    };

    const theme = useTheme();

    const isLoggingIn = state === 'loggingIn';

    return (
        <>
            <Main>
                {state !== 'init' && (
                    <div>
                        {state === 'userNotFound' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    Sorry, we can't find a user account with those details.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'wrongPassword' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    Password incorrect, please try again.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'userDisabled' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    Sorry, this user account is disabled.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'error' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    Sorry, it looks like something went wrong during sign-in. Please try again or send
                                    us a message at <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'wrongSignInMethod' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    Your email address is associated with a different login method. Please try again
                                    using the service you first logged in with.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'tooManyPopUps' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    There is more than one pop-up window open. Please close all pop-up windows and try
                                    again.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'popUpBlocked' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    The login pop-up window has been blocked by your browser. Please change your browser
                                    settings or use another sign-up method.
                                </Typography>
                            </LoginError>
                        )}
                        {state === 'popUpClosedByUser' && (
                            <LoginError backgroundColor={theme.palette.error.main}>
                                <Typography variant="body3" color="inherit">
                                    The login pop-up window was closed before the process of signing up was completed.
                                    Please try again.
                                </Typography>
                            </LoginError>
                        )}
                    </div>
                )}
                <Social>
                    <SocialButton
                        disabled={isLoggingIn}
                        icon={<Icon src={GoogleIcon} />}
                        size="m"
                        variant="outlined"
                        onClick={(event) => {
                            onSubmitWithProvider(event, 'google');
                        }}
                    >
                        Continue with Google
                    </SocialButton>
                    {/* TODO: BROKEN <SocialButton
                        disabled={isLoggingIn}
                        icon={<Icon src={FacebookIcon} />}
                        size="m"
                        variant="outlined"
                        onClick={(event) => {
                            onSubmitWithProvider(event, 'fb');
                        }}
                    >
                        Continue with Facebook
                    </SocialButton> */}
                </Social>

                <Form onSubmit={onSubmit}>
                    <InputGroup>
                        <InputLabel htmlFor="email">Email address</InputLabel>
                        <StyledInput
                            id="email"
                            aria-label="email"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <StyledInput
                            id="password"
                            aria-label="password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputHelper variant="body3">
                            <InputLink to="/reset-password">Forgotten password?</InputLink>
                        </InputHelper>
                    </InputGroup>
                    <Button
                        disabled={isLoggingIn}
                        type="submit"
                        size="m"
                        variant="solid-blue"
                        style={{ borderRadius: '50px', backgroundColor: '#2C3958' }}
                    >
                        Log in
                    </Button>
                </Form>
            </Main>
            <Footer>
                <FooterTypography variant="body3">
                    By logging into the Wavepaths
                    <br /> you consent to <FooterLink to="/terms">our terms</FooterLink>.
                </FooterTypography>
            </Footer>
        </>
    );
};

export default ExistingUsers;
