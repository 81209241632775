import { isEqual } from 'lodash';
import React, { createContext, memo, useEffect } from 'react';
import { BroadcastPersistentState, SessionRenderType, VoiceoverStage } from 'wavepaths-shared/core';

import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import { useHLSAudioPlayer } from './useHLSAudioPlayer';
import { usePlayerLibAudioPlayer } from './usePlayerLibAudioPlayer';

type PlayerStatus = 'idle' | 'paused' | 'playing' | 'error' | 'loading';
type AudioState = 'init' | 'blocked' | 'active';

export interface AudioPlayerProps {
    outputDevice: string | undefined;
    broadcastIdentifier: string;
    renderType: SessionRenderType;
    errorContext: string;
    mode: 'recording' | 'live';
    broadcastState: BroadcastPersistentState;
    broadcastElapsedTimeSecs: number;
    sessionDuration: number;
    voiceOverStages: VoiceoverStage[];
    playDemoVO?: boolean;
}

export interface AudioPlayer {
    type: 'hls' | 'playerlib' | 'null';
    actions: {
        play: (props?: { fadeMs?: number; loop?: boolean; offsetSeconds?: number }) => void;
        playPrelude: () => void;
        playPostlude: () => void;
        pause: (props: { fadeMs?: number; reason: string }) => void;
        setTime: (seekToTimeSecs: number) => void;
        unblock: () => Promise<void>;
        setVolume: (vol: number) => void;
        end: () => void;
    };
    audioStatus: AudioState;
    playerStatus: PlayerStatus;
    generalPlayerStatus: 'paused' | 'playing';
    warning: string | null;
    currentTimeSecs: number;
    currentBufferSizeSecs: number | undefined;
    volume: number;
    isVolumeControllable: boolean;
}

export const NULL_AUDIO_PLAYER: AudioPlayer = {
    type: 'null',
    actions: {
        play: () => {
            console.log('null play');
        },
        playPrelude: () => {
            console.log('null playPrelude');
        },
        playPostlude: () => {
            console.log('null playPostlude');
        },
        pause: () => {
            console.log('null pause');
        },
        setTime: () => {
            console.log('null setTime');
        },
        unblock: () => Promise.resolve(),
        setVolume: () => {
            console.log('null setVolume');
        },
        end: () => {
            console.log('null end');
        },
    },
    audioStatus: 'init',
    playerStatus: 'idle',
    generalPlayerStatus: 'paused',
    warning: null,
    currentTimeSecs: 0,
    currentBufferSizeSecs: undefined,
    volume: 0,
    isVolumeControllable: false,
};

const HLSAudioPlayer = ({
    playerProps,
    onPlayerChange,
}: {
    playerProps: AudioPlayerProps;
    onPlayerChange: (player: AudioPlayer) => void;
}) => {
    const player = useHLSAudioPlayer(playerProps);
    useEffect(() => {
        onPlayerChange({ type: 'hls', ...player });
    }, [player, onPlayerChange]);
    return null;
};

const PlayerLibAudioPlayer = ({
    playerProps,
    onPlayerChange,
}: {
    playerProps: AudioPlayerProps;
    onPlayerChange: (player: AudioPlayer) => void;
}) => {
    const player = usePlayerLibAudioPlayer(playerProps);
    useEffect(() => {
        onPlayerChange(player);
    }, [player, onPlayerChange]);
    return null;
};

export const AudioPlayerContext = createContext<AudioPlayer | undefined>(undefined);

// Working around react big time here, but should be short-lived code
// only for the duration of feature-flagging between the two audio players.

export type AudioPlayerTypeResolution = 'hls' | 'playerlib' | 'featureFlag';

export const AudioPlayerMaker = memo<{
    playerTypeResolution?: AudioPlayerTypeResolution;
    playerProps: AudioPlayerProps;
    setAudioPlayer: (p: AudioPlayer) => void;
}>(({ playerProps, setAudioPlayer, playerTypeResolution = 'featureFlag' }) => {
    const { isEnabled } = useAuthContext();
    const playerlibFeatureEnabled = isEnabled(Features.PLAYERLIB);
    const playerlibEnabled =
        playerTypeResolution === 'playerlib' ||
        (playerTypeResolution === 'featureFlag' &&
            playerlibFeatureEnabled &&
            (playerProps.renderType === SessionRenderType.PREDICTIVE_COMPOSED ||
                playerProps.renderType === SessionRenderType.PRE_RENDERED));
    return playerlibEnabled ? (
        <PlayerLibAudioPlayer playerProps={playerProps} onPlayerChange={setAudioPlayer} />
    ) : (
        <HLSAudioPlayer playerProps={playerProps} onPlayerChange={setAudioPlayer} />
    );
}, isEqual);
