import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    background-color: var(--color-highlight);
`;

const Text = styled.p`
    font-size: var(--font-size-small);
    line-height: 1.2;
    text-align: center;
    margin: 0;
`;

const Button = styled.button`
    display: inline-block;
    height: 40px;
    padding: 0 2px;
    color: var(--color-primary);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

const UpgradeBanner: React.FC = () => {
    const history = useHistory();
    return (
        <Container>
            <Text>
                To remove voiceover audio and access more features,{' '}
                <Button onClick={() => history.push('/subscriptions')}>upgrade now</Button>
            </Text>
        </Container>
    );
};

export default UpgradeBanner;
