import styled from '@emotion/styled';
import React from 'react';

const Container = styled.header`
    width: 100%;
`;

const Title = styled.h1`
    margin-bottom: var(--font-size-medium);
`;

export interface DashboardPageHeadingProps {
    pageTitle: string;
}

const DashboardPageHeading: React.FC<DashboardPageHeadingProps> = ({ pageTitle }) => {
    return (
        <Container>
            <Title>{pageTitle}</Title>
        </Container>
    );
};

export default React.memo(DashboardPageHeading);
