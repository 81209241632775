import React from 'react';
import { useLocation } from 'react-router-dom';
import { isAdmin } from 'wavepaths-shared/domain/user';

import { Features } from '@/features';

import DashboardLayout from '../src/common/components/Dashboard/DashboardLayout';
import { signOut, useAuthContext } from './auth';

export const LayoutContainer = ({ children }: { children: React.ReactChild[] | React.ReactChild }) => {
    const location = useLocation();
    const { userData, isEnabled } = useAuthContext();

    return (
        <DashboardLayout
            currentRoute={location.pathname}
            userName={userData?.name ?? ''}
            onSignOutClick={signOut}
            isAdmin={!!userData && isAdmin(userData)}
            showSavedTemplates={isEnabled(Features.SAVE_SESSION_TEMPLATES)}
            showTemplatePrograms={isEnabled(Features.SAVE_SESSION_TEMPLATES)} // MK20231003 TODO: perms to show tempalte programs
        >
            {children}
        </DashboardLayout>
    );
};
