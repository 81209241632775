import firebase from 'firebase/app';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import { ActiveBonnySubscription, BonnySubscription, GetBonnySubscriptionResponse } from 'wavepaths-shared/core';

import { offeringsList } from '../../pages/subscriptions/data/offerings';
import { getMySubscription } from '../api/bonnyApi';

interface IUseMySubscriptionReturn {
    subscription?: BonnySubscription;
    error: Error | undefined;
    isLoading: boolean;
    userHasActiveSubscription: boolean;
    activeOfferingTitle: string | undefined;
}

const options = {
    dedupingInterval: 5 * 60 * 1000,
};

function hasActiveSubscription(subscription?: BonnySubscription): boolean {
    if (!subscription) {
        return false;
    }
    return subscription.status !== 'inactive';
}

const useMySubscription = ({ fbUser }: { fbUser: firebase.User | undefined }): IUseMySubscriptionReturn => {
    const [error] = useState<Error | undefined>(undefined);
    const [membershipCacheKey] = useLocalStorage('membershipCacheKey', '');

    const { error: getMySubscriptionError, data, isLoading } = useSWR<GetBonnySubscriptionResponse, Error>(
        fbUser ? ['/my/subscription', fbUser.uid, membershipCacheKey] : null,
        fbUser ? () => getMySubscription(fbUser) : null,
        options,
    );

    const userHasActiveSubscription = hasActiveSubscription(data?.subscription);
    const activeSubscription = data?.subscription as ActiveBonnySubscription;
    const activeOffering =
        activeSubscription && activeSubscription.tier
            ? offeringsList.find((x) => x.tier == activeSubscription.tier)
            : undefined;

    return {
        subscription: data?.subscription,
        isLoading,
        error: error ?? getMySubscriptionError,
        userHasActiveSubscription: userHasActiveSubscription,
        activeOfferingTitle: activeOffering ? activeOffering.offeringTitle : undefined,
    };
};

export default useMySubscription;
