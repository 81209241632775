import { compact, uniqBy } from 'lodash';

import { ListSavedTemplatesItem } from '../../common/api/savedTemplatesApi';
import { ISessionsData } from '../sessions/useSessions';

type RecentlyUsedTemplate = { type: 'saved'; template: ListSavedTemplatesItem } | { type: 'template'; id: string };

export const useRecentlyUsedTemplates = (
    n: number,
    endedSessions: ISessionsData,
    savedTemplates?: ListSavedTemplatesItem[],
) => {
    const recentlyUsedTemplates: RecentlyUsedTemplate[] = uniqBy(
        compact(
            endedSessions.sessions.map((s) => {
                const savedTemplate = savedTemplates?.find((t) => t.name === s.variableInputs.name);
                if (savedTemplate) {
                    const tpl: RecentlyUsedTemplate = { type: 'saved', template: savedTemplate };
                    return tpl;
                } else if (s.score?.id) {
                    const tpl: RecentlyUsedTemplate = { type: 'template', id: s.score?.id };
                    return tpl;
                }
            }),
        ),
        (tpl) => (tpl.type === 'saved' ? tpl.template.id : tpl.id),
    ).slice(0, n);

    return recentlyUsedTemplates;
};
