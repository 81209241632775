import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { NewsArticle } from './NewsArticle';
import { useNewsArticleLatest } from './useNews';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Article = styled.div`
    padding: 12px 32px 16px 32px;
    background: var(--color-background-highlight);
`;

function NewsLatestContainer() {
    const { article: articleSelected } = useNewsArticleLatest();
    const history = useHistory();

    if (!articleSelected) {
        return null;
    }

    return (
        <Container>
            <h3>Latest news</h3>
            <Article>
                <NewsArticle
                    key={articleSelected.id}
                    article={articleSelected}
                    onClick={() => history.push('/news?article=' + articleSelected.id)}
                />
            </Article>
        </Container>
    );
}

export default NewsLatestContainer;
