import firebase from 'firebase';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseUser, SessionHeader, SessionRenderType } from 'wavepaths-shared/core';

import { Features } from '@/features';

import { useAuthContext } from '../../../auth';
import { getSession } from '../../../common/api/sessionApi';
import { convertSessionToSessionTemplate } from '../../../common/api/sessionTemplatesApi';
import { formatDateTime } from '../../../dateUtilsV2';
import {
    getAbsoluteSessionAnonymousRecordingLink,
    getAbsoluteSessionIntegrationLink,
    getAbsoluteSessionRecordingLink,
} from '../../../formUtils/formUtils';
import UserEvents, { CopiedSessionTab } from '../../../UserEvents';
import { renderSessionClients, renderSessionName } from '../helpers';
import SessionCard from '../SessionCard';
import { ShareClientLinkDialog } from '../SharePatientLinkDialog';
import { fetchExpirationInfo } from '../utils';
//import { ResponseStatus } from './evaluateIntegrationFormStatus';
// import { SessionAudioPlayer } from './SessionAudioPlayer';
//import useFormResponses from './useFormResponses';

export interface Props {
    session: SessionHeader;
    isAdmin?: boolean;
    fbUser: firebase.User;
    signups?: { [sessionId: string]: FirebaseUser[] };
}
const EndedSessionCard = ({ session, isAdmin, fbUser }: Props): React.ReactElement => {
    const { isEnabled } = useAuthContext();
    let isIntegrationEnabled = isEnabled(Features.CLIENT_INTEGRATION);
    const history = useHistory();
    const sessionName = renderSessionName(session);
    const clientNames = renderSessionClients(session);

    const userInfoText = session?.user?.name;

    const onClickLog = () => {
        history.push(`/logs/${session.id}`);
    };

    const onClickDetails = () => {
        history.push(`/session/details/${session.id}`);
    };

    const handleMakeCopy = async () => {
        UserEvents.copiedSession(CopiedSessionTab.COMPLETED);
        const fullSession = await getSession(session.id, fbUser);
        const sessionTemplate = convertSessionToSessionTemplate(fullSession);
        history.push({
            pathname: '/session/new/oneOnOne',
            state: { sessionTemplate }, // your data array of objects
        });
    };
    const sessionInfo = [
        `${session.renderType === SessionRenderType.PRE_RENDERED ? 'Generated' : 'Ended'} ${formatDateTime(
            session.endedAt,
        )}`,
    ];

    const { isExpired, expirationMessage } = fetchExpirationInfo(session);
    if (isExpired) {
        isIntegrationEnabled = false;
    }
    if (expirationMessage) {
        sessionInfo.push(expirationMessage);
    }

    return (
        <div>
            <SessionCard
                info={sessionInfo}
                title={sessionName}
                subtitle={clientNames}
                userInfoText={isAdmin && userInfoText ? userInfoText : undefined}
                primaryActionLabel={'Details'}
                primaryAction={onClickDetails}
                actionsComponent={
                    <>
                        {isIntegrationEnabled ? (
                            <>
                                <ShareClientLinkDialog
                                    linkText={'Share Recording Only'}
                                    linkUrl={getAbsoluteSessionRecordingLink(session.id)}
                                    anonymousLinkUrl={getAbsoluteSessionAnonymousRecordingLink(session.id)}
                                    clients={session.clients}
                                    belowHeaderInfoText={expirationMessage}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        {isIntegrationEnabled ? (
                            <ShareClientLinkDialog
                                linkText={'Share Recording + Integration Form'}
                                linkUrl={getAbsoluteSessionIntegrationLink(session.id)}
                                clients={session.clients}
                                belowHeaderInfoText={expirationMessage}
                            />
                        ) : null}
                    </>
                }
                actions={[
                    { description: 'Make A Copy', action: handleMakeCopy },
                    ...(isAdmin ? [{ description: 'Log', action: onClickLog }] : []),
                ]}
                // footer={
                //     <SessionAudioPlayer
                //         onPlay={() => UserEvents.completedSessionAudioPlayed({ sessionId: session.id })}
                //         broadcastIdentifier={session.broadcastIdentifier}
                //         errorContext="Ended Session"
                //     />
                // }
                subActions={[]}
            />
        </div>
    );
};

export default EndedSessionCard;
