import * as Sentry from '@sentry/browser';
import Hls, { ErrorData, Events } from 'hls.js';
import { partial } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { BroadcastPersistentState, VoiceoverStage } from 'wavepaths-shared/core';

import { TestHooksContext } from '@/hooks/useTestHooks';
import { useStateWithRef } from '@/util/useStateWithRef';

import * as audio from '../../audio';
import configs from '../../configs';
import {
    DEFAULT_HLS_FRAGMENT_BUFFER_COUNT,
    drainAndSummarizeNetworkStats,
    getHlsErrorContext,
    getHlsEventData,
    NetworkStats,
} from '../../freudConnection/hlsUtils';
import { useAudioDevices } from './useAudioDevices';
//wa have to use silence as iPhone volume is not controllable and there is a glitch between play().pause() in unblocking
const UNBLOCKER_SILENCE_FILE_URL = '/silence.mp3';

type PlayerStatus = 'idle' | 'paused' | 'playing' | 'error' | 'loading';
type AudioState = 'init' | 'blocked' | 'active';

const setSinkId = (deviceId: any) => {
    const audioContextLooselyTyped = audio.audioCtx as any;
    if (audioContextLooselyTyped.setSinkId) {
        if (audioContextLooselyTyped.sinkId !== deviceId) {
            audioContextLooselyTyped.setSinkId(deviceId);
        }
    }
};

const NETWORK_STATS_REPORT_INTERVAL = 10 * 60 * 1000;

const reportNetworkStats = (networkStats: NetworkStats[], broadcastId: string) => {
    if (networkStats.length === 0) return;
    const stats = drainAndSummarizeNetworkStats(networkStats);
    Sentry.withScope((scope) => {
        scope.setExtra('broadcastIdentifier', broadcastId);
        scope.setExtra('streamingStats', stats);
        Sentry.captureMessage('Streaming stats');
    });
};

let lastMediaDeviceChangeAt: number | undefined = undefined;
!!navigator.mediaDevices &&
    !!navigator.mediaDevices.addEventListener &&
    navigator.mediaDevices.addEventListener('devicechange', () => {
        lastMediaDeviceChangeAt = Date.now();
        Sentry.addBreadcrumb({
            category: 'streaming',
            data: { mediaDeviceEvent: 'devicechange' },
        });
    });

const noGainSupportForHls = navigator.vendor ? navigator.vendor.indexOf('Apple') > -1 : false;

interface PlayerInternal {
    public: {
        errorContext: string;
        actions: {
            loadSource: () => Promise<void>;
            play: (props: { fadeMs?: number; loop?: boolean; offsetSeconds?: number }) => void;
            pause: (props: { fadeMs?: number; reason: string }) => void;
            setTime: (seekToTimeSecs: number) => void;
            unblock: () => Promise<void>;
            setVolume: (props: { vol: number; fadeMs?: number }) => void;
            end: () => void;
        };
        streamUrl: string;
    };
    protected: {
        destroy: (props?: { immediate?: boolean }) => void;
        streamUrlRef: React.MutableRefObject<string>;
        bufferSizeSecs: React.MutableRefObject<number | undefined>;
        audioStatusRef: React.MutableRefObject<AudioState | undefined>;
        errorContext: string;
        isInTheMiddleOfSeeking: boolean;
    };
}

interface Player {
    public: {
        errorContext: string;
        actions: {
            loadSource: () => Promise<void>;
            play: (props: { fadeMs?: number; loop?: boolean; offsetSeconds?: number }) => void;
            pause: (props: { fadeMs?: number; reason: string }) => void;
            setTime: (seekToTimeSecs: number) => void;
            unblock: () => Promise<void>;
            setVolume: (props: { vol: number; fadeMs?: number }) => void;
            end: () => void;
        };
        streamUrl: string;
        audioStatus: AudioState;
        playerStatus: PlayerStatus;
        warning: string | null;
        currentTimeSecs: number;
        duration: number | undefined;
        volume: number;
    };
    protected: {
        destroy: (props?: { immediate?: boolean }) => void;
        streamUrlRef: React.MutableRefObject<string>;
        bufferSizeSecs: React.MutableRefObject<number | undefined>;
        audioStatusRef: React.MutableRefObject<AudioState | undefined>;
        errorContext: string;
        audioElRef: React.MutableRefObject<HTMLAudioElement | null>;
        isInTheMiddleOfSeeking: boolean;
    };
}

const useRawAudioPlayer = ({
    broadcastIdentifier,
    errorContext,
    initialStreamUrl,
    mode,
    forceNativeAudio = false,
    forceHlsJsLib = false,
    bufferProtection = true,
    isVolumeControllable,
    isHlsFormat,
}: {
    broadcastIdentifier: string;
    initialStreamUrl: string;
    errorContext: string;
    mode: 'live' | 'recording';
    forceNativeAudio?: boolean;
    forceHlsJsLib?: boolean;
    isHlsFormat: boolean;
    bufferProtection?: boolean;
    isVolumeControllable: boolean;
}): Player => {
    const errorReportContext = errorContext + ` Audio Player`;
    const testHooks = useContext(TestHooksContext);
    const [audioStatus, setAudioStatus] = useStateWithRef<AudioState>('init');
    const [playerStatus, setPlayerStatus] = useStateWithRef<PlayerStatus>('idle');
    const [warning, setWarning] = useStateWithRef<string | null>(null);
    const [currentTimeSecs, setCurrentTimeSecs] = useStateWithRef<number>(0);

    const _streamUrl = useRef<string>(initialStreamUrl);
    const volumeRef = useRef<number>(1);

    const isVolumeControllableRef = useRef(isVolumeControllable);
    useEffect(() => {
        isVolumeControllableRef.current =
            (isVolumeControllable || testHooks?.controlVolumeBy === 'gain') && !testHooks?.noVolume;
    }, [isVolumeControllable]);

    const [duration, setDuration] = useStateWithRef<number | undefined>(undefined);

    const resumeAudio = async () => {
        try {
            await audio.resumeAudioContext();
        } catch {
            // We have no permission to start
            setAudioStatus('blocked');
        }
    };

    const bufferSizeSecs = useRef<number | undefined>(undefined);
    const audioElRef = useRef<HTMLAudioElement | null>(null);

    const init = (): PlayerInternal => {
        let audioGain: GainNode | undefined = undefined;
        let hls: Hls | null = null;
        const audioEl: HTMLAudioElement = document.createElement('audio');
        audioElRef.current = audioEl;
        audioEl.src = UNBLOCKER_SILENCE_FILE_URL;
        audioEl.controls = true;
        audioEl.style.height = '15px';
        let networkStats: NetworkStats[] = [];
        let streamSrc: MediaElementAudioSourceNode | null = null;

        let bufferingAfterSeekTimer: NodeJS.Timeout | undefined = undefined;

        const cancelPendingVolumeTimers = () => {
            bufferingAfterSeekTimer && clearTimeout(bufferingAfterSeekTimer);
            bufferingAfterSeekTimer = undefined;
        };

        let minBufferSizeSecs: number | undefined = undefined;
        let metaLoaded = false;
        let lastSeekTime: number = Date.now();

        const SEEK_STALL_TOLERANCE = 10 * 1000;

        let shouldBePlaying = false;

        const pauseAudioEl = ({ reason }: { reason: string }) => {
            console.debug('pauseAudioEl', errorContext, { reason });
            audioEl.pause();
        };

        const refreshBufferSizes = () => {
            if (!audioEl.buffered) {
                bufferProtection && testHooks?.debugBuffering && console.debug('No buffer info for ' + errorContext);
                return;
            }

            bufferSizeSecs.current = 0;
            for (let i = 0; i < audioEl.buffered.length; i++) {
                const startSeconds = audioEl.buffered.start(i);
                const endSeconds = audioEl.buffered.end(i);
                if (startSeconds <= audioEl.currentTime && endSeconds >= audioEl.currentTime) {
                    bufferSizeSecs.current = Math.round((endSeconds - audioEl.currentTime) * 100) / 100;
                    break;
                }
            }
            if (!minBufferSizeSecs) {
                minBufferSizeSecs = bufferSizeSecs.current;
            } else {
                minBufferSizeSecs = Math.min(minBufferSizeSecs, bufferSizeSecs.current);
            }

            setDuration(audioEl.duration);

            // bufferProtection &&
            //     testHooks?.debugBuffering &&
            //     console.debug({ errorContext, bufferSizeSecs, duration, shouldBePlaying });
        };

        let retryCounter = 0;
        let isScheduledRetryLoadNativeAudio = false;

        const retryLoadSourceNativeAudio = () => {
            if (isScheduledRetryLoadNativeAudio) {
                console.debug('Skip retry as there is a pending reload');
                return;
            }
            isScheduledRetryLoadNativeAudio = true;
            retryCounter++;
            if (retryCounter > 1000) {
                Sentry.captureMessage('Unable to play native audio', 'error');
                setWarning('We are unable to play. Our team is notified. Please try again later.');
                setPlayerStatus('error');
            }
            console.debug('Error loading stream when using native audio, trying again in some time', retryCounter);
            console.debug('Attempt to go back to stream position at secs: ', progressiveCurrentTimeSecs);
            setTimeout(() => {
                isScheduledRetryLoadNativeAudio = false;
                console.debug('Retry loading using native audio', {
                    retryCounter,
                    progressiveCurrentTimeSecs,
                    errorContext,
                });

                audioEl.src = _streamUrl.current + '#t=' + retryCounter;
                audioEl.load();
                if (shouldBePlaying) {
                    setTime(progressiveCurrentTimeSecs);
                }
            }, 1000 + retryCounter * 100);
        };

        const monitorBuffer = () => {
            if (bufferProtection && testHooks?.debugBuffering) {
                console.debug('Buffer info ' + errorContext, {
                    metaLoaded,
                    currentBufferSizeSecs: bufferSizeSecs.current,
                    currentTime: audioEl?.currentTime,
                    lastSeekTime,
                    lastSeekTimeInGrace: lastSeekTime >= Date.now() - SEEK_STALL_TOLERANCE,
                    duration: duration.current,
                    readyState: audioEl?.readyState,
                    ended: audioEl?.ended,
                    shouldBePlaying,
                    volumeTimer: bufferingAfterSeekTimer,
                    bufferProtection,
                    lastMediaDeviceChangeAt,
                    isInTheMiddleOfSeeking,
                });
            }

            if (
                bufferSizeSecs.current === undefined ||
                !metaLoaded ||
                audioEl.ended ||
                !shouldBePlaying ||
                bufferingAfterSeekTimer ||
                !bufferProtection ||
                lastSeekTime >= Date.now() - SEEK_STALL_TOLERANCE ||
                isInTheMiddleOfSeeking
            )
                return;

            const BUFFER_WARNING_THRESHOLD_SECONDS = 2;

            if (bufferSizeSecs.current < BUFFER_WARNING_THRESHOLD_SECONDS) {
                Sentry.captureMessage('Very Low Buffer');

                console.debug('Very Low Buffer');
                setWarning('Network issue, low audio buffer');
            }
        };

        const timers: {
            networkStatsTimer?: NodeJS.Timeout;
            bufferMonitorTimer?: NodeJS.Timeout;
            debugAudioTimer?: NodeJS.Timeout;
        } = {};

        const restartTimers = () => {
            timers.networkStatsTimer = setInterval(() => {
                reportNetworkStats(networkStats, broadcastIdentifier);
                networkStats = [];
                minBufferSizeSecs = undefined;
            }, NETWORK_STATS_REPORT_INTERVAL);

            const BUFFER_SAMPLING_INTERVAL = 1000;
            timers.bufferMonitorTimer = setInterval(() => {
                refreshBufferSizes();
                monitorBuffer();
            }, BUFFER_SAMPLING_INTERVAL);

            timers.debugAudioTimer = setInterval(async () => {
                if (testHooks?.debugAudio) {
                    console.debug('Audio ' + errorContext, {
                        audioEl,
                        audioCtx: audio.audioCtx,
                        audioGain,
                        playerStatus: playerStatus.current,
                        audioStatus: audioStatus.current,
                        metaLoaded,
                    });
                }
            }, 5000);
        };

        audioEl.crossOrigin = 'anonymous';

        audioGain = audio.audioCtx.createGain();
        streamSrc = audio.audioCtx.createMediaElementSource(audioEl);

        //TODO: This is basically ignored on iOS when using HLS, gain changes not having any effect
        streamSrc.connect(audioGain);
        audioGain.connect(audio.audioCtx.destination);

        let previousRoundedCurrentTimeSecs: number | undefined = undefined;
        let progressiveCurrentTimeSecs = 0;

        const loadSource = async () => {
            metaLoaded = false;

            if ((Hls.isSupported() && !forceNativeAudio) || forceHlsJsLib) {
                if (hls !== null) {
                    throw new Error('old Hls is still there for ' + errorContext);
                }
                console.debug(`Using Hls.js for ${errorContext}`);

                const hlsLogger = (level: string, ...msg: string[]) => {
                    // Sentry.addBreadcrumb({
                    //     category: 'streaming',
                    //     data: { level, msg: msg.join(' ') },
                    // });
                    testHooks?.debugHls && console.debug('HLS event', level, msg);
                };

                const fragCount = DEFAULT_HLS_FRAGMENT_BUFFER_COUNT;

                const hlsBaseConfig =
                    mode == 'live'
                        ? {
                              liveSyncDurationCount: fragCount,
                              liveMaxLatencyDurationCount: fragCount + 1,
                              initialLiveManifestSize: fragCount,
                              liveDurationInfinity: true, // Important for Safari; it won't end the stream if we temporarily cannot extend its duration
                          }
                        : {
                              startPosition: 0,
                          };

                hls = new Hls({
                    ...hlsBaseConfig,
                    fetchSetup: function (context, initParams) {
                        initParams.credentials = 'include';
                        return new Request(context.url, initParams);
                    },
                    startLevel: 0,
                    abrEwmaFastLive: 0.5,
                    abrEwmaSlowLive: 6,
                    lowLatencyMode: false,
                    debug: {
                        trace: partial(hlsLogger, 'trace'),
                        debug: partial(hlsLogger, 'debug'),
                        log: partial(hlsLogger, 'log'),
                        warn: partial(hlsLogger, 'warn'),
                        info: partial(hlsLogger, 'info'),
                        error: partial(hlsLogger, 'error'),
                    },
                });

                hls.loadSource(_streamUrl.current);
                hls.attachMedia(audioEl);
                let recoveryCounter = 0;
                const retryManifestLoad = (_event: 'hlsError', data: ErrorData) => {
                    if (data.details !== Hls.ErrorDetails.MANIFEST_LOAD_ERROR) return;

                    console.debug('HLS Manifest not available, retrying in 1s');
                    recoveryCounter++;
                    if (recoveryCounter < 100) {
                        setTimeout(() => hls?.loadSource(_streamUrl.current), 1000);
                    } else if (recoveryCounter == 100) {
                        setWarning('Failed to load media. Please try again later.');
                        Sentry.withScope((scope) => {
                            scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                            scope.setExtra('context', getHlsErrorContext(data));
                            Sentry.captureMessage(`Streaming manifest error in ${errorReportContext}`);
                        });
                    }
                };
                hls.on(Hls.Events.ERROR, retryManifestLoad);
                hls.on(Hls.Events.FRAG_BUFFERED, (e, data: any) => {
                    recoveryCounter = 0;
                    networkStats.push({
                        trequest: data.frag.stats.loading.start,
                        tload: data.frag.stats.buffering.end,
                        bwEstimate: data.frag.stats.bwEstimate,
                        minBufferSizeSecs: minBufferSizeSecs,
                    });
                });

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log('HLS Manifest loaded ' + errorContext);
                    hls?.off(Hls.Events.ERROR, retryManifestLoad);
                    metaLoaded = true;

                    if (testHooks?.debugHls) {
                        Object.values(Hls.Events).forEach((e) => {
                            hls?.on(e, (event: Events, data: any) => {
                                const evtData = getHlsEventData(event, data);
                                if (evtData) {
                                    Sentry.addBreadcrumb({
                                        category: 'streaming',
                                        data: evtData,
                                    });
                                }
                            });
                        });
                    }

                    hls?.on(Hls.Events.ERROR, (_event, data) => {
                        if (!data.fatal) return;

                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                recoveryCounter++;
                                console.debug('fatal network error encountered, try to recover', errorContext);

                                //TODO - restore when less false-positive due to end of stream error
                                // Sentry.withScope((scope) => {
                                //     scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                //     scope.setExtra('context', getHlsErrorContext(data));
                                //     Sentry.captureMessage(`Streaming network error in ${errorReportContext}`);
                                // });
                                setTimeout(
                                    () => hls?.startLoad(progressiveCurrentTimeSecs),
                                    1000 + recoveryCounter * 1000,
                                );
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.error('fatal media error encountered, try to recover', data);
                                Sentry.withScope((scope) => {
                                    scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                    scope.setExtra('context', getHlsErrorContext(data));
                                    Sentry.captureMessage(`Streaming media error in ${errorReportContext}`);
                                });
                                recoveryCounter++;
                                if (recoveryCounter < 100) {
                                    setTimeout(() => hls?.recoverMediaError(), recoveryCounter * 1000);
                                }
                                break;
                            default:
                                console.error('fatal streaming error', data);
                                Sentry.withScope((scope) => {
                                    scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                    scope.setExtra('context', getHlsErrorContext(data));
                                    Sentry.captureMessage(`Streaming error in ${errorReportContext}`);
                                });
                                break;
                        }
                    });
                });
            } else if (audioEl.canPlayType('application/vnd.apple.mpegurl') || forceNativeAudio) {
                console.debug(`Using native audio for ${errorContext}`);
                audioEl.src = _streamUrl.current;
                console.debug('Setting onloadsource currentTime to ', progressiveCurrentTimeSecs, errorContext);
                audioEl.currentTime = progressiveCurrentTimeSecs;
                if (mode === 'recording') {
                    audioEl.preload = 'none';
                }
                setPlayerStatus('loading');

                audioEl.addEventListener('error', retryLoadSourceNativeAudio);

                audioEl.addEventListener(
                    'loadedmetadata',
                    () => {
                        metaLoaded = true;
                        // audioEl?.removeEventListener('error', retryLoadSourceNativeAudio);
                        if (playerStatus.current == 'loading') {
                            setPlayerStatus('idle');
                        }
                    },
                    { once: true },
                );

                audioEl.addEventListener('onloadeddata', () => {
                    console.debug('Setting onloadeddata currentTime to ', progressiveCurrentTimeSecs, errorContext);

                    audioEl.currentTime = progressiveCurrentTimeSecs;
                });

                audioEl.addEventListener('onload', () => {
                    console.debug('Setting onload currentTime to ', progressiveCurrentTimeSecs, errorContext);

                    audioEl.currentTime = progressiveCurrentTimeSecs;
                });

                audioEl.addEventListener('error', () => {
                    console.debug('error on audioEl', errorContext);
                });
                if (bufferProtection) {
                    audioEl.addEventListener('waiting', () => {
                        console.debug('waiting on audioEl', errorContext);
                    });

                    audioEl.addEventListener('stalled', () => {
                        console.debug('stalled on audioEl', errorContext);
                    });
                }
                // audioEl.addEventListener('suspend', () => {
                //     console.debug('suspend on audioEl', errorContext);
                // });
                audioEl.addEventListener('pause', () => {
                    console.debug('pause on audioEl', errorContext);
                });

                audioEl.load();
            } else {
                // TODO: handle
                throw new Error('Browser not supported');
            }

            // Common code for both HLS and native <audio>
            audioEl.addEventListener('seeking', () => {
                console.debug('Seeking...');
            });

            audioEl.ontimeupdate = () => {
                //only set full seconds so we dont flood rendering
                const newTime = Math.round(audioEl.currentTime ?? 0);
                if (newTime === currentTimeSecs.current) return;

                setCurrentTimeSecs(newTime);
                progressiveCurrentTimeSecs =
                    progressiveCurrentTimeSecs < currentTimeSecs.current
                        ? currentTimeSecs.current
                        : progressiveCurrentTimeSecs;

                if (currentTimeSecs.current !== previousRoundedCurrentTimeSecs) {
                    // if (testHooks?.debugPlayers && bufferProtection) {
                    //     console.debug('Time updated to :', _currentTimeSecs.current, errorContext);
                    // }
                    previousRoundedCurrentTimeSecs = currentTimeSecs.current;
                }
            };

            audioEl.onended = () => {
                shouldBePlaying = false;
            };

            restartTimers();
        };

        const play = (props: { fadeMs?: number; loop?: boolean; offsetSeconds?: number } = {}) => {
            shouldBePlaying = true;
            lastSeekTime = Date.now();
            console.debug('Playing ' + errorContext, { props });
            if (props.offsetSeconds !== undefined) {
                setCurrentTimeSecs(Math.round(props.offsetSeconds ?? 0));
                audioEl.currentTime = props.offsetSeconds;
                progressiveCurrentTimeSecs = props.offsetSeconds;
            }
            setPlayerStatus('loading');
            cancelPendingVolumeTimers();
            changeVolume({ targetVolume: 0, fadeMs: 0 }); // if the session is in the middle you dont want to bang the speakers immediately

            audioEl.loop = props.loop !== undefined ? props.loop : false;
            audioEl
                .play()
                .then(() => {
                    if (mode == 'recording') {
                        //when stream is being generated, audio doesnt start from the beginning. Here we force it to current playback position
                        console.debug('Setting currentTime after play to ', progressiveCurrentTimeSecs, errorContext);
                        audioEl.currentTime = progressiveCurrentTimeSecs;
                    }
                    setPlayerStatus('playing');
                    setAudioStatus('active');
                    towardsTargetVolume({ targetVolume: volumeRef.current, fadeMs: props.fadeMs });
                })
                .catch((e) => {
                    setPlayerStatus('error');
                    setAudioStatus('init');
                    console.error('Audio play error', errorContext, e);
                });
        };

        const pause = (props: { fadeMs?: number; reason: string } = { reason: 'unknown' }) => {
            shouldBePlaying = false;
            setPlayerStatus('paused');
            towardsTargetVolume({
                targetVolume: 0,
                fadeMs: props?.fadeMs ?? 1002,
                then: () => {
                    pauseAudioEl({ reason: props.reason });
                },
            });
        };

        const end = () => {
            shouldBePlaying = false;
            setPlayerStatus('paused');
            towardsTargetVolume({
                targetVolume: 0,
                then: () => {
                    pauseAudioEl({ reason: 'end' });
                },
            });
        };
        // const recover = () => {
        //     if (!shouldBePlaying) {
        //         return;
        //     }
        //     if (hls) {
        //         console.debug('Reloading hls after recover');
        //         hls.startLoad();
        //     } else {
        //         //Iphone going back online - recover loading position if stuck
        //         if (audioEl.readyState <= audioEl.HAVE_CURRENT_DATA) {
        //             audioEl.load();
        //             if (mode == 'recording') {
        //                 console.debug('Setting currentTime in recover()', progressiveCurrentTimeSecs, errorContext);
        //                 audioEl.currentTime = progressiveCurrentTimeSecs;
        //             }
        //         }
        //         shouldBePlaying && audioEl.play();
        //     }
        // };
        const destroy = (props?: { immediate?: boolean }) => {
            shouldBePlaying = false;
            console.debug('Destroy ' + errorContext);
            setPlayerStatus('idle');
            setAudioStatus('init');

            timers.bufferMonitorTimer && clearInterval(timers.bufferMonitorTimer);
            timers.networkStatsTimer && clearInterval(timers.networkStatsTimer);
            timers.debugAudioTimer && clearInterval(timers.debugAudioTimer);
            //gracefully turn off music
            const jobAfterVolumeDown = () => {
                if (hls) {
                    hls?.destroy();
                    hls = null;
                }

                setCurrentTimeSecs(0);
                progressiveCurrentTimeSecs = 0;
                audioEl.src = '';
                audioEl.removeAttribute('src');
                pauseAudioEl({ reason: 'destroy' });
            };
            if (props?.immediate) {
                jobAfterVolumeDown();
            } else {
                towardsTargetVolume({
                    targetVolume: 0,
                    then: jobAfterVolumeDown,
                });
            }
        };

        let lastCtxVolumeTime: number | undefined;
        let volumeSequence = 1;
        const changeVolume = ({ targetVolume, fadeMs }: { targetVolume: number; fadeMs: number }) => {
            if (!audioGain) {
                console.debug('No gain');
                return;
            }
            const targetGain = Math.min(0.000001 + targetVolume, 1.0);
            testHooks?.debugGain &&
                console.debug('Volume change', {
                    errorContext,
                    targetVolume,
                    targetGain,
                    fadeMs,
                    ctxTime: audio.audioCtx.currentTime,
                    valueNow: audioGain.gain.value,
                });
            if (lastCtxVolumeTime === audio.audioCtx.currentTime) {
                volumeSequence++;
            } else {
                volumeSequence = 1;
            }

            if (isHlsFormat && noGainSupportForHls) {
                // gain and fading is not working on Safari when using HLS format so we fallback to immediate volume change
                // that might still not be effective on devices where volume is not controllable (ipad/iphone) but atleast we try...
                audioEl.volume = targetVolume;
            } else {
                if (fadeMs === 0) {
                    audioGain.gain.cancelScheduledValues(audio.audioCtx.currentTime + volumeSequence * 0.001);
                    audioGain.gain.value = targetGain;
                } else {
                    audioGain.gain.setValueAtTime(
                        audioGain.gain.value,
                        audio.audioCtx.currentTime + volumeSequence * 0.002,
                    );
                    audioGain.gain.linearRampToValueAtTime(
                        targetGain,
                        audio.audioCtx.currentTime + fadeMs / 1000 + volumeSequence * 0.003,
                    );
                }
            }

            lastCtxVolumeTime = audio.audioCtx.currentTime;
        };

        const towardsTargetVolume = ({
            targetVolume,
            fadeMs = 1001,
            then = () => {
                /*@ts-ignore*/
            },
            cancelPending = true,
            towardsCounter = 0,
        }: {
            targetVolume: number;
            fadeMs?: number;
            then?: () => void;
            cancelPending?: boolean;
            towardsCounter?: number;
        }) => {
            if (cancelPending) {
                bufferingAfterSeekTimer && clearTimeout(bufferingAfterSeekTimer);
                bufferingAfterSeekTimer = undefined;
            }

            if (!audioGain) {
                console.debug('No audioGain');
                return;
            }

            if (towardsCounter > 10000) {
                //to learn recurrence you have to learn recurrence
                throw new Error('Recurrence error towardsTargetVolume');
            }
            if (!isVolumeControllableRef.current || fadeMs === 0) {
                //forcing volume to target, if by any case isVolumeControllable is not accurate
                changeVolume({ targetVolume: targetVolume, fadeMs: 0 });
                // if we cant control the volume then we can finish here
                then && then();
                return;
            }

            changeVolume({ targetVolume: targetVolume, fadeMs });

            then &&
                setTimeout(() => {
                    then();
                }, fadeMs);
        };
        let isInTheMiddleOfSeeking = false;
        const setTime = (seekToTimeSecs: number) => {
            if (audioEl.duration > 0 && seekToTimeSecs >= audioEl.duration) {
                console.debug('Music is not available yet at this position', {
                    duration: audioEl.duration,
                    seekToTimeSecs,
                });
                setWarning('Music is not available yet at this position of the session, please wait and try again');
                return;
            }
            lastSeekTime = Date.now();
            isInTheMiddleOfSeeking = true;

            shouldBePlaying = true;
            setPlayerStatus('playing');
            towardsTargetVolume({
                targetVolume: 0,
                then: () => {
                    setCurrentTimeSecs(seekToTimeSecs);
                    progressiveCurrentTimeSecs = seekToTimeSecs;
                    console.debug('Setting currentTime in setTime to ', seekToTimeSecs);
                    audioEl.currentTime = seekToTimeSecs;

                    const waitForBufferingToPlay = (loadCounter = 0) => {
                        bufferingAfterSeekTimer && clearTimeout(bufferingAfterSeekTimer);

                        if (loadCounter > 1000) {
                            //to learn recurrence you have to learn recurrence
                            throw new Error('Recurrence error waitForLoad');
                        }

                        //MK20240316 - on native audio this doesnt load more than 2s... so need to rely on HAVE_FUTURE_DATA
                        // const haveEnoughBuffer =
                        //     bufferSizeSecs.current !== undefined
                        //         ? bufferSizeSecs.current > BUFFER_MIN_RECOVERY_THRESHOLD_SECONDS
                        //         : true;
                        loadCounter > 0 &&
                            loadCounter % 10 === 0 &&
                            console.debug('Waiting for load ' + errorContext, {
                                readyState: audioEl.readyState,
                                currentBufferSizeSecs: bufferSizeSecs.current,
                                currentTimeAudioEl: audioEl.currentTime,
                                duration: audioEl.duration,
                            });
                        if (audioEl.readyState >= audioEl.HAVE_FUTURE_DATA) {
                            setWarning('');
                            if (!shouldBePlaying) {
                                isInTheMiddleOfSeeking = false;
                                return;
                            }
                            audioEl.play().then(() =>
                                setTimeout(() => {
                                    towardsTargetVolume({ targetVolume: volumeRef.current });
                                    isInTheMiddleOfSeeking = false;
                                }, 100),
                            );
                        } else {
                            setWarning('Loading...');
                            bufferingAfterSeekTimer = setTimeout(() => waitForBufferingToPlay(loadCounter + 1), 100);
                        }
                    };
                    waitForBufferingToPlay();
                },
            });
        };

        //TODO unblock is not specific to 1 audio element, so could be extracted out of here
        const unblock = async () => {
            if (audioStatus.current === 'active') return;
            console.debug('Attempt to unblock ' + errorContext);
            setVolume({ vol: 0, fadeMs: 0 });
            audioEl.src = UNBLOCKER_SILENCE_FILE_URL;
            try {
                await Promise.all([audioEl.play(), resumeAudio()]);
                setAudioStatus('active');
                console.debug('Unblocked ' + errorContext);
                pauseAudioEl({ reason: 'unblocking' });
                audioEl.src = '';
                audioEl.removeAttribute('src');
            } catch {
                console.debug('Blocked ' + errorContext);
                setAudioStatus('blocked');
            }
        };

        const setVolume = ({ vol, fadeMs }: { vol: number; fadeMs?: number }) => {
            volumeRef.current = vol;
            towardsTargetVolume({ targetVolume: volumeRef.current, fadeMs });
        };

        return {
            public: {
                errorContext: errorContext,
                actions: {
                    loadSource: loadSource,
                    play: play,
                    pause: pause,
                    setTime: setTime,
                    unblock: unblock,
                    setVolume: setVolume,
                    end: end,
                },
                streamUrl: _streamUrl.current,
            },
            protected: {
                destroy: destroy,
                streamUrlRef: _streamUrl,
                bufferSizeSecs: bufferSizeSecs,
                audioStatusRef: audioStatus,
                errorContext: errorContext,
                isInTheMiddleOfSeeking: isInTheMiddleOfSeeking,
            },
        };
    };

    const playerStateRef = useRef<PlayerInternal | undefined>();
    //lazy init to not call init function all the time
    if (!playerStateRef.current) {
        playerStateRef.current = init();
    }
    const playerPersistentState = playerStateRef.current;

    return {
        public: {
            actions: playerPersistentState.public.actions,
            errorContext: errorContext,
            streamUrl: playerPersistentState.public.streamUrl,
            audioStatus: audioStatus.current,
            playerStatus: playerStatus.current,
            warning: warning.current,
            currentTimeSecs: currentTimeSecs.current,
            volume: volumeRef.current,
            duration: duration.current,
        },
        protected: {
            destroy: playerPersistentState.protected.destroy,
            streamUrlRef: playerPersistentState.protected.streamUrlRef,
            bufferSizeSecs: playerPersistentState.protected.bufferSizeSecs,
            audioStatusRef: playerPersistentState.protected.audioStatusRef,
            errorContext: playerPersistentState.protected.errorContext,
            isInTheMiddleOfSeeking: playerPersistentState.protected.isInTheMiddleOfSeeking,
            audioElRef: audioElRef,
        },
    };
};

export const useHLSAudioPlayer = ({
    outputDevice,
    broadcastIdentifier,
    errorContext,
    mode,
    broadcastState,
    broadcastElapsedTimeSecs,
    voiceOverStages,
    playDemoVO,
}: {
    outputDevice: string | undefined;
    broadcastIdentifier: string;
    errorContext: string;
    mode: 'recording' | 'live';
    broadcastState: BroadcastPersistentState;
    broadcastElapsedTimeSecs: number;
    voiceOverStages: VoiceoverStage[];
    playDemoVO?: boolean;
}) => {
    const testHooks = useContext(TestHooksContext);
    if (!testHooks) throw new Error('Missing TestHooksContext');

    const closestTimelineItem = [...broadcastState.timeline]
        .sort((a, b) => b.dspOffset - a.dspOffset)
        .find((x) => x.dspOffset <= broadcastElapsedTimeSecs * 1000);
    if (!closestTimelineItem) {
        throw new Error('Cant find closest stream to play initially');
    }
    const [generalPlayerStatus, setGeneralPlayerStatus] = useState<'paused' | 'playing'>('paused');
    const [currentDspOffsetMs, setCurrentDspOffsetMs] = useState<number>(closestTimelineItem.dspOffset);

    const [nextSeekToSecs, setNextSeekToSecs] = useState<number | undefined>(undefined);

    const broadcastFileOffset = closestTimelineItem.broadcastOffset ?? closestTimelineItem.dspOffset;

    const initialStreamUrl =
        testHooks.streamUrl !== undefined
            ? testHooks.streamUrl
            : broadcastFileOffset > 0
            ? `${configs.freud.STREAM_BASE}/${broadcastIdentifier}/offset_${broadcastFileOffset}/stream.m3u8`
            : `${configs.freud.STREAM_BASE}/${broadcastIdentifier}/stream.m3u8`;

    const [isVolumeControllable, setIsVolumeControllable] = useState<boolean>(false);
    useEffect(() => {
        audio.isVolumeControllable().then((_isVolumeControllable) => {
            if (testHooks?.debug) {
                console.debug(errorContext, { _isVolumeControllable });
            }
            setIsVolumeControllable(_isVolumeControllable && !testHooks?.noVolume);
        });
    }, []);

    const deckAPlayer = useRawAudioPlayer({
        broadcastIdentifier,
        errorContext: errorContext + ' DeckA',
        mode,
        forceHlsJsLib: testHooks.forceHls,
        forceNativeAudio: testHooks.forceNativeAudio,
        initialStreamUrl,
        isVolumeControllable,
        isHlsFormat: true,
    });

    const deckBPlayer = useRawAudioPlayer({
        broadcastIdentifier,
        errorContext: errorContext + ' DeckB',
        mode,
        forceHlsJsLib: testHooks.forceHls,
        forceNativeAudio: testHooks.forceNativeAudio,
        initialStreamUrl: configs.freud.BUFFER_STALL_FALLBACK_MUSIC,
        isVolumeControllable,
        isHlsFormat: true,
    });
    const didStartedPlayback = useRef<boolean>(false);
    useEffect(() => {
        (async () => {
            console.debug(
                'New dspOffset',
                closestTimelineItem.dspOffset,
                didStartedPlayback.current,
                deckAPlayer.protected.streamUrlRef.current,
                initialStreamUrl,
            );
            setCurrentDspOffsetMs(closestTimelineItem.dspOffset);
            if (deckAPlayer.protected.streamUrlRef.current !== initialStreamUrl && !didStartedPlayback.current) {
                console.debug('Catching up with updated stream before playback starts for first time');
                deckAPlayer.protected.streamUrlRef.current = initialStreamUrl;
                //THIS breaks loading?
                // await deckAPlayer.public.actions.loadSource();
            }
        })();
    }, [closestTimelineItem.dspOffset]);

    const mainDeckNow = useRef<'deckA' | 'deckB'>('deckA');

    let mainPlayer = mainDeckNow.current === 'deckA' ? deckAPlayer : deckBPlayer;
    let futurePlayer = mainDeckNow.current === 'deckA' ? deckBPlayer : deckAPlayer;

    const BROADCAST_STREAMS_FADE_TIME_SECS = 30;

    const nextTimelineItemToPlay = useRef<
        | {
              sessionId: string;
              dspOffset: number;
          }
        | undefined
    >(undefined);

    //Guarding against strange offset issues/timing issues with HLS live position etc...
    const playbackMisAlignmentsInRowRef = useRef<number>(0);
    useEffect(() => {
        const totalDuration = mainPlayer.protected.audioElRef.current?.duration;

        if (
            broadcastElapsedTimeSecs <= 0 ||
            !didStartedPlayback.current ||
            mainPlayer.protected.isInTheMiddleOfSeeking ||
            totalDuration === undefined ||
            mode === 'live'
        )
            return;

        const offsetSecondsEffective = Math.min(broadcastElapsedTimeSecs - currentDspOffsetMs / 1000, totalDuration);

        const whatPlayerHas = mainPlayer.protected.audioElRef.current?.currentTime ?? 0;

        const deltaBetweenWhatShouldBeAndPlayerHas = Math.abs(whatPlayerHas - offsetSecondsEffective);
        const ACCEPTABLE_DELTA_SECS = 60;
        if (deltaBetweenWhatShouldBeAndPlayerHas > ACCEPTABLE_DELTA_SECS) {
            playbackMisAlignmentsInRowRef.current += 1;
        } else {
            playbackMisAlignmentsInRowRef.current = 0;
        }
        const PLAYBACK_DISPARITY_THRESHOLD_TIMES = 3;

        if (
            deltaBetweenWhatShouldBeAndPlayerHas > ACCEPTABLE_DELTA_SECS &&
            playbackMisAlignmentsInRowRef.current >= PLAYBACK_DISPARITY_THRESHOLD_TIMES
        ) {
            playbackMisAlignmentsInRowRef.current = 0;
            console.debug('Detected player offset disparity with what it should be', {
                deltaBetweenWhatShouldBeAndPlayerHas,
                broadcastElapsedTimeSecs,
                whatPlayerHas,
                offsetSecondsEffective,
                closestTimelineItem,
                playbackMisAlignmentsInRow: playbackMisAlignmentsInRowRef.current,
                totalDuration,
            });
            Sentry.captureMessage('Detected player offset disparity with what it should be');
            mainPlayer.public.actions.setTime(offsetSecondsEffective);
        }
    }, [broadcastElapsedTimeSecs]);

    useEffect(() => {
        (async () => {
            if (!didStartedPlayback.current) return;
            let nextTimelineItemToLoad:
                | {
                      sessionId: string;
                      dspOffset: number;
                  }
                | undefined;
            if (nextSeekToSecs === undefined) {
                const nextMinimalOffsetToFadeMs = broadcastElapsedTimeSecs * 1000; // fade in buffer size
                const nextMaximalOffsetToFadeMs = (broadcastElapsedTimeSecs + BROADCAST_STREAMS_FADE_TIME_SECS) * 1000; // fade in buffer size
                //TODO: how to fade when seeking in precomposed for recordings?
                nextTimelineItemToLoad = broadcastState.timeline.find(
                    (x) =>
                        nextTimelineItemToPlay.current === undefined &&
                        x.dspOffset > nextMinimalOffsetToFadeMs &&
                        x.dspOffset <= nextMaximalOffsetToFadeMs,
                );
            } else {
                nextTimelineItemToLoad = broadcastState.timeline.find((x) => x.dspOffset === currentDspOffsetMs);
                if (nextTimelineItemToLoad === undefined) throw new Error('missing nextTimelineItemToLoad');
            }
            // console.debug('Transition candidate', { nextTimelineItem, broadcastState });
            if (nextTimelineItemToLoad !== undefined) {
                console.debug('Transitioning to', nextTimelineItemToLoad);
                nextTimelineItemToPlay.current = nextTimelineItemToLoad;

                // const setFutureStreamUrl = deckPlaying.current == 'deckA' ? setDeckBStreamUrl : setDeckAStreamUrl;
                const futureStreamUrl =
                    testHooks.streamUrlDeckB !== undefined
                        ? testHooks.streamUrlDeckB
                        : `${configs.freud.STREAM_BASE}/${broadcastIdentifier}/${
                              nextTimelineItemToLoad.dspOffset !== 0
                                  ? `offset_${nextTimelineItemToLoad.dspOffset}/`
                                  : ''
                          }stream.m3u8`;
                // setFutureStreamUrl(futureStreamUrl);
                futurePlayer.protected.destroy({ immediate: true });
                futurePlayer.protected.streamUrlRef.current = futureStreamUrl;

                await futurePlayer.public.actions.loadSource();
            }

            if (
                nextTimelineItemToPlay.current !== undefined &&
                (nextTimelineItemToPlay.current.dspOffset <= broadcastElapsedTimeSecs * 1000 ||
                    nextSeekToSecs !== undefined)
            ) {
                console.debug('Playing transition', nextTimelineItemToPlay.current);

                if (generalPlayerStatus === 'playing') {
                    if (nextSeekToSecs !== undefined) {
                        futurePlayer.public.actions.setTime(nextSeekToSecs);
                        mainPlayer.public.actions.pause({ fadeMs: 1000, reason: 'seek' });
                    } else {
                        futurePlayer.public.actions.play({ fadeMs: BROADCAST_STREAMS_FADE_TIME_SECS * 1000 });
                        mainPlayer.public.actions.pause({
                            fadeMs: BROADCAST_STREAMS_FADE_TIME_SECS * 1000,
                            reason: 'transition',
                        });
                    }
                }

                const _tmpPlayer = mainPlayer;
                mainPlayer = futurePlayer;
                futurePlayer = _tmpPlayer;
                mainDeckNow.current = mainDeckNow.current == 'deckA' ? 'deckB' : 'deckA';
                setCurrentDspOffsetMs(nextTimelineItemToPlay.current.dspOffset);

                nextTimelineItemToPlay.current = undefined;
                setNextSeekToSecs(undefined);
            }
        })();
    }, [broadcastElapsedTimeSecs, nextSeekToSecs]);

    const preludePostludeAudioPlayer = useRawAudioPlayer({
        broadcastIdentifier: 'PRELUDE',
        initialStreamUrl:
            testHooks.streamUrlPrelude !== undefined
                ? testHooks.streamUrlPrelude
                : configs.freud.PRELUDE_POSTLUDE_MUSIC,
        errorContext: 'Prelude Postlude',
        mode: 'recording',
        forceNativeAudio: true,
        bufferProtection: false,
        isVolumeControllable: true,
        isHlsFormat: false,
    });

    const customSoundsChannel2 = useRawAudioPlayer({
        broadcastIdentifier: 'CUSTOM2',
        initialStreamUrl: configs.freud.FREE_ACCOUNT_VO,
        errorContext: 'Custom2',
        mode: 'recording',
        forceNativeAudio: true,
        bufferProtection: false,
        isVolumeControllable: true,
        isHlsFormat: false,
    });

    useEffect(() => {
        const DEMO_VO_INTERVAL_MINS = 20;

        if (playDemoVO === true && broadcastElapsedTimeSecs % (60 * DEMO_VO_INTERVAL_MINS) === 5) {
            customSoundsChannel2.public.actions.setVolume({ vol: 1.0 });
            customSoundsChannel2.public.actions.play({ offsetSeconds: 0 });
        }
    }, [broadcastElapsedTimeSecs]);

    const customSoundsChannel1 = useRawAudioPlayer({
        broadcastIdentifier: 'CUSTOM1',
        initialStreamUrl: configs.freud.BUFFER_STALL_FALLBACK_MUSIC,
        errorContext: 'Custom1',
        mode: 'recording',
        forceNativeAudio: true,
        bufferProtection: false,
        isVolumeControllable: true,
        isHlsFormat: false,
    });
    const VOICEOVER_FADE_TIME_SECS = 2;
    const voiceOverStagesSortedDesc1 = [...voiceOverStages].sort((a, b) => b.timing.from - a.timing.from);
    const voiceOverStagesSortedAsc1 = [...voiceOverStages].sort((a, b) => a.timing.from - b.timing.from);
    const voiceOverStageToPlay1 = voiceOverStagesSortedDesc1.find(
        (x) =>
            x.timing.from < broadcastElapsedTimeSecs &&
            x.timing.to > broadcastElapsedTimeSecs + VOICEOVER_FADE_TIME_SECS,
    );
    const voiceOverStageToLoadAhead1 = voiceOverStagesSortedAsc1.find(
        (x) => voiceOverStageToPlay1 === undefined && x.timing.from > broadcastElapsedTimeSecs,
    );

    const refreshVOGainAdjustedPlayerVolumes = (_generalVolume: number) => {
        const gainOfMusicWhileVOPlaying = voiceOverStageToPlay1?.musicGain ?? 1.0;
        deckAPlayer.public.actions.setVolume({ vol: gainOfMusicWhileVOPlaying * _generalVolume });
        deckBPlayer.public.actions.setVolume({ vol: gainOfMusicWhileVOPlaying * _generalVolume });
        customSoundsChannel1.public.actions.setVolume({ vol: (voiceOverStageToPlay1?.volume ?? 1.0) * _generalVolume });
    };

    useEffect(() => {
        console.debug('Voiceovers: ', voiceOverStageToPlay1, voiceOverStagesSortedDesc1);
    }, [voiceOverStagesSortedDesc1.length, voiceOverStageToPlay1?.timing.from]);

    useEffect(() => {
        if (voiceOverStageToLoadAhead1 !== undefined) {
            (async () => {
                const voiceOverStream1 = `${configs.freud.CUSTOM_VOICEOVERS_PREVIEW_BASE_URL}${voiceOverStageToLoadAhead1.fileNameWithoutExtension}.mp3`;
                if (voiceOverStream1 !== customSoundsChannel1.protected.streamUrlRef.current) {
                    customSoundsChannel1.protected.destroy({ immediate: true });
                    customSoundsChannel1.protected.streamUrlRef.current = voiceOverStream1;

                    await customSoundsChannel1.public.actions.loadSource();
                }
            })();
        }
    }, [voiceOverStageToLoadAhead1?.timing.from]);

    const playCustom1 = async () => {
        if (voiceOverStageToPlay1 !== undefined) {
            const voiceOverStream1 = `${configs.freud.CUSTOM_VOICEOVERS_PREVIEW_BASE_URL}${voiceOverStageToPlay1.fileNameWithoutExtension}.mp3`;
            if (voiceOverStream1 !== customSoundsChannel1.protected.streamUrlRef.current) {
                customSoundsChannel1.protected.destroy({ immediate: true });
                customSoundsChannel1.protected.streamUrlRef.current = voiceOverStream1;

                await customSoundsChannel1.public.actions.loadSource();
            }
            const offsetSeconds = broadcastElapsedTimeSecs - voiceOverStageToPlay1.timing.from;
            refreshVOGainAdjustedPlayerVolumes(generalVolume);
            customSoundsChannel1.public.actions.play({ offsetSeconds: offsetSeconds, fadeMs: 0 });
        }
    };

    useEffect(() => {
        console.debug('Voiceover stage to play', voiceOverStageToPlay1, generalPlayerStatus);
        if (voiceOverStageToPlay1 !== undefined && generalPlayerStatus === 'playing') {
            playCustom1();
        } else {
            refreshVOGainAdjustedPlayerVolumes(generalVolume);
            customSoundsChannel1.public.actions.pause({ fadeMs: 900, reason: 'customSoundEnd' });
        }
    }, [voiceOverStagesSortedDesc1.length, voiceOverStageToPlay1?.timing.from, generalPlayerStatus]);

    useEffect(() => {
        if (voiceOverStageToPlay1) {
            refreshVOGainAdjustedPlayerVolumes(generalVolume);
        }
    }, [voiceOverStageToPlay1?.musicGain, voiceOverStageToPlay1?.volume]);

    const [generalVolume, setGeneralVolume] = useState(1);

    const { refreshDevices } = useAudioDevices();

    useEffect(() => {
        const setCurrentOutputDevice = (deviceId: any) => {
            console.debug('Setting output device to ', deviceId);
            //Chrome presents default as 'default' but setSinkId only accepts '' empty string
            const finalDeviceId = deviceId == 'default' ? '' : deviceId;
            setSinkId(finalDeviceId);
        };
        (async () => {
            const outputDevices = await refreshDevices();
            if (!outputDevices.some((x) => x.deviceId == outputDevice)) {
                outputDevices.map((x) => {
                    if (x.deviceId == '') {
                        //common standard is that default device has an empty string
                        setCurrentOutputDevice(x.deviceId);
                    } else if (x.deviceId == 'default') {
                        //macos default deviceId is 'default'
                        setCurrentOutputDevice(x.deviceId);
                    }
                });
            } else {
                setCurrentOutputDevice(outputDevice);
            }
        })();
    }, [refreshDevices, outputDevice]);

    useEffect(() => {
        (async () => {
            if (navigator.mediaDevices) {
                navigator.mediaDevices.addEventListener('devicechange', refreshDevices);
                console.debug('Listening to device change');
            }
        })();
        return () => {
            console.debug('destroying players');
            if (navigator.mediaDevices) {
                navigator.mediaDevices.removeEventListener('devicechange', refreshDevices);
                console.debug('Removing handler for device change');
            }
            deckAPlayer.protected.destroy();
            deckBPlayer.protected.destroy();
            preludePostludeAudioPlayer.protected.destroy();
            customSoundsChannel1.protected.destroy();
            customSoundsChannel2.protected.destroy();
        };
    }, [refreshDevices]);

    const currentTimeSecs = currentDspOffsetMs / 1000 + (nextSeekToSecs ?? mainPlayer.public.currentTimeSecs);

    const setTime = (timeSecs: number) => {
        didStartedPlayback.current = true;
        setGeneralPlayerStatus('playing');
        refreshVOGainAdjustedPlayerVolumes(generalVolume);
        preludePostludeAudioPlayer.public.actions.pause({ fadeMs: 1000, reason: 'seek' });
        const newDspOffset = [...broadcastState.timeline]
            .sort((a, b) => b.dspOffset - a.dspOffset)
            .find((x) => x.dspOffset <= timeSecs * 1000);
        if (newDspOffset === undefined) throw new Error('Cant find newDspOffset');
        const timeRelativeInSessionMs = timeSecs * 1000 - newDspOffset.dspOffset;
        if (timeRelativeInSessionMs < 0) throw new Error('timeRelativeInSession less than 0');
        console.debug('Seeking to', {
            timeSecs,
            dspOffset: newDspOffset.dspOffset,
            timeRelativeInSessionMs: timeRelativeInSessionMs,
        });
        if (newDspOffset.dspOffset === currentDspOffsetMs) {
            mainPlayer.public.actions.setTime(timeRelativeInSessionMs / 1000);
        } else {
            setCurrentDspOffsetMs(newDspOffset.dspOffset);
            setNextSeekToSecs(timeRelativeInSessionMs / 1000);
        }
    };

    const unblock = async () => {
        const oldAudioStatus = preludePostludeAudioPlayer.protected.audioStatusRef.current;
        await Promise.all([
            preludePostludeAudioPlayer.public.actions.unblock(),
            deckAPlayer.public.actions.unblock(),
            deckBPlayer.public.actions.unblock(),
            customSoundsChannel1.public.actions.unblock(),
            customSoundsChannel2.public.actions.unblock(),
        ]);

        if (oldAudioStatus !== 'active' && preludePostludeAudioPlayer.protected.audioStatusRef.current === 'active') {
            console.debug('oldAudioStatus was', oldAudioStatus);
            console.debug('Loading sources...');
            await preludePostludeAudioPlayer.public.actions.loadSource();

            console.debug('Loading DeckA source...');
            await deckAPlayer.public.actions.loadSource();

            if (playDemoVO) {
                await customSoundsChannel2.public.actions.loadSource();
            }

            await customSoundsChannel1?.public?.actions?.loadSource();
        }
    };

    const play = (props?: { offsetSeconds?: number }) => {
        console.debug('Playing all players');
        didStartedPlayback.current = true;

        refreshVOGainAdjustedPlayerVolumes(generalVolume);
        if (preludePostludeAudioPlayer.protected.audioStatusRef.current === 'active') {
            console.log('Stopping playing prelude postlude');
            const offsetSecondsEffective =
                (props?.offsetSeconds ?? broadcastElapsedTimeSecs) - currentDspOffsetMs / 1000;

            mainPlayer.public.actions.play({ offsetSeconds: offsetSecondsEffective });
            preludePostludeAudioPlayer.public.actions.pause({ fadeMs: 20 * 1000, reason: 'playMain' });
            playCustom1();
        }

        setGeneralPlayerStatus('playing');
    };

    const playPreludePostlude = async () => {
        console.debug('Playing prelude postlude');
        if (preludePostludeAudioPlayer.protected.audioStatusRef.current === 'active') {
            preludePostludeAudioPlayer.public.actions.setVolume({ vol: generalVolume });
            const loop = true;
            preludePostludeAudioPlayer.public.actions.play({ loop });
        }
        setGeneralPlayerStatus('paused');
    };

    const pause = (...props: Parameters<typeof mainPlayer.public.actions.pause>) => {
        console.debug('Pausing all players');
        mainPlayer.public.actions.pause(...props);
        preludePostludeAudioPlayer.public.actions.pause({ fadeMs: 0, reason: 'generalPause' });
        setGeneralPlayerStatus('paused');
    };

    const setVolume = (vol: number) => {
        setGeneralVolume(vol);
        refreshVOGainAdjustedPlayerVolumes(vol);
        preludePostludeAudioPlayer.public.actions.setVolume({ vol });
    };

    const exposedObject = {
        ...mainPlayer.public,
        currentBufferSizeSecs: mainPlayer.protected.bufferSizeSecs.current,
        currentTimeSecs: currentTimeSecs,
        isVolumeControllable,
        volume: generalVolume,
        generalPlayerStatus,
        actions: {
            ...mainPlayer.public.actions,
            setTime,
            unblock,
            play: play,
            playPrelude: playPreludePostlude,
            playPostlude: playPreludePostlude,
            pause: pause,
            setVolume,
        },
    };
    return {
        ...exposedObject,
        broadcastState,
        broadcastElapsedTimeSecs,
    };
};
