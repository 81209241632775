import { range, sortBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useInterval, useLifecycles } from 'react-use';
import { BroadcastPersistentState, VoiceoverStage } from 'wavepaths-shared/core';

import configs from '../../configs';
import { AudioPlayer, AudioPlayerProps } from './AudioPlayerProvider';
import { PlayerLibInterface, PlayerLibStream, PlayerLibStreamPhase } from './PlayerLibInterface';

const STREAM_FADE_OUT_TIME = 15000;
const LONG_TIME_FROM_NOW = 365 * 24 * 60 * 60 * 1000;

export function usePlayerLibAudioPlayer({
    broadcastIdentifier,
    broadcastState,
    broadcastElapsedTimeSecs,
    sessionDuration,
    voiceOverStages,
    outputDevice,
    playDemoVO,
}: AudioPlayerProps): AudioPlayer {
    const playerRef = useRef<Promise<PlayerLibInterface | undefined>>(Promise.resolve(undefined));
    const [initialised, setInitialised] = useState(false);
    const [playbackStatus, setPlaybackStatus] = useState<'paused' | 'playing'>('paused');
    useLifecycles(
        () => {
            const player = new PlayerLibInterface();
            playerRef.current = player.init().then(() => {
                setInitialised(true);
                return player;
            });
        },
        () => {
            if (playerRef.current) {
                playerRef.current.then((p) => p?.destroy());
                playerRef.current = Promise.resolve(undefined);
            }
        },
    );

    const [volume, _setVolume] = useState(1);
    const [currentTimeSecs, setCurrentTimeSecs] = useState(0);
    const [currentBufferSizeSecs, setCurrentBufferSizeSecs] = useState(0);

    useEffect(() => {
        const streams = makePlayerLibStreams(
            broadcastIdentifier,
            broadcastState,
            voiceOverStages,
            sessionDuration,
            !!playDemoVO,
        );
        console.log('combinedStreams', streams);
        playerRef.current.then((p) => p?.setSession(streams));
    }, [broadcastIdentifier, broadcastState.timeline, voiceOverStages, playDemoVO, sessionDuration]);

    useEffect(() => {
        playerRef.current.then((p) => p?.setBroadcastElapsedTimeSecs(broadcastElapsedTimeSecs));
    }, [broadcastElapsedTimeSecs]);

    useEffect(() => {
        playerRef.current.then(async (p) => {
            if (!p) return;
            const audioCtx = await p.audioCtx();
            const audioCtxUntyped = audioCtx as any;
            // Chrome presents default as 'default' but setSinkId only accepts '' empty string
            const deviceId = outputDevice == 'default' ? '' : outputDevice;
            if (deviceId && 'setSinkId' in audioCtxUntyped) {
                audioCtxUntyped.setSinkId(deviceId);
            }
        });
    }, [outputDevice]);

    useInterval(async () => {
        const p = await playerRef.current;
        const timeInPhase = await p?.getTimeInPhase();
        const bufferedTime = await p?.getBufferedTime();
        setCurrentTimeSecs(timeInPhase ?? 0);
        setCurrentBufferSizeSecs(bufferedTime ?? 0);
    }, 1000);

    const play = async () => {
        if (!initialised) return;
        const p = await playerRef.current;
        const phase = await p?.getPhase();
        const isStarted = await p?.isStarted();
        if (phase !== PlayerLibStreamPhase.Session || !isStarted) {
            console.log('setting main phase');
            await p?.setPhase(PlayerLibStreamPhase.Session);
            await p?.start();
            setPlaybackStatus('playing');
        }
    };
    const playPrelude = async () => {
        if (!initialised) return;
        const p = await playerRef.current;
        const phase = await p?.getPhase();
        const isStarted = await p?.isStarted();
        if (phase !== PlayerLibStreamPhase.Pre || !isStarted) {
            await p?.setPhase(PlayerLibStreamPhase.Pre);
            await p?.start();
            setPlaybackStatus('playing');
        }
    };
    const playPostlude = async () => {
        if (!initialised) return;
        const p = await playerRef.current;
        const phase = await p?.getPhase();
        const isStarted = await p?.isStarted();
        if (phase !== PlayerLibStreamPhase.Post || !isStarted) {
            await p?.setPhase(PlayerLibStreamPhase.Post);
            await p?.start();
            setPlaybackStatus('playing');
        }
    };
    const pause = async () => {
        if (!initialised) return;
        const p = await playerRef.current;
        await p?.stop();
        setPlaybackStatus('paused');
    };
    const setTime = async (timeWithinPhase: number) => {
        const p = await playerRef.current;
        await p?.seekToTimeInPhase(timeWithinPhase);
    };

    const unblock = async () => {
        const p = await playerRef.current;
        const ctx = await p?.audioCtx();
        ctx?.resume();
    };

    const setVolume = async (volume: number) => {
        _setVolume(volume);
        const p = await playerRef.current;
        await p?.setVolume(volume);
    };
    const end = async () => {
        const p = await playerRef.current;
        await p?.stop();
    };

    return {
        type: 'playerlib',
        actions: { play, playPrelude, playPostlude, pause, setTime, unblock, setVolume, end },
        audioStatus: initialised ? 'active' : 'init',
        playerStatus: initialised ? playbackStatus : 'loading',
        generalPlayerStatus: playbackStatus,
        warning: null,
        currentTimeSecs,
        currentBufferSizeSecs,
        volume,
        isVolumeControllable: true,
    };
}

function makePlayerLibStreams(
    broadcastIdentifier: string,
    broadcastState: BroadcastPersistentState,
    voiceoverStages: VoiceoverStage[],
    sessionDurationMs: number,
    playDemoVO: boolean,
) {
    const result: PlayerLibStream[] = [];
    result.push({
        id: 'prelude',
        phase: PlayerLibStreamPhase.Pre,
        url: configs.freud.PRELUDE_POSTLUDE_MUSIC,
        fromTime: 0,
        toTime: LONG_TIME_FROM_NOW,
        fadeOutTime: STREAM_FADE_OUT_TIME,
        loopContent: true,
        gain: 1.0,
        usesSidechain: false,
        sidechainGain: 0.0,
    });
    result.push({
        id: 'postlude',
        phase: PlayerLibStreamPhase.Post,
        url: configs.freud.PRELUDE_POSTLUDE_MUSIC,
        fromTime: 0,
        toTime: LONG_TIME_FROM_NOW,
        fadeOutTime: STREAM_FADE_OUT_TIME,
        loopContent: true,
        gain: 1.0,
        usesSidechain: false,
        sidechainGain: 0.0,
    });
    const streamsInOrder = sortBy(broadcastState.timeline, (b) => b.dspOffset);
    result.push(
        ...sortBy(broadcastState.timeline, (b) => b.dspOffset).map((s, i) => ({
            id: s.sessionId,
            phase: PlayerLibStreamPhase.Session,
            url: `${configs.freud.STREAM_BASE}/${broadcastIdentifier}/${s.sessionId}/stream.m3u8`,
            fromTime: s.dspOffset,
            toTime:
                i === streamsInOrder.length - 1
                    ? LONG_TIME_FROM_NOW
                    : streamsInOrder[i + 1].dspOffset + STREAM_FADE_OUT_TIME,
            fadeOutTime: STREAM_FADE_OUT_TIME,
            loopContent: false,
            gain: 1.0,
            usesSidechain: false,
            sidechainGain: 0.0,
        })),
    );
    for (const voiceover of voiceoverStages) {
        result.push({
            id: `vo-${voiceover.timing.from}-${voiceover.timing.to}-${voiceover.fileNameWithoutExtension}`,
            phase: PlayerLibStreamPhase.Session,
            url: `${configs.freud.CUSTOM_VOICEOVERS_PREVIEW_BASE_URL}${voiceover.fileNameWithoutExtension}.mp3`,
            fromTime: voiceover.timing.from * 1000,
            toTime: voiceover.timing.to * 1000,
            fadeOutTime: 0,
            loopContent: false,
            gain: voiceover.volume ?? 1.0,
            usesSidechain: true,
            sidechainGain: 1.0 - (voiceover.musicGain ?? 1.0),
        });
    }
    if (playDemoVO) {
        const DEMO_VO_INTERVAL_MINS = 20;
        for (const time of range(5000, sessionDurationMs, DEMO_VO_INTERVAL_MINS * 60 * 1000)) {
            result.push({
                id: `freeVo-${time}`,
                phase: PlayerLibStreamPhase.Session,
                url: configs.freud.FREE_ACCOUNT_VO,
                fromTime: time,
                toTime: time + 5 * 60 * 1000, // this assumes the free VO is no longer than 5 minutes long
                fadeOutTime: 0,
                loopContent: false,
                gain: 1.0,
                usesSidechain: true,
                sidechainGain: 0.8,
            });
        }
    }
    return result;
}
