import styled from '@emotion/styled';
import VolumeDownIcon from '@material-ui/icons/VolumeDownRounded';
import VolumeUpIcon from '@material-ui/icons/VolumeUpRounded';
import React, { useContext, useLayoutEffect, useState } from 'react';

import RangeInput from '@/components/Form/RangeInput';

import { AudioPlayerContext } from './AudioPlayerProvider';
import { PlayerChoiceContext } from './deviceOptions/PlayerChoiceContext';

const Container = styled.div`
    width: 160px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 8px;
`;

export const VolumeMeter: React.FC = React.memo(() => {
    const audioPlayer = useContext(AudioPlayerContext);
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const [visible, setVisible] = useState<boolean>(false);
    useLayoutEffect(() => {
        if (
            audioPlayer !== undefined &&
            audioPlayer.isVolumeControllable &&
            playerChoiceContext.playerChoice === 'browser'
        ) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [audioPlayer?.isVolumeControllable, playerChoiceContext.playerChoice]);

    return (
        <>
            {visible && audioPlayer !== undefined ? (
                <Container>
                    <VolumeDownIcon style={{ fill: '#2B2D3F' }} />
                    <RangeInput
                        min={0}
                        max={1}
                        step={0.01}
                        value={audioPlayer.volume}
                        onChange={(e) => audioPlayer.actions.setVolume(+e.target.value)}
                    />
                    <VolumeUpIcon style={{ fill: '#2B2D3F' }} />
                </Container>
            ) : (
                <></>
            )}
        </>
    );
});
