import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { VoiceoverStage } from 'wavepaths-shared/core';

import { useSessionRemoteTick } from '@/hooks';

import { CustomVoiceoverForm, VoiceOverStagesContext } from '../../templateInfo';

const Container = styled.div({});

export const Voiceovers = ({
    isLoading,
    isPreludeOrPostlude,
    elapsedTimeSecs,
    voiceoverStages,
    onUpsertVoiceOverStage,
}: {
    isLoading: boolean;
    isPreludeOrPostlude: boolean;
    elapsedTimeSecs: number;
    voiceoverStages: VoiceoverStage[];
    onUpsertVoiceOverStage?: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
}) => {
    const voiceOverStagesContext = useContext(VoiceOverStagesContext);
    if (!voiceOverStagesContext) {
        throw new Error('No VoiceOverStagesContext');
    }
    const tick = useSessionRemoteTick();
    const voiceOverStagesSortedDesc1 = [...voiceoverStages].sort((a, b) => b.timing.from - a.timing.from);
    const voiceOverStageToPlay1 = voiceOverStagesSortedDesc1.find(
        (x) => x.timing.from <= elapsedTimeSecs && x.timing.to > elapsedTimeSecs,
    );
    const sessionDurationSecs = Math.floor((tick?.sessionDuration ?? 0) / 1000);
    return (
        <Container>
            {!isLoading && !isPreludeOrPostlude ? (
                voiceOverStageToPlay1 !== undefined ? (
                    <CustomVoiceoverForm
                        key={voiceOverStageToPlay1.stage}
                        customVoiceOvers={voiceOverStagesContext.customVoiceOvers}
                        sessionDuration={sessionDurationSecs}
                        onEdit={onUpsertVoiceOverStage}
                        initialValue={voiceOverStageToPlay1}
                        index={voiceoverStages.findIndex((x) => x.stage == voiceOverStageToPlay1.stage)}
                        voiceOverStages={voiceoverStages}
                        elapsedTimeSecs={elapsedTimeSecs}
                        forceTimingTo={'now'}
                    ></CustomVoiceoverForm>
                ) : (
                    <>
                        {elapsedTimeSecs > 0 && elapsedTimeSecs < sessionDurationSecs && (
                            <CustomVoiceoverForm
                                key={'notPlaying'}
                                customVoiceOvers={voiceOverStagesContext.customVoiceOvers}
                                sessionDuration={sessionDurationSecs}
                                onAdd={onUpsertVoiceOverStage}
                                voiceOverStages={voiceoverStages}
                                elapsedTimeSecs={elapsedTimeSecs}
                                forceTimingTo={'now'}
                            ></CustomVoiceoverForm>
                        )}
                    </>
                )
            ) : (
                <></>
            )}
        </Container>
    );
};
