import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Features } from '@/features';
import useMySubscription from '@/hooks/useMySubscription';

import { useAuthContext } from '../../../auth';
import IconSignOut from '../../../images/icons/icon-sign-out.svg';
import IconStar from '../../../images/icons/icon-upgrade-star.svg';
import IconUser from '../../../images/icons/icon-user.svg';
import WavepathsLogo from '../../../images/wavepaths-logo-dark.svg';
import DropdownMenu from '../DropdownMenu';

const Container = styled.header`
    width: 100%;
    display: flex;
    gap: var(--global-spacing);
`;

const LeftColumn = styled.div`
    display: flex;
    width: 100%;
    max-width: 230px;
`;

const RightColumn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Logo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    img {
        display: block;

        width: 100px;

        @media (min-width: 490px) {
            width: 120px;
        }
    }

    padding: 0;

    @media (min-width: 490px) {
        padding: 0 12px;
    }

    @media (min-width: 1040px) {
        padding: 0 24px;
    }
`;

const Actions = styled.div`
    width: 100%;
    max-width: 1024px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
`;

const UpgradeButton = styled.button`
    height: 44px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 0 24px;
    background: var(--color-highlight);
    border: none;
    border-radius: 8px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    color: var(--color-primary);
    cursor: pointer;
`;

const Label = styled.span`
    display: inline-block;
`;

const Icon = styled.span`
    display: block;
    width: 16px;
    height: 16px;
    img {
        display: block;
        width: 100%;
    }
`;

const UserMenu = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 8px;
`;

const UserInfo = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
`;

const UserName = styled.button`
    display: inline-flex;
    background: none;
    border: none;
    padding: 0;
    font-size: var(--font-size-small);
    text-align: right;
`;

const UserSubscriptionTier = styled.span`
    text-align: right;
    font-size: var(--font-size-small);
    color: var(--color-muted);
`;

export interface DashboardHeaderProps {
    userName?: string;
    onSignOutClick: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ userName, onSignOutClick }) => {
    const history = useHistory();
    const { isEnabled, firebaseUser } = useAuthContext();
    const { subscription, isLoading, activeOfferingTitle } = useMySubscription({ fbUser: firebaseUser });

    const getSubscriptionTierText = () => {
        if (isLoading) return 'Loading...';
        if (!subscription) return 'Free';
        if (subscription.status === 'inactive') return 'Inactive';
        if (activeOfferingTitle) return activeOfferingTitle;
        return subscription.tier;
    };
    return (
        <Container role="banner">
            <LeftColumn>
                <Logo>
                    <img src={WavepathsLogo} alt="Wavepaths" />
                </Logo>
            </LeftColumn>
            <RightColumn>
                <Actions>
                    {!isEnabled(Features.FREE_ACCESS) && (
                        <UpgradeButton onClick={() => history.push('/subscriptions')}>
                            <Label>Upgrade</Label>
                            <Icon>
                                <img src={IconStar} alt="Upgrade" />
                            </Icon>
                        </UpgradeButton>
                    )}
                    <UserMenu>
                        <UserInfo>
                            {userName && <UserName>{userName}</UserName>}
                            <UserSubscriptionTier>Wavepaths {getSubscriptionTierText()}</UserSubscriptionTier>
                        </UserInfo>
                        <DropdownMenu
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            options={[
                                {
                                    icon: <img src={IconUser} alt="Your account" />,
                                    element: 'Your account',
                                    onClick: () => history.push('/profile'),
                                },
                                {
                                    icon: <img src={IconSignOut} alt="Sign out" />,
                                    element: 'Sign out',
                                    onClick: () => onSignOutClick(),
                                },
                            ]}
                        />
                    </UserMenu>
                </Actions>
            </RightColumn>
        </Container>
    );
};

export default React.memo(DashboardHeader);
