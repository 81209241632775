import styled from '@emotion/styled';
import { noop, range } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

import DashboardPageGuidance from '@/components/Dashboard/DashboardPageGuidance';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';
import TemplateCard from '@/components/Templates/TemplateCard';
import TemplateCardGrid from '@/components/Templates/TemplateCardGrid';
import { TemplateCardLoader } from '@/components/Templates/TemplateCardLoader';
import useSavedTemplates from '@/hooks/useSavedTemplates';

import { useAuthContext } from '../../../auth';
import { ListScoreTemplatesItem } from '../../../common/api/contentApi';
import { LayoutContainer } from '../../../LayoutContainer';

const EmptyStateMessage = styled.p`
    font-size: var(--font-size-small);
`;

const EmptyStateButton = styled.button`
    display: inline-block;
    height: 40px;
    padding: 0 2px;
    color: var(--color-primary);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small);
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

type SavedTemplatesProps = {
    templates?: ListScoreTemplatesItem[];
};

const SavedTemplates = ({ templates }: SavedTemplatesProps) => {
    const history = useHistory();

    if (!templates || templates.length === 0) {
        return (
            <EmptyStateMessage>
                You don't currently have any Templates saved.{' '}
                <EmptyStateButton onClick={() => history.push('/templates')}>Explore Templates</EmptyStateButton>
            </EmptyStateMessage>
        );
    }

    return (
        <TemplateCardGrid>
            {templates
                ? templates.map((t) => (
                      <TemplateCard
                          key={'template-' + t.id}
                          onCardClick={() => {
                              history.push({
                                  pathname: '/templates/saved/' + t.id,
                              });
                          }}
                          title={t.name}
                          subtitle={t.subtitle}
                          intensity={t.intensity}
                          minDurationMins={Math.ceil(t.durationMins.min)}
                          maxDurationMins={Math.floor(t.durationMins.max)}
                          emotionalities={t.emotionalities}
                          id={t.id}
                      />
                  ))
                : range(16).map((i) => <TemplateCardLoader key={i} state={{ state: 'loading' }} onCardClick={noop} />)}
        </TemplateCardGrid>
    );
};

function SavedTemplatesContainer() {
    const { firebaseUser } = useAuthContext();

    const { savedTemplates } = useSavedTemplates({ fbUser: firebaseUser });

    return (
        <>
            <DashboardPageHeading pageTitle="Saved" />
            <DashboardPageGuidance
                pageTitle="Saved"
                guidanceText="Saved Templates are Templates that you have saved for later use. You can view and edit them here."
            />
            <SavedTemplates templates={savedTemplates} />
        </>
    );
}

function SavedTemplatesWithNav() {
    return (
        <LayoutContainer>
            <SavedTemplatesContainer />
        </LayoutContainer>
    );
}

export default SavedTemplatesWithNav;
