import styled from '@emotion/styled';
import React from 'react';

const Input = styled.input<{ percentage: number; centerPercentage: number }>(
    ({ percentage, centerPercentage }) => `
position: relative;
appearance: none;
width: 100%;
height: 8px;
display: flex;
align-items: center;
padding: 0;
border-radius: 8px;
border: none;
background: var(--color-muted);
font-size: inherit;
font-weight: inherit;
color: inherit;
z-index: 1;
&::placeholder {
  color: #ffffff;
}
&:focus {
  outline: none;
  border-color: var(--color-primary);
}
&::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(
    to right, 
    ${
        percentage <= centerPercentage
            ? `#ECEBE9, #ECEBE9 ${percentage}%, var(--color-primary) ${percentage}%, var(--color-primary) calc( ${centerPercentage}% - 4px), #ECEBE9 calc( ${centerPercentage}% - 4px), #ECEBE9`
            : `#ECEBE9, #ECEBE9 calc( ${centerPercentage}% - 4px), var(--color-primary) calc( ${centerPercentage}% - 4px), var(--color-primary) ${percentage}%,  #ECEBE9 ${percentage}%, #ECEBE9`
    }
  );
}
&::-webkit-slider-thumb {
  appearance: none;
  width: 24px;
  height: 24px;
  margin-top: -8px;
  border-radius: 50%;
  background: #fff;
  border: 1.5px solid var(--color-muted);
  cursor: pointer;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  z-index: 2;
}
`,
);

interface RangeInputProps {
    min?: number;
    max?: number;
    step?: number;
    value?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RangeInput: React.FC<RangeInputProps> = ({ min = -100, max = 100, step = 1, value = 0, onChange }) => {
    const percentage = ((value - min) / (max - min)) * 100;
    const centerPercentage = ((0 - min) / (max - min)) * 100;
    return (
        <Input
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            percentage={percentage}
            centerPercentage={centerPercentage}
        />
    );
};

export default RangeInput;
