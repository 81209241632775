import './globals.css';

import { createBrowserHistory } from 'history';
import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { UserRoles } from 'wavepaths-shared/core';

import LoadingOrb from '@/components/LoadingOrb';
import { GlobalSnackbarWrapper } from '@/components/Snackbar';
import { Feature, Features, useFeatures } from '@/features';
import { useUserData } from '@/hooks';
import { SessionCacheManagerContext, useSessionCacheManager, useStaticFilesAudioCache } from '@/hooks/useAudioCache';
import { TestHooksWrapper } from '@/hooks/useTestHooks';

import { AuthContext } from './auth';
import { OptionalRoute } from './OptionalRoute';
import ActiveSessions from './pages/activeSessions';
import { ClientDetailPage, ClientsPage } from './pages/clients/ClientsList';
import CompletedSession from './pages/completedSession';
import Home from './pages/home';
import { GuideContainer } from './pages/inSession/GuideContainer';
import { ListenContainer } from './pages/inSession/ListenContainer';
import { PrecomposedGuideContainer } from './pages/inSession/Precomposed/PrecomposedGuideContainer';
import useIsOnline, { OnlineContext } from './pages/inSession/useIsOnline';
import { SessionLog } from './pages/log/SessionLog';
import { Login } from './pages/login/Login';
import News from './pages/news';
import { GuidePlannerContainer } from './pages/planner/GuidePlannerContainer';
import Profile from './pages/profile/Profile';
import TemplatePrograms from './pages/programs';
import ResetPassword from './pages/resetPassword/ResetPassword';
import Resources from './pages/resources';
import { Signup } from './pages/signup/Signup';
import { TherapistInfo } from './pages/signup/TherapistInfo';
import Subscriptions from './pages/subscriptions';
import SupportPosts from './pages/supportPosts';
import TemplateDetail, { CopySessionContainer } from './pages/templateInfo';
import SavedTemplateDetail from './pages/templateInfo/savedTemplateInfo';
import Templates from './pages/templates/all';
import SavedTemplates from './pages/templates/saved';
import { Terms } from './pages/terms/Terms';
import { PaidRoute } from './PaidRoute';
import { PrivateRoute } from './PrivateRoute';
import SwitchUIBanner from './SwitchUIBanner';
import { UnauthedRoute } from './UnauthedRoute';
import { withTracker } from './withTracker';

const TrackedSignup = withTracker(Signup);
const TrackedTherapistInfo = withTracker(TherapistInfo);
const TrackedLogin = withTracker(Login);
const TrackedResetPassword = withTracker(ResetPassword);
const TrackedGuide = withTracker(GuideContainer);
const TrackedListen = withTracker(ListenContainer);
const TrackedGuidePlanner = withTracker(GuidePlannerContainer);
const TrackedEndedSession = withTracker(CompletedSession);
const TrackedCopySession = withTracker(CopySessionContainer);
const TrackedSessionLog = withTracker(SessionLog);
const TrackedHome = withTracker(Home);
const TrackedTemplates = withTracker(Templates);
const TrackedClients = withTracker(ClientsPage);
const TrackedClientDetail = withTracker(ClientDetailPage);
const TrackedActiveSessions = withTracker(ActiveSessions);
const TrackedTemplateDetail = withTracker(TemplateDetail);
const TrackedSavedTemplates = withTracker(SavedTemplates);
const TrackedSavedTemplateDetail = withTracker(SavedTemplateDetail);
const TrackedPrograms = withTracker(TemplatePrograms);
const TrackedResources = withTracker(Resources);
const TrackedTerms = withTracker(Terms);
const TrackedProfile = withTracker(Profile);
const TrackedSubscriptions = withTracker(Subscriptions);
const TrackedPrecomposedGuided = withTracker(PrecomposedGuideContainer);
const TrackedNews = withTracker(News);
const TrackedSupportPosts = withTracker(SupportPosts);

export const App: React.FC = () => {
    const history = useMemo(() => {
        const h = createBrowserHistory();
        return h;
    }, []);

    useEffect(function clearLoadingState() {
        document.getElementById('loadingscreen')?.remove();
    }, []);

    const { userData, firebaseUser, isLoaded } = useUserData();
    const { isEnabled, isLoading: isLoadingFeatures } = useFeatures({
        user: userData,
        userLoaded: isLoaded,
        fbUser: firebaseUser,
    });

    const [showNewUi, setShowNewUI] = useLocalStorage('showNewUi2', true);

    const onChangeUI = (toggleOn: boolean) => {
        setShowNewUI(toggleOn);
        history.push('/');
    };

    const staticCacheResult = useStaticFilesAudioCache();
    useEffect(() => {
        if (staticCacheResult.preludeFileCacheResult) {
            console.debug('Prelude cache status', staticCacheResult.preludeFileCacheResult);
        }
    }, [staticCacheResult.preludeFileCacheResult?.status]);

    const isOnline = useIsOnline();

    const sessionCacheManager = useSessionCacheManager();
    return (
        <>
            {isLoaded && !isLoadingFeatures ? (
                <OnlineContext.Provider value={isOnline}>
                    <AuthContext.Provider
                        value={{
                            userData,
                            firebaseUser,
                            isPersonal: userData?.roles?.includes(UserRoles.PERSONAL) ?? false,
                            isEnabled,
                        }}
                    >
                        <GlobalSnackbarWrapper>
                            <Router history={history}>
                                <QueryParamProvider adapter={ReactRouter5Adapter}>
                                    <TestHooksWrapper>
                                        <SessionCacheManagerContext.Provider value={sessionCacheManager}>
                                            <ProtectedPages
                                                firebaseUser={firebaseUser}
                                                userData={userData}
                                                showNewUi={showNewUi}
                                                onChangeUI={onChangeUI}
                                            />
                                            <UnprotectedPages />
                                        </SessionCacheManagerContext.Provider>
                                    </TestHooksWrapper>
                                </QueryParamProvider>
                            </Router>
                        </GlobalSnackbarWrapper>
                    </AuthContext.Provider>
                </OnlineContext.Provider>
            ) : (
                <LoadingOrb>
                    <p>Loading&hellip;</p>
                </LoadingOrb>
            )}
        </>
    );
};

const UnprotectedPages = () => {
    return (
        <Switch>
            <UnauthedRoute path="/signup/personal">
                <TrackedSignup />
            </UnauthedRoute>
            <UnauthedRoute path="/signup/patient">
                <TrackedSignup />
            </UnauthedRoute>
            <UnauthedRoute path="/signup">
                <TrackedSignup />
            </UnauthedRoute>
            <UnauthedRoute path="/login">
                <TrackedLogin />
            </UnauthedRoute>
            <UnauthedRoute path="/reset-password">
                <TrackedResetPassword />
            </UnauthedRoute>
            <Route exact path="/terms">
                <TrackedTerms />
            </Route>
        </Switch>
    );
};

const ProtectedPages = ({
    firebaseUser,
    showNewUi,
    onChangeUI,
}: Pick<ReturnType<typeof useUserData>, 'firebaseUser' | 'userData'> & {
    showNewUi?: boolean;
    onChangeUI: (on: boolean) => void;
}) => {
    return (
        <Switch>
            {/* First match order now, it worked by chance in the past */}
            <PrivateRoute exact path="/session/new/:sessionType">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    {showNewUi ? <TrackedCopySession /> : <TrackedGuidePlanner />}
                </>
            </PrivateRoute>
            <PrivateRoute path="/session/replay/:sessionId">
                <TrackedPrecomposedGuided requiredSubscription={true} />
            </PrivateRoute>
            <PrivateRoute path="/session/precomposed/:sessionId">
                <TrackedPrecomposedGuided requiredSubscription={true} />
            </PrivateRoute>
            <OptionalRoute path="/session/anonymous/:sessionId">
                <TrackedPrecomposedGuided requiredSubscription={false} />
            </OptionalRoute>
            <PrivateRoute path="/session/recording/:sessionId">
                <TrackedPrecomposedGuided requiredSubscription={false} />
            </PrivateRoute>
            <OptionalRoute path="/listen/:freePaidPathPart/:broadcastIdentifier">
                <TrackedListen />
            </OptionalRoute>
            <OptionalRoute path="/listen/:broadcastIdentifier">
                <TrackedListen />
            </OptionalRoute>

            <PrivateRoute path="/session/details/:sessionId">
                <TrackedEndedSession fbUser={firebaseUser!} />
            </PrivateRoute>
            <PrivateRoute path="/session/:sessionId">
                <TrackedGuide />
            </PrivateRoute>
            <PrivateRoute path="/resources">
                <TrackedResources />
            </PrivateRoute>
            <PrivateRoute path="/news">
                <TrackedNews />
            </PrivateRoute>
            <PrivateRoute path="/customer-support">
                <TrackedSupportPosts />
            </PrivateRoute>
            <PrivateRoute exact path="/">
                <TrackedHome firebaseUser={firebaseUser!} />
            </PrivateRoute>
            <PrivateRoute path="/templates/saved/:templateId">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedSavedTemplateDetail />
                </>
            </PrivateRoute>
            <PrivateRoute path="/templates/saved">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedSavedTemplates />
                </>
            </PrivateRoute>
            <PaidRoute path="/templates/programs">
                <Feature
                    activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                    name={Features.SESSION_LAUNCHING_V2}
                />
                <TrackedPrograms />
            </PaidRoute>
            <PrivateRoute path="/templates/:templateId">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedTemplateDetail />
                </>
            </PrivateRoute>
            <PrivateRoute path="/templates">
                <TrackedTemplates />
            </PrivateRoute>
            <PrivateRoute path="/clients">
                <TrackedClients />
            </PrivateRoute>
            <PrivateRoute path="/client/:clientId">
                <TrackedClientDetail />
            </PrivateRoute>
            <PaidRoute path="/sessions">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedActiveSessions />
                </>
            </PaidRoute>
            <PrivateRoute path="/onboarding">
                <TrackedTherapistInfo fbUser={firebaseUser!} />
            </PrivateRoute>
            <PrivateRoute path="/logs/:sessionId">
                <TrackedSessionLog firebaseUser={firebaseUser!} />
            </PrivateRoute>
            <PrivateRoute path="/profile">
                <TrackedProfile firebaseUser={firebaseUser!} />
            </PrivateRoute>
            <PrivateRoute path="/guide">
                <Redirect to="/" />
            </PrivateRoute>
            <PrivateRoute exact path="/subscriptions">
                <TrackedSubscriptions />
            </PrivateRoute>
        </Switch>
    );
};
