import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
    width: 100%;
    display: grid;
    gap: var(--global-spacing);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

export interface TemplateCardGridProps {
    children: React.ReactNode;
}

const TemplateCardGrid: React.FC<TemplateCardGridProps> = ({ children }) => <Container>{children}</Container>;

export default TemplateCardGrid;
