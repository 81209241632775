import styled from '@emotion/styled';
import React from 'react';

import { formatDate } from '../../dateUtilsV2';
import { NewsArticleRichEditor } from './NewsArticleRichEditor';
import { NewsArticle } from './useNews';

const Container = styled.div`
    position: relative;
`;

const Header = styled.div`
    padding: 16px 0 var(--global-spacing) 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-top: 4px solid var(--color-background-highlight);
`;

const Tag = styled.p`
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 8px 0;
`;

const Date = styled.p`
    font-size: var(--font-size-small);
    margin: 0;
`;

type NewsArticleProps = React.PropsWithChildren<{
    article: NewsArticle;
    isExpanded?: boolean;
    onClick?: () => void;
}>;

export function NewsArticle({ article, isExpanded = false, onClick, children }: NewsArticleProps) {
    const date = article.date ? formatDate(article.date) : undefined;
    return (
        <Container>
            <Header onClick={onClick}>
                <Tag>{article.tag}</Tag>
                <h3>{article.shortDescription}</h3>
                {date && <Date>{date}</Date>}
            </Header>
            {isExpanded && article.description && (
                <NewsArticleRichEditor value={article.description} isEditable={false} />
            )}
            {children}
        </Container>
    );
}
