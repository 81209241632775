import { useEffect, useState } from 'react';

import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import configs from '../../configs';

export function useSessionLinkForTheClient({
    broadcastIdentifier,
    version = 'classic',
}: {
    broadcastIdentifier: string;
    version?: 'classic' | 'beta';
}) {
    const { userData, isEnabled } = useAuthContext();
    const [sessionLink, setSessionLink] = useState<string>('');
    const playDemoVO = !isEnabled(Features.FREE_ACCESS);

    useEffect(() => {
        if (!userData) return;
        if (version == 'beta') {
            const baseURL = configs.bonny.BASE_URL;
            const freePaidPathPart = playDemoVO ? '/free' : '/paid';

            const finalUrl = `${baseURL}/listen${freePaidPathPart}/${broadcastIdentifier}`;
            setSessionLink(finalUrl);
        } else {
            const baseURL = configs.playerWebsite.BASE_URL;
            if (!baseURL) throw new Error('Missing baseURL.');
            const baseURLFormatted = baseURL.endsWith('/') ? baseURL : baseURL + '/';
            const finalUrl = `${baseURLFormatted}session?session=${broadcastIdentifier}`;
            setSessionLink(finalUrl);
        }
    }, [userData, version, playDemoVO]);

    return sessionLink;
}

export function useSessionLinkForTheNativeApp({
    broadcastIdentifier,
    freePaidPathPart,
}: {
    broadcastIdentifier: string;
    freePaidPathPart?: string;
}) {
    const { isEnabled } = useAuthContext();
    const playDemoVO = !isEnabled(Features.FREE_ACCESS);
    const usePlayerLib = isEnabled(Features.PLAYERLIB);

    const freudEnv = configs.freud.ENV;
    const freePaidPathPartCurrentUser = playDemoVO ? 'free' : 'paid';
    const freePaidPathPartEffective = freePaidPathPart ?? freePaidPathPartCurrentUser;
    const usePlayerLibEffective = usePlayerLib ? 'playerlib/' : '';
    const scheme = usePlayerLib ? 'wavepaths2' : 'wavepaths';
    const finalUrl = `${scheme}://listen/${freudEnv}/${freePaidPathPartEffective}/${usePlayerLibEffective}${broadcastIdentifier}`;

    return finalUrl;
}

export function useSessionLinkForControls({ sessionId }: { sessionId: string }) {
    const { userData } = useAuthContext();
    const [sessionLink, setSessionLink] = useState<string>('');
    useEffect(() => {
        if (!userData) return;
        const baseURL = configs.bonny.BASE_URL;
        const finalUrl = `${baseURL}/session/${sessionId}`;
        setSessionLink(finalUrl);
    }, [userData]);

    return sessionLink;
}
