import styled from '@emotion/styled';
import { PopoverOrigin } from '@material-ui/core';
import MaterialMenu from '@material-ui/core/Menu';
import React, { ReactElement, useState } from 'react';

import IconChevronDown from '../../images/icons/icon-chevron-down.svg';

export interface DropdownMenuOption {
    onClick?: () => void;
    element: ReactElement | string;
    icon?: ReactElement;
}

export interface DropdownMenuProps {
    options: DropdownMenuOption[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    buttonBackgroundColor?: string;
    buttonIcon?: ReactElement;
}

const Container = styled.div`
    position: relative;
    display: inline-flex;
`;

const MenuButton = styled.button<{ buttonBackgroundColor?: string }>`
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    border: none;
    border-radius: 8px;
    background-color: ${(props) => props.buttonBackgroundColor || 'transparent'};
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:hover {
        background-color: rgba(43, 45, 63, 0.1);
    }
`;

const MenuItem = styled.div`
    height: 32px;
    width: 100%;
    min-width: 160px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 8px;
    cursor: pointer;
    &:hover {
        background-color: rgba(43, 45, 63, 0.05);
    }
`;

const MenuItemIcon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MenuItemLabel = styled.div`
    font-size: var(--font-size-small);
`;

export default function DropdownMenu({
    options,
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    buttonBackgroundColor,
    buttonIcon,
}: DropdownMenuProps): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [show, setShow] = useState<boolean>(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setShow(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => {
            setShow(false);
        }, 300);
    };

    return (
        <Container>
            <MenuButton
                aria-label="More"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleClick}
                buttonBackgroundColor={buttonBackgroundColor}
            >
                {buttonIcon ? buttonIcon : <img src={IconChevronDown} alt="Dropdown" />}
            </MenuButton>
            {show && (
                <MaterialMenu
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    getContentAnchorEl={null}
                    id="menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    {options.map(({ onClick, element, icon }, idx) => (
                        <MenuItem
                            key={idx}
                            onClick={() => {
                                onClick && onClick();
                                handleClose();
                            }}
                        >
                            {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
                            {typeof element === 'string' ? <MenuItemLabel>{element}</MenuItemLabel> : element}
                        </MenuItem>
                    ))}
                </MaterialMenu>
            )}
        </Container>
    );
}
