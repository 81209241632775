import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { CoreEmotionalAtmosphere, SessionHeader } from 'wavepaths-shared/core';

import TemplateArtwork from '@/component-library/components/TemplateArtwork';

export interface ISessionAction {
    description: string;
    action: () => void;
}

export interface ISessionCardProps {
    title: string;
    subtitle: string;
    info: string[];
    session: SessionHeader;
    onClick?: () => void;
}

const Container = styled.div`
    width: 100%;
    min-width: 200px;
    max-width: 220px;
    flex: none;
    order: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
`;

const Artwork = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: filter 0.2s ease;
    &:hover {
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.1));
    }
    svg {
        display: block;
        width: 100%;
        height: auto;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Title = styled.p`
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    line-height: 1;
    margin: 0;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: var(--color-muted);
`;

const Subtitle = styled.p`
    font-size: var(--font-size-xsmall);
    line-height: 1;
    margin: 0;
`;

const Meta = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MetaItem = styled.p`
    display: flex;
    gap: 4px;
    font-size: var(--font-size-xsmall);
    line-height: 1;
    margin: 0;
`;

const SessionCard = ({
    title,
    subtitle,
    info,
    onClick,
    session,
}: PropsWithChildren<ISessionCardProps>): JSX.Element => {
    return (
        <Container onClick={onClick}>
            <Artwork>
                <TemplateArtwork
                    randomSeed={session.id}
                    intensity={'none'} //TODO
                    primaryEmotion={session.emotionalities?.primary ?? CoreEmotionalAtmosphere.SILENCE}
                    secondaryEmotion={session.emotionalities?.secondary ?? CoreEmotionalAtmosphere.SILENCE}
                    tertiaryEmotion={session.emotionalities?.tertiary ?? CoreEmotionalAtmosphere.SILENCE}
                />
            </Artwork>
            <Info>
                <Title>{title}</Title>
                <Description>
                    <Subtitle>{subtitle}</Subtitle>
                    <Meta>{info?.length && <MetaItem>{info[0]}</MetaItem>}</Meta>
                </Description>
            </Info>
        </Container>
    );
};

export default SessionCard;
