import 'firebase/auth';
import 'firebase/database';
import './images/favicon.ico';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fontsource/tenor-sans';
import '@fontsource/roboto-mono/500.css';

import { StylesProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';
import { replace } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';

import { App } from './App';
import theme from './common/styles/theme';
import configs from './configs';
import events from './UserEvents';

Sentry.init({
    dsn: 'https://b74b73e6b1704696963df5686dd1c9dd@sentry-relay.wavepaths.com/5257987',
    release: replace(process.env.RELEASE ?? '', '/', '-'),
    maxBreadcrumbs: 100,
    environment: configs.sentry.ENVIRONMENT,
    enabled: process.env.DISABLE_SENTRY ? false : true,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    transportOptions: {
        maxQueueSize: 100,
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'This browser does not support ResizeObserver'],
});

Sentry.configureScope((scope) => {
    scope.setTag('documentVisibility', document.visibilityState);
});

document.addEventListener('visibilitychange', function () {
    Sentry.configureScope((scope) => {
        scope.setTag('documentVisibility', document.visibilityState);
    });
});

events.init();

firebase.initializeApp(configs.firebase);

ReactDOM.render(
    <GenericErrorBoundary>
        {/**
         * By default, the style tags are injected last in the <head> element of the page.
         * They gain more specificity than any other style tags on your page e.g. CSS modules, styled components.
         *
         * By adding the StylesProvider with injectFirst we will give priority to styled, withStyles, makeStyles
         * over default MUI theme settings
         */}
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </StylesProvider>
    </GenericErrorBoundary>,
    document.getElementById('root'),
);
