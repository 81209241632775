import { makeStyles, Theme } from '@material-ui/core/styles';
import date from 'date-and-time';
import { isNumber } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useHistory, useParams } from 'react-router-dom';
import {
    EndOfSessionFeedback,
    ScoreLibrary,
    ScoreSelectionCriteria,
    Session,
    SESSION_SCORE_MODALITIES,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';
import * as Scores from 'wavepaths-shared/domain/scores';

import { AuthContext } from '../../auth';
import * as sessionApi from '../../common/api/sessionApi';
import {
    EndOfSessionFeedbackInput,
    isValidEndOfSessionFeedback,
} from '../../common/components/EndOfSessionFeedbackInput';
import { Modal } from '../../common/components/Modal';
import { formatDateTime } from '../../dateUtilsV2';
import UserEvents from '../../UserEvents';
import { SessionVariableInputs } from './SessionVariableInputs';
import useScorePlanner from './useScorePlanner';

export interface GuidePlannerParams {
    sessionType: SessionType;
}

interface GuidePlannerProps {
    sessionType: SessionType;
    scoreLibrary: ScoreLibrary;
}

const useStyles = makeStyles<Theme>({
    guideInfinitePlanner: {
        padding: '40px 0 120px 0',
        overflowY: 'auto',
    },
    wrapper: {
        width: '100%',
        maxWidth: 550,
        margin: '0 auto',
    },
});

export function GuidePlanner({ scoreLibrary }: GuidePlannerProps) {
    const { sessionType } = useParams<GuidePlannerParams>() as GuidePlannerParams;

    const history = useHistory();
    const authCtx = useContext(AuthContext);

    const defaultSelectionCriteria =
        sessionType === SessionType.ONE_ON_ONE
            ? { emotionalIntensity: SessionScoreEmotionalIntensity.LOW, medicine: SessionScoreModality.KETAMINE }
            : undefined;
    const [selectionCriteria, setSelectionCriteria] = useState<Partial<ScoreSelectionCriteria> | undefined>(
        defaultSelectionCriteria,
    );

    const [sessionAttributeInputs, setSessionAttributeInputs] = useState<Partial<Session>>({
        unlisted: true,
    });
    const [sessionsAwaitingFeedback, setSessionsAwaitingFeedback] = useState<Session[]>([]);
    const [previousEndOfSessionFeedback, setPreviousEndOfSessionFeedback] = useState<EndOfSessionFeedback>();
    const [startingSession, setStartingSession] = useState(false);

    const { score, sessionVariableInputs, updateVariableInputs, setSessionScorePreset } = useScorePlanner(
        scoreLibrary,
        sessionType,
    );

    useEffect(() => {
        if (sessionType === 'groupGuided' || sessionType === 'groupInfinite') {
            const newScore = scoreLibrary.sessionScores.find((s) => s.sessionTypes.indexOf(sessionType) >= 0);
            newScore && setSessionScorePreset(newScore);
        } else {
            pickScorePresetForSelectionCriteria(selectionCriteria, scoreLibrary);
        }
    }, [sessionType, selectionCriteria]);

    const pickScorePresetForSelectionCriteria = (
        criteria: Partial<ScoreSelectionCriteria> | undefined,
        lib = scoreLibrary,
    ) => {
        const newScore = Scores.pickRandomScoreForCriteria(lib.sessionScores, sessionType, criteria);

        if (!newScore) return;

        setSelectionCriteria(criteria);
        setSessionScorePreset(newScore);
    };

    const startSession = useCallback(() => {
        const filteredLayerIds = window.location.search
            ? window.location.search
                  .substring(1)
                  .split(',')
                  .map((id) => +id)
            : [];
        const attributeInputs: Partial<Session> = {
            ...sessionAttributeInputs,
            scheduledStart: sessionAttributeInputs.scheduledStart || getDefaultScheduledStart(),
        };
        const renderType =
            sessionType == SessionType.GROUP_INFINITE ? SessionRenderType.PRE_RENDERED : SessionRenderType.REAL_TIME;
        const urlPostfix = renderType == SessionRenderType.PRE_RENDERED ? 'precomposed/' : '';

        authCtx.firebaseUser &&
            sessionApi
                .startSession(
                    sessionType,
                    renderType,
                    {
                        ...score,
                        wavepaths: score.wavepaths.map((wp) => ({ ...wp, pathScore: undefined })),
                    },
                    sessionVariableInputs,
                    attributeInputs,
                    [],
                    filteredLayerIds,
                    authCtx.firebaseUser,
                    [],
                )
                .then((res) => {
                    const streamStart = res.scheduledStart;
                    //TODO : dont rely on Browser time
                    if (streamStart !== undefined && (streamStart === 0 || streamStart <= Date.now() + 1000)) {
                        window.location.href = `/session/${urlPostfix}${res.id}`;
                    } else {
                        history.push(`/`);
                    }
                });
        setStartingSession(true);
    }, [sessionType, score, sessionVariableInputs, sessionAttributeInputs, authCtx]);

    const sendPreviousEndOfSessionFeedback = useCallback(() => {
        if (!authCtx.firebaseUser) return;
        const session = sessionsAwaitingFeedback[0];
        previousEndOfSessionFeedback &&
            sessionApi
                .sendPreviousSessionFeedback(session.id, previousEndOfSessionFeedback, authCtx.firebaseUser)
                .then(() => setPreviousEndOfSessionFeedback(undefined));
        previousEndOfSessionFeedback && UserEvents.feedbackRecorded(previousEndOfSessionFeedback);
    }, [sessionsAwaitingFeedback, previousEndOfSessionFeedback, authCtx]);

    const dismissPreviousEndOfSessionFeedback = useCallback(async () => {
        for (const prev of sessionsAwaitingFeedback) {
            authCtx.firebaseUser && (await sessionApi.dismissPreviousSessionFeedback(prev.id, authCtx.firebaseUser));
        }
        setSessionsAwaitingFeedback([]);
        setPreviousEndOfSessionFeedback(undefined);
    }, [sessionsAwaitingFeedback, authCtx]);

    const classes = useStyles();

    return (
        <div className={classes.guideInfinitePlanner}>
            {score && (
                <>
                    <div className={classes.wrapper}>
                        <SessionVariableInputs
                            score={score}
                            values={sessionVariableInputs}
                            onUpdateValues={updateVariableInputs}
                            groupChildren={{
                                sessionSettings:
                                    sessionType === 'groupGuided' || sessionType === 'groupInfinite' ? (
                                        <div className="sessionVariableInputs--input inputGroup">
                                            <label htmlFor="scoreInput">Score</label>
                                            <select
                                                id="scoreInput"
                                                value={score.name}
                                                onChange={(evt) => {
                                                    const newScore = scoreLibrary.sessionScores.find(
                                                        (s) => s.name === evt.currentTarget.value,
                                                    );
                                                    newScore && setSessionScorePreset(newScore);
                                                }}
                                            >
                                                {scoreLibrary.sessionScores
                                                    .filter((score) => score.sessionTypes.indexOf(sessionType) >= 0)
                                                    .map((score, idx) => (
                                                        <option key={idx} value={score.name}>
                                                            {score.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div className="sessionVariableInputs--input inputGroup">
                                            <label htmlFor="emotionalIntensityInput">Medicine</label>
                                            <select
                                                id="medicineInput"
                                                value={selectionCriteria && selectionCriteria.medicine}
                                                onChange={(evt) =>
                                                    pickScorePresetForSelectionCriteria({
                                                        ...selectionCriteria,
                                                        medicine: evt.target.value as SessionScoreModality,
                                                    })
                                                }
                                            >
                                                {SESSION_SCORE_MODALITIES.map((medicine) => (
                                                    <option
                                                        key={medicine}
                                                        value={medicine}
                                                        disabled={
                                                            !Scores.isMedicineAvailableForScores(
                                                                medicine,
                                                                scoreLibrary.sessionScores,
                                                            )
                                                        }
                                                    >
                                                        {medicine}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ),
                                timing:
                                    sessionType !== 'groupInfinite' &&
                                    renderScheduledSessionInputs(
                                        sessionAttributeInputs,
                                        sessionVariableInputs,
                                        (k, v) =>
                                            setSessionAttributeInputs({
                                                ...sessionAttributeInputs,
                                                [k]: v,
                                            }),
                                    ),
                                website: (sessionType === 'groupGuided' || sessionType === 'groupInfinite') && (
                                    <>
                                        {renderGroupSessionInputs(sessionAttributeInputs, (k, v) =>
                                            setSessionAttributeInputs({
                                                ...sessionAttributeInputs,
                                                [k]: v,
                                            }),
                                        )}
                                    </>
                                ),
                            }}
                        />
                        <div className={'guideSubmissionContainer'}>
                            <button
                                className="button button--primary"
                                onClick={startSession}
                                disabled={startingSession}
                            >
                                {getStartButtonText(sessionAttributeInputs, sessionVariableInputs, startingSession)}
                            </button>
                            <span className="preludeHint" style={{ marginLeft: 5 }}>
                                {!startingSession && getPreludeHintText(sessionAttributeInputs, sessionVariableInputs)}
                            </span>
                        </div>
                    </div>
                </>
            )}
            {sessionsAwaitingFeedback.length > 0 && (
                <Modal>
                    <EndOfSessionFeedbackInput
                        title={`Please provide feedback on your ${getSessionDurationMinutes(
                            sessionsAwaitingFeedback[0],
                        )} minute ${renderSessionType(sessionsAwaitingFeedback[0].type)} from ${getSessionStartTime(
                            sessionsAwaitingFeedback[0],
                        )}`}
                        feedback={previousEndOfSessionFeedback}
                        onUpdateFeedback={setPreviousEndOfSessionFeedback}
                    />
                    <button
                        className="primaryAction"
                        onClick={sendPreviousEndOfSessionFeedback}
                        disabled={!isValidEndOfSessionFeedback(previousEndOfSessionFeedback)}
                    >
                        Send
                    </button>
                    <button className="closeModal" onClick={dismissPreviousEndOfSessionFeedback}>
                        X
                    </button>
                </Modal>
            )}
        </div>
    );
}

function renderScheduledSessionInputs(
    attributes: Partial<Session>,
    variableInputs: SessionVariables,
    onUpdate: (k: keyof Session, v: any) => void,
) {
    return (
        <>
            <div className="sessionVariableInputs--input inputGroup">
                <label htmlFor="scheduledStartInput">Scheduled start</label>
                <DateTimePicker
                    value={new Date(attributes.scheduledStart || getDefaultScheduledStart())}
                    onChange={(d: Date) => onUpdate('scheduledStart', d.getTime())}
                    minDate={new Date()}
                    locale="en-GB"
                />
            </div>
        </>
    );
}

//MK20231031 In the past scheduledStart was coming from the Browser but its not reliable, so we pass 1 as 'now'
// we correct it here so all the legacy code can still use the absolute timestamp
// ideally this should come from same server that stamps end time
function getDefaultScheduledStart(): number {
    return 1;
}

function getStartButtonText(
    sessionAttributes: Partial<Session>,
    variableInputs: SessionVariables,
    startingSession: boolean,
) {
    const sessionStart = sessionAttributes.scheduledStart || getDefaultScheduledStart();
    //TODO: dont rely on Browser time clock
    if (sessionStart > Date.now()) {
        if (startingSession) {
            return <>Scheduling session&hellip;</>;
        } else {
            return <>Schedule session for {formatDateTime(sessionStart).toLowerCase()}</>;
        }
    } else {
        if (startingSession) {
            return <>Starting session&hellip;</>;
        } else {
            return <>Start session now</>;
        }
    }
}

function getPreludeHintText(sessionAttributes: Partial<Session>, variableInputs: SessionVariables) {
    const preludeStart = sessionAttributes.scheduledStart || getDefaultScheduledStart();
    const firstWaveStart =
        preludeStart + (isNumber(variableInputs.preludeDuration) ? variableInputs.preludeDuration * 60 * 1000 : 0);
    if (preludeStart < Date.now()) {
        if (firstWaveStart == preludeStart) {
            return 'with no prelude';
        } else {
            return `with a prelude now and the session in ${variableInputs.preludeDuration} mins`;
        }
    } else if (preludeStart < firstWaveStart) {
        return `starting first with a ${variableInputs.preludeDuration} min prelude`;
    } else {
        return 'with no prelude';
    }
}

function renderGroupSessionInputs(attributes: Partial<Session>, onUpdate: (k: keyof Session, v: any) => void) {
    return (
        <>
            <div className="sessionVariableInputs--input inputGroup">
                <label htmlFor="unlisted">Unlisted</label>
                <input
                    id="unlisted"
                    type="checkbox"
                    checked={attributes.unlisted}
                    onChange={() => onUpdate('unlisted', !attributes.unlisted)}
                />
            </div>
        </>
    );
}

function getSessionDurationMinutes(session: Session) {
    if (session.startedAt && session.endedAt) {
        const dur = session.endedAt - session.startedAt;
        return Math.round(dur / 1000 / 60);
    } else {
        return '?';
    }
}

function getSessionStartTime(session: Session) {
    if (session.startedAt) {
        return date.format(new Date(session.startedAt), 'dddd, MMMM DD h:mmA');
    } else {
        return '?';
    }
}

export function renderSessionType(type: SessionType) {
    switch (type) {
        case 'groupGuided':
            return 'Guided Jung Session';
        case 'groupInfinite':
            return 'Infinite Jung Session';
        case 'oneOnOne':
            return 'Session';
    }
}
