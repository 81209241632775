import styled from '@emotion/styled';
import React from 'react';
import { AdministrationRoute, CoreEmotionalAtmosphere, DosageLevel, SessionScoreModality } from 'wavepaths-shared/core';
import { formatDurationMinutes } from 'wavepaths-shared/util/dateUtils';

import AdministrationIcon from '@/component-library/components/icons/AdministrationIcon';
import DosageIcon from '@/component-library/components/icons/DosageIcon';
import IntensityIcon, { IntensityIconProps } from '@/component-library/components/icons/IntensityIcon';

import IconQuickstart from '../../../images/icons/icon-quickstart.svg';
import TemplateArtwork from './TemplateArtwork';

const Container = styled.div`
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
`;

const Artwork = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: filter 0.2s ease;
    &:hover {
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.1));
    }
    svg {
        display: block;
        width: 100%;
        height: auto;
    }
`;

const QuickStartImage = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Title = styled.p<{ variant: 'dark' | 'light' }>`
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    line-height: 1;
    margin: 0;
    color: ${(props) => (props.variant === 'dark' ? 'var(--color-primary)' : 'rgba(255, 255, 255, 1)')};
`;

const Description = styled.div<{ variant: 'dark' | 'light' }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${(props) => (props.variant === 'dark' ? 'var(--color-muted)' : 'rgba(255, 255, 255, 0.75)')};
`;

const Subtitle = styled.p`
    font-size: var(--font-size-xsmall);
    line-height: 1;
    margin: 0;
`;

const Meta = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MetaItem = styled.div`
    display: flex;
    gap: 4px;
    font-size: var(--font-size-xsmall);
    line-height: 1;
    margin: 0;
`;

export interface TemplateCardProps {
    onCardClick: (id: string) => void;
    imageUrl?: string;
    className?: string;
    id: string;
    title: string;
    subtitle: string;
    intensity: IntensityIconProps['intensity'];
    modality?: SessionScoreModality;
    administration?: AdministrationRoute;
    dosage?: DosageLevel;
    minDurationMins: number;
    maxDurationMins: number;
    emotionalities: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
    variant?: 'dark' | 'light';
    isQuickStart?: boolean;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
    onCardClick,
    id,
    title,
    subtitle,
    intensity,
    minDurationMins,
    maxDurationMins,
    emotionalities,
    administration,
    dosage,
    variant = 'dark',
    isQuickStart = false,
}) => (
    <Container
        onClick={() => {
            onCardClick('id');
        }}
    >
        <Artwork>
            <TemplateArtwork
                randomSeed={id}
                intensity={intensity}
                primaryEmotion={emotionalities.primary}
                secondaryEmotion={emotionalities.secondary}
                tertiaryEmotion={emotionalities.tertiary}
            />
            {isQuickStart && <QuickStartImage src={IconQuickstart} alt="Start Session using this Template" />}
        </Artwork>
        <Info>
            <Title variant={variant}>{title}</Title>
            <Description variant={variant}>
                <Subtitle>{subtitle}</Subtitle>
                <Meta>
                    <MetaItem>
                        <IntensityIcon intensity={intensity} />
                    </MetaItem>
                    <MetaItem>
                        <span>
                            {formatDurationMinutes(minDurationMins * 60 * 1000)} -{' '}
                            {formatDurationMinutes(maxDurationMins * 60 * 1000)}
                        </span>
                    </MetaItem>
                    {administration && (
                        <MetaItem>
                            <AdministrationIcon administration={administration} />
                        </MetaItem>
                    )}
                    {dosage && (
                        <MetaItem>
                            <DosageIcon dosage={dosage} />
                        </MetaItem>
                    )}
                </Meta>
            </Description>
        </Info>
    </Container>
);

export default TemplateCard;
