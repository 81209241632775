import styled from '@emotion/styled';
import React from 'react';
import { isSafari } from 'react-device-detect';

import { useSessionLinkForTheNativeApp } from '@/util/broadcastLinkUtils';

import LinkExternalIcon from '../../../../images/icons/icon-link-external.svg';
import { PlayerChoice } from '../PlayerChoiceContext';
import AppStoreContent from './AppStoreContent';
import { BrowserPlayerDeviceSelector } from './BrowserPlayerDeviceSelector';
import PlayerAppMessage from './PlayerAppMessage';
import TextDivider from './TextDivider';

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Tabs = styled.div`
    width: 100%;
    display: flex;
    margin-top: -8px;
`;

const Tab = styled.button<{ isActive: boolean }>`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 2px solid ${({ isActive }: { isActive: boolean }) => (isActive ? '#103DA8' : 'transparent')};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ isActive }: { isActive: boolean }) => (isActive ? '#103DA8' : '#344054')};
`;

const TabContent = styled.div`
    display: flex;
    gap: 16px;
`;

const TabPanel = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const AppUpdateMessage = styled.div`
    background: #faeae8;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const AppUpdateMessageHeading = styled.h3`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: #cf2020;
`;

const AppUpdateMessageText = styled.p`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #cf2020;
    a {
        color: #cf2020;
        text-decoration: underline;
        font-weight: normal;
    }
`;

const OpenAppLink = styled.a`
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: 1px solid #475467;
    border-radius: 8px;
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #2b2d3f;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    stroke: #475467;
`;

function PlayOnLocalDevice({
    broadcastIdentifier,
    playerChoice,
    browserPlayerOutputDevice,
    isPlayerAppSupported,
    isWebPlayerSupported,
    freePaidPathPartForPlayerAppLink,
    onSetPlayerChoice,
    onSetBrowserPlayerOutputDevice,
}: {
    broadcastIdentifier: string;
    playerChoice: PlayerChoice;
    browserPlayerOutputDevice: string | undefined;
    isPlayerAppSupported: boolean;
    isWebPlayerSupported: boolean;
    freePaidPathPartForPlayerAppLink?: string;
    onSetPlayerChoice: (playerChoice: PlayerChoice) => void;
    onSetBrowserPlayerOutputDevice: (deviceId: string) => void;
}): React.ReactElement {
    const appStoreLink = 'https://apps.apple.com/app/wavepaths-player/id6479019124';
    const appLink = useSessionLinkForTheNativeApp({
        broadcastIdentifier,
        freePaidPathPart: freePaidPathPartForPlayerAppLink,
    });

    const isDeviceSelectionSupported = !isSafari; // Whilst Safari supports getUserMedia(), it does not actually return output devices when you list devices so it's useless for this purpose.

    return (
        <Container>
            {isPlayerAppSupported && isWebPlayerSupported && (
                <Tabs>
                    <Tab onClick={() => onSetPlayerChoice('browser')} isActive={playerChoice === 'browser'}>
                        Web browser
                    </Tab>
                    <Tab onClick={() => onSetPlayerChoice('native')} isActive={playerChoice === 'native'}>
                        Player app
                    </Tab>
                </Tabs>
            )}
            <TabContent>
                {playerChoice === 'browser' && (
                    <TabPanel>
                        {isPlayerAppSupported && <PlayerAppMessage appStoreLink={appStoreLink} />}
                        {isDeviceSelectionSupported && (
                            <BrowserPlayerDeviceSelector
                                device={browserPlayerOutputDevice}
                                onSetDevice={onSetBrowserPlayerOutputDevice}
                            />
                        )}
                    </TabPanel>
                )}
                {playerChoice === 'native' && (
                    <TabPanel>
                        <AppStoreContent appStoreLink={appStoreLink} />
                        <TextDivider text="Then" />
                        <AppUpdateMessage>
                            <AppUpdateMessageHeading>Update your Player app</AppUpdateMessageHeading>
                            <AppUpdateMessageText>
                                Please ensure you have the{' '}
                                <a href={appStoreLink} target="_blank" rel="noreferrer">
                                    latest version of the Player app
                                </a>{' '}
                                installed so it works with this Session.
                            </AppUpdateMessageText>
                        </AppUpdateMessage>
                        <OpenAppLink href={appLink} target="_blank" rel="noreferrer">
                            Open in Player app
                            <Icon src={LinkExternalIcon} alt="External link icon" />
                        </OpenAppLink>
                    </TabPanel>
                )}
            </TabContent>
        </Container>
    );
}

export default PlayOnLocalDevice;
