import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import React, { useContext, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { Session, SessionRenderType } from 'wavepaths-shared/core';

import { Button, Menu } from '@/component-library';
import { SubscribeModal } from '@/components';

import { useAuthContext } from '../../../auth';
import { Header } from '../../../common/components/Header';
import { AudioDownload } from '../AudioDownload';
import { PlayerChoiceContext } from '../deviceOptions/PlayerChoiceContext';
import { SessionInfo } from '../SessionInfo';
import { SessionName } from '../SessionName';

interface PrecomposedGuideHeader {
    session: Session;
    headerCenterText?: string;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

export const PrecomposedGuideHeader: React.FC<PrecomposedGuideHeader> = React.memo(({ session, headerCenterText }) => {
    const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
    const classes = useStyles();

    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState<boolean>(false);

    const { isPersonal, firebaseUser } = useAuthContext();

    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const menuActions = [
        ...(isPersonal || !firebaseUser || session.renderType !== SessionRenderType.PRE_RENDERED
            ? []
            : [
                  {
                      element: 'Session Info',
                      icon: <InfoIcon />,
                      onClick: () => setSessionInfoOpen(true),
                  },
              ]),
        {
            element: 'Playback Settings',
            icon: <MobileScreenShareIcon />,
            onClick: () => playerChoiceContext.openDialog(),
        },
        {
            element: 'Go Back To Dashboard',
            icon: <ArrowBackIcon />,
            onClick: () => (window.location.href = '/'),
        },
    ];

    return (
        <>
            <SubscribeModal isOpen={!!upgradeModalIsOpen} closeModal={() => setUpgradeModalIsOpen(false)} />

            <Header
                left={<SessionName session={session} />}
                center={headerCenterText}
                right={
                    <div
                        className="tour-sessionOptions"
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}
                    >
                        <>
                            {session.renderType !== SessionRenderType.PREDICTIVE_COMPOSED && !isSafari && (
                                <AudioDownload />
                            )}
                            {isPersonal || !firebaseUser || session.renderType !== SessionRenderType.PRE_RENDERED ? (
                                <></>
                            ) : (
                                <Button
                                    variant="outlined"
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.4)',
                                        border: '1px solid rgba(255, 255, 255, 0.4)',
                                        borderRadius: '75px',
                                    }}
                                    onClick={() => playerChoiceContext.openDialog()}
                                    icon={<MobileScreenShareIcon />}
                                    aria-label="Volume"
                                />
                            )}
                        </>
                        <Menu
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            options={menuActions}
                        />
                        <Dialog
                            classes={{ paper: classes.paper }}
                            open={sessionInfoOpen}
                            onClose={() => setSessionInfoOpen(false)}
                        >
                            <DialogContent>
                                <SessionInfo closeDialog={() => setSessionInfoOpen(false)} session={session} />
                            </DialogContent>
                        </Dialog>
                    </div>
                }
            />
        </>
    );
});
