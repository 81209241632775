import styled from '@emotion/styled';
import React from 'react';
import Ratings from 'react-ratings-declarative';
import * as uuid from 'uuid';
import { EndOfSessionFeedback } from 'wavepaths-shared/core';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const InputRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const InputLabel = styled.div`
    padding-bottom: 2px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;

const Required = styled.sup`
    color: #ff0000;
`;

const AdditionalFeedback = styled.div`
    padding: 16px 0;
    textarea {
        width: 100%;
        height: 60px;
    }
`;

interface EndOfSessionFeedbackProps {
    title: string;
    feedback?: EndOfSessionFeedback;
    onUpdateFeedback: (newFeedback: EndOfSessionFeedback) => void;
}
export const EndOfSessionFeedbackInput: React.FC<EndOfSessionFeedbackProps> = React.memo(
    ({ title, feedback, onUpdateFeedback, children }) => {
        const onChangeRating = (key: keyof EndOfSessionFeedback, rating: number) => {
            onUpdateFeedback({
                ...(feedback || { feedback: '', rating: -1, id: uuid.v4() }),
                [key]: rating,
            });
        };
        const onChangeFeedback = (text: string) => {
            onUpdateFeedback({
                ...(feedback || { rating: -1, id: uuid.v4() }),
                feedback: text,
            });
        };

        return (
            <Container>
                <Header>
                    <h3>{title}</h3>
                </Header>
                <Body>
                    <InputRow>
                        <InputLabel>
                            Overall experience<Required>*</Required>
                        </InputLabel>
                        <StarRating
                            rating={feedback?.rating || 0}
                            onChangeRating={(r) => onChangeRating('rating', r)}
                        />
                    </InputRow>
                    <InputRow>
                        <InputLabel>Technical quality</InputLabel>
                        <StarRating
                            rating={feedback?.technicalQualityRating || 0}
                            onChangeRating={(r) => onChangeRating('technicalQualityRating', r)}
                        />
                    </InputRow>
                    <InputRow>
                        <InputLabel>Music quality</InputLabel>
                        <StarRating
                            rating={feedback?.musicQualityRating || 0}
                            onChangeRating={(r) => onChangeRating('musicQualityRating', r)}
                        />
                    </InputRow>
                    <InputRow>
                        <InputLabel>Ease of use</InputLabel>
                        <StarRating
                            rating={feedback?.easeOfUseRating || 0}
                            onChangeRating={(r) => onChangeRating('easeOfUseRating', r)}
                        />
                    </InputRow>
                    <AdditionalFeedback>
                        <InputLabel>Additional feedback</InputLabel>
                        <textarea value={feedback?.feedback} onChange={(evt) => onChangeFeedback(evt.target.value)} />
                    </AdditionalFeedback>
                    {children}
                </Body>
            </Container>
        );
    },
);

interface StarRatingProps {
    rating: number;
    onChangeRating: (newRating: number) => void;
}
export const StarRating: React.FC<StarRatingProps> = ({ rating, onChangeRating }) => (
    <Ratings
        rating={rating}
        widgetEmptyColors="#D8D8D8"
        widgetHoverColors="#FFC100"
        widgetRatedColors="#FFC100"
        widgetDimensions="20px"
        widgetSpacings="0px"
        changeRating={onChangeRating}
    >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
    </Ratings>
);

export function isValidEndOfSessionFeedback(feedback?: EndOfSessionFeedback) {
    return feedback && feedback.rating > 0 && feedback.rating <= 5;
}
