import { AxiosError } from 'axios';
import firebase from 'firebase/app';
import {
    EndOfSessionFeedback,
    FirebaseUser,
    RecordSessionFeedbackEvent,
    ScoreLibrary,
    Session,
    SessionLog,
    SessionPageResponse,
    SessionPlan,
    SessionScoreDTO,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';
import {
    clientOptionType,
    CollectionStatus,
    MidSessionFeedbackSubmitted,
    SessionFeedback,
    SessionRenderType,
} from 'wavepaths-shared/types/sessions/core';

import configs from '../../configs';
import axios from '../util/axios';

export const FREUD_BASE_URL = configs.freud.BASE_URL;

export class TooMuchTrafficError extends Error {
    constructor() {
        super('Too Much Traffic Error');
        Object.setPrototypeOf(this, TooMuchTrafficError.prototype);
    }
}

export async function loadScoreLibrary(fbUser: firebase.User): Promise<ScoreLibrary> {
    return axios(`${FREUD_BASE_URL}/scores`, {
        headers: {
            Authorization: `idToken ${await fbUser.getIdToken()}`,
            'Cache-Control': 'no-cache', // the server should control this but this is to bust it for clients with caches from previously, who might never hit the server otherwise
        },
    }).then((res) => res.data);
}

export async function listEndedSessionsV2(
    maxNumber: number,
    page: number,
    fbUser: firebase.User,
): Promise<SessionPageResponse> {
    const offset = maxNumber * page;
    return axios(`${FREUD_BASE_URL}/v2/sessions/ended?max=${maxNumber}&offset=${offset}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function listEndedSessionsV2ByType(
    maxNumber: number,
    page: number,
    fbUser: firebase.User,
    type: string,
): Promise<SessionPageResponse> {
    const offset = maxNumber * page;
    return axios(`${FREUD_BASE_URL}/v2/sessions/ended?max=${maxNumber}&offset=${offset}&type=${type}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function submitInSessionFeedback(
    sessionId: string,
    feedback: SessionFeedback,
    dspTimeMs: number,
    fbUser: firebase.User,
): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/in-session-feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({
            fbUser,
            feedback,
            dspTimeMs,
        }),
    }).then((res) => res.status === 200);
}

export async function getMyIntegrationAnswers(
    sessionId: string,
    fbUser: firebase.User,
): Promise<{
    //TODO: prisma ORM model please?
    //CY: probs shouldnt couple directly to prisma, but it would be useful to share a DTO type for the API somehow
    answers: {
        experience_rating: number;
        experience_summary: string;
        most_remember: string;
        insights: string;
        confusions: string;
        other: string;
    };
}> {
    return axios(`${FREUD_BASE_URL}/api/v1/sessionIntegration/my/${sessionId}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export type IntegrationResponse = {
    userName: string;
    userId: string;
    integration: {
        created_at: string;
        answers: {
            experience_rating: number;
            experience_summary: string;
            most_remember: string;
            insights: string;
            confusions: string;
            other: string;
        };
    };
};
export async function getAllIntegrationAnswers(
    sessionId: string,
    fbUser: firebase.User,
): Promise<{ entries: IntegrationResponse[] }> {
    return axios(`${FREUD_BASE_URL}/my/session-integration-answers?sessionId=${sessionId}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function saveIntegrationAnswers(
    sessionId: string,

    answers: {
        experience_rating: number;
        experience_summary: string;
        most_remember: string;
        insights: string;
        confusions: string;
        other: string;
    },
    fbUser: firebase.User,
): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/api/v1/sessionIntegration/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        //TODO: Prisma model?
        data: JSON.stringify({
            session_id: sessionId,
            answers,
            user_id: fbUser.uid,
        }),
    }).then((res) => res.status === 200);
}

export async function sendPreviousSessionFeedback(
    sessionId: string,
    feedback: EndOfSessionFeedback,
    fbUser: firebase.User,
): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/awaiting_feedback/${sessionId}/feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify(feedback),
    }).then((res) => res.status === 200);
}

export async function dismissPreviousSessionFeedback(sessionId: string, fbUser: firebase.User): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/awaiting_feedback/${sessionId}/feedback`, {
        method: 'POST',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => res.status === 200);
}

// export async function listSessionSignups(sessionId: string, fbUser: firebase.User): Promise<FirebaseUser[]> {
//     return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/signups`, {
//         headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
//     }).then((res) => (res.status === 200 ? res.data : null));
// }

export async function upsertSessionSignup(sessionId: string, fbUser: firebase.User): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/available/signups`, {
        method: 'POST',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({ sessionId }),
    }).then((res) => (res.status === 200 ? true : false));
}

export async function prolongSession(sessionId: string): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/prolong`, {
        method: 'POST',
    }).then((res) => (res.status === 200 ? true : false));
}

export async function deleteSessionSignup(
    sessionId: string,
    firebaseUserId: string,
    fbUser: firebase.User,
): Promise<FirebaseUser[]> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/signups/${firebaseUserId}`, {
        method: 'DELETE',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function getSession(id: string, fbUser?: firebase.User, anonymousToken?: string): Promise<Session> {
    return axios(
        `${FREUD_BASE_URL}/sessions/${id}`,
        fbUser
            ? {
                  headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
              }
            : {
                  headers: { Authorization: `anonymous ${anonymousToken}` },
              },
    ).then((res) => (res.status === 200 ? res.data : null));
}

export async function getSessionByBroadcastIdentifier(
    broadcastIdentifier: string,
    fbUser?: firebase.User,
    anonymousToken?: string,
): Promise<Session> {
    return axios(
        `${FREUD_BASE_URL}/sessions/my/${broadcastIdentifier}`,
        fbUser
            ? {
                  headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
              }
            : {
                  headers: { Authorization: `anonymous ${anonymousToken}` },
              },
    ).then((res) => (res.status === 200 ? res.data : null));
}

export async function startSessionEarly(
    sessionId: string,
    fbUser?: firebase.User,
    anonymousToken?: string,
): Promise<Session> {
    let endpointPrefix = '';
    if (!fbUser && anonymousToken) {
        endpointPrefix = 'my/';
    }
    return axios(`${FREUD_BASE_URL}/sessions/${endpointPrefix}${sessionId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: fbUser ? `idToken ${await fbUser.getIdToken()}` : `anonymous ${anonymousToken}`,
        },
        //MK20231031 In the past scheduledStart was coming from the Browser but its not reliable, so we pass 1 as 'now'
        // we correct it here so all the legacy code can still use the absolute timestamp
        // ideally this should come from same server that stamps end time
        data: JSON.stringify({ scheduledStart: 1 }),
    }).then((res) => res.data);
}

export async function startSession(
    type: SessionType,
    renderType: SessionRenderType,
    sessionScore: SessionScoreDTO,
    sessionVariableInputs: SessionVariables,
    attributeInputs: Partial<Session>,
    plan: SessionPlan,
    filteredLayerIds: number[],
    fbUser: firebase.User,
    selectedClients: clientOptionType[],
    contentStatusesToInclude: CollectionStatus[] = ['Approved'],
    quickFadesEnabled = false,
): Promise<Session> {
    return axios(`${FREUD_BASE_URL}/sessions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({
            type,
            renderType,
            sessionScore,
            sessionVariableInputs: {
                ...sessionVariableInputs,
                // Note: prelude duration is becoming less of a thing as we give therapists and users
                // the ability to start their sessions early, so we're just hardcoding at 240 for
                // one-on-one sessions as a reasonably long default value. - Willis Plummer, 02.02.21
                preludeDuration:
                    renderType === SessionRenderType.PRE_RENDERED
                        ? 0
                        : type === SessionType.ONE_ON_ONE
                        ? 240
                        : sessionVariableInputs.preludeDuration,
                postludeDuration:
                    renderType === SessionRenderType.PRE_RENDERED ? 0 : sessionVariableInputs.postludeDuration,
            },
            plan: [],
            filteredLayerIds,
            contentStatusesToInclude,
            quickFadesEnabled,
            selectedClients,
            ...attributeInputs,
        }),
    })
        .then((res) => res.data)
        .catch((error: AxiosError) => {
            if (error.response?.status === 503) throw new TooMuchTrafficError();
            throw new Error('Network Error');
        });
}

export async function deleteSession(
    sessionId: string,
    audioLatency: number | undefined,
    fbUser: firebase.User,
): Promise<void> {
    await axios(`${FREUD_BASE_URL}/sessions/${sessionId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `idToken ${await fbUser.getIdToken()}`,
        },
        data: { audioLatency },
    });
}

export async function getLog(sessionId: string, fbUser: firebase.User): Promise<SessionLog> {
    return axios(`${FREUD_BASE_URL}/session_logs/${sessionId}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => res.data);
}

export type SessionFeedbackResponse = {
    event: RecordSessionFeedbackEvent;
    dspTimeMs: number;
    timestamp: number;
    endOfSession: boolean;
    feedback: MidSessionFeedbackSubmitted;
};
export async function getSessionFeedbacks(
    sessionId: string,
    fbUser: firebase.User,
): Promise<SessionFeedbackResponse[]> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/feedbacks`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}
