import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Button, EvaIcon } from '@/component-library';
import Confirm from '@/component-library/components/Dialog';
import { SessionCacheContext } from '@/hooks/useAudioCache';

const CacheContainer = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 75px;
    background-color: rgba(255, 255, 255, 0.4);
`;

export const AudioDownload: React.FC = () => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmDownloaded, setShowConfirmDownloaded] = useState(false);

    const cacheContext = useContext(SessionCacheContext);
    if (!cacheContext) {
        return <></>;
    }
    const audioCache = cacheContext.audioCache;

    const onClick =
        audioCache.status == 'idle' ||
        audioCache.status == 'failed' ||
        audioCache.status == 'deleting' ||
        audioCache.status == 'stopped'
            ? () => cacheContext.cacheSession()
            : audioCache.status == 'done'
            ? () => setShowConfirmDownloaded(true)
            : audioCache.status == 'downloading' || audioCache.status == 'generating'
            ? () => setShowConfirmDelete(true)
            : undefined;

    const onDelete = () => {
        cacheContext.deleteSession();
        setShowConfirmDownloaded(false);
        setShowConfirmDelete(false);
    };

    const onStop = () => {
        cacheContext.stopCacheSession();
        setShowConfirmDownloaded(false);
        setShowConfirmDelete(false);
        cacheContext.deleteSession();
    };

    const canShowDownload = ['idle', 'deleting', 'stopped'].includes(audioCache.status);
    return (
        <CacheContainer>
            <Button
                onClick={onClick}
                style={{
                    backgroundColor: audioCache.status == 'done' ? 'rgba(46, 204, 113, 0.5)' : 'inherit',
                }}
            >
                {canShowDownload && (
                    <>
                        <EvaIcon name="download-outline" size={25} fill="#2c3958" />
                        <span>Download</span>
                    </>
                )}
                {audioCache.status == 'failed' ? (
                    <>
                        <EvaIcon name="download-outline" size={25} fill="#2c3958" />
                        <span>Failed, try again?</span>
                    </>
                ) : (
                    <></>
                )}
                {audioCache.status == 'downloading' || audioCache.status == 'generating' ? (
                    <>
                        <EvaIcon name="loader-outline" size={25} fill="#2c3958" />
                        <span>{audioCache.percentCompleted} % Downloading</span>
                    </>
                ) : (
                    <></>
                )}
                {audioCache.status == 'done' ? (
                    <>
                        <EvaIcon name="download-outline" size={25} fill="#2c3958" />
                        <span>Downloaded</span>
                    </>
                ) : (
                    <></>
                )}
            </Button>
            <Confirm
                fullWidth={true}
                open={showConfirmDelete}
                title={`Downloading in Progress`}
                message={`Once the download is complete, this session will be available for offline playback. You can find all your downloaded sessions on the product home page.`}
                onClose={onStop}
                cancelText={`Stop`}
                onConfirm={() => setShowConfirmDelete(false)}
                confirmText={'Continue'}
            />
            <Confirm
                fullWidth={true}
                open={showConfirmDownloaded}
                title={`Ready for Offline Playback`}
                message={`This session is downloaded and ready for offline playback. You can access this and all your downloaded sessions on the product home page.`}
                onConfirm={() => setShowConfirmDownloaded(false)}
                confirmText={'Got it!'}
                onClose={onDelete}
                cancelText={`Delete`}
            />
        </CacheContainer>
    );
};
