import { Session, SessionHeader } from 'wavepaths-shared/core';

export function fetchExpirationInfo(session: Session | SessionHeader) {
    let isExpired = session.isDeletedRecording;
    let expirationMessage = '';

    const expirationDate = session.deleteRecordingAt ? new Date(session.deleteRecordingAt) : null;

    if (session.endedAt && session.startedAt && expirationDate) {
        const now = new Date();

        if (now > expirationDate || isExpired) {
            isExpired = true;
            expirationMessage = 'The recording of this session is no longer available';
        } else {
            expirationMessage = `This recording will expire on ${expirationDate.toLocaleDateString()}`;
        }
    }

    return { isExpired, expirationMessage };
}
