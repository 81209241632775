import { makeStyles } from '@material-ui/core/styles';
import { convertToRaw, EditorState } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import React, { useState } from 'react';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    editor: {
        backgroundColor: 'transparent',
        padding: 0,
    },
});

export type NewsArticleRichEditorProps = {
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    isEditable?: boolean;
};

export const NewsArticleRichEditor = (props: NewsArticleRichEditorProps) => {
    const classes = useStyles();

    const { value, onChange, placeholder = 'Enter text...', isEditable } = props;

    // Local state to manage the editor's internal state
    const [initialValue] = useState(value);

    // Prevent the editor from updating in an infinite loop
    const onSaveState = (newEditorState: EditorState) => {
        if (onChange) {
            const rawContent = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));
            if (rawContent !== value) {
                onChange(rawContent);
            }
        }
    };

    return (
        <MUIRichTextEditor
            label={placeholder}
            value={initialValue}
            controls={isEditable ? ['bold', 'italic', 'underline', 'link'] : []}
            readOnly={!isEditable}
            toolbar={isEditable}
            inlineToolbar={isEditable}
            onChange={onSaveState}
            classes={{
                root: classes.root,
                editor: classes.editor,
            }}
        />
    );
};
