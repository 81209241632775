import styled from '@emotion/styled';
import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import React, { useContext, useState } from 'react';
import { Session, SessionRenderType } from 'wavepaths-shared/core';
import { formatDurationMinutes, formatDurationSeconds } from 'wavepaths-shared/util/dateUtils';

import { EvaIcon, Menu } from '@/component-library';
import { useSessionRemoteTick } from '@/hooks';

import { Header } from '../../common/components/Header';
import IconButton from '../../common/components/IconButton';
import { formatDuration } from '../../dateUtilsV2';
import SettingsIcon from '../../images/icons/icon-settings.svg';
import { AudioPlayerContext } from './AudioPlayerProvider';
import { PlayerChoiceContext } from './deviceOptions/PlayerChoiceContext';
import { SessionInfo } from './SessionInfo';
import { SessionName } from './SessionName';
import { VolumeMeter } from './VolumeMeter';

interface GuideHeaderProps {
    session: Session;
    onEndSession: () => void;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

// const LongBufferSwitch = React.memo(({ connection }: { connection: FreudConnection }) => {
//     const [isLowConnectionModeEnabled, setLowConnectionMode] = useState<boolean>(connection.isLongBufferEnabled);

//     const handleToggleLongBuffer = () => {
//         const newVal = !isLowConnectionModeEnabled;
//         Sentry.configureScope((scope) => {
//             scope.setTag('usingSlowConnectionMode', `${newVal}`);
//         });

//         connection.toggleLongBuffer(newVal);
//         setLowConnectionMode(newVal);
//     };

//     return (
//         <div
//             onClick={handleToggleLongBuffer}
//             style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
//         >
//             <Typography variant="body3" style={{ textAlign: 'right' }}>
//                 I'm on a slow connection
//             </Typography>
//             <Checkbox style={{ padding: 0, margin: '0 8px' }} size="small" checked={isLowConnectionModeEnabled} />
//         </div>
//     ); // tried using <FormGroup/> as in the docs but got some weird double firing of the onChange handler...
// });

const StyledHeader = styled(Header)`
    width: 100%;
    padding: 8px 40px;
    @media (min-height: 720px) {
        padding: 24px 40px;
    }
`;

const SessionHeaderInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

const SessionDuration = styled.div`
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #2b2d3f;
`;

const SessionBufferSize = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #2b2d3f;
`;

const Timer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 24px;
    background-color: #2b2d3f;
    font-family: inherit;
    font-weight: 600;
    color: white;
    font-size: 20px;
    height: 32px;
    border-radius: 16px;
    padding: 0px 16px;
    @media (min-height: 720px) {
        font-size: 24px;
        height: 48px;
        border-radius: 24px;
        padding: 0px 24px;
    }
`;

const SessionOptionButtons = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

const ShareSessionButton = styled(IconButton)`
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    border: none;
    border-radius: 50%;
    background-color: rgba(43, 45, 63, 0.05);
    &:hover {
        background-color: rgba(43, 45, 63, 0.1);
        border: none;
    }
`;

const ShareSessionButtonIcon = styled.img`
    width: 20px;
    height: 20px;
`;

export const GuideHeader: React.FC<GuideHeaderProps> = React.memo(({ session, onEndSession }: GuideHeaderProps) => {
    const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
    const classes = useStyles();

    const tick = useSessionRemoteTick();
    const timeElapsedMs = tick ? tick.absoluteTime : 0;
    const totalDurationMs = (tick && tick?.sessionDuration) ?? 0;

    const audioPlayerContext = useContext(AudioPlayerContext);
    const currentBufferSizeSecs = audioPlayerContext?.currentBufferSizeSecs;
    const bufferFormatted =
        currentBufferSizeSecs !== undefined
            ? currentBufferSizeSecs > 60
                ? formatDurationMinutes(currentBufferSizeSecs * 1000)
                : formatDurationSeconds(currentBufferSizeSecs * 1000)
            : undefined;

    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const menuActions = [
        {
            element: 'Controls and Playback',
            icon: <MobileScreenShareIcon />,
            onClick: () => playerChoiceContext.openDialog(),
        },
        {
            element: 'Session Info',
            icon: <InfoIcon />,
            onClick: () => setSessionInfoOpen(true),
        },
        {
            element: 'Go Back To Dashboard',
            icon: <ArrowBackIcon />,
            onClick: () => (window.location.href = '/'),
        },
        {
            element: 'End Session for All',
            icon: <CloseIcon />,
            onClick: onEndSession,
        },
    ];

    return (
        <StyledHeader
            left={
                <SessionHeaderInfo>
                    <SessionName session={session} />
                    <SessionDuration>{totalDurationMs > 0 ? formatDuration(totalDurationMs) : '00:00'}</SessionDuration>
                    {/* <MobileScreenShareIcon /> */}
                    {session.renderType === SessionRenderType.PREDICTIVE_COMPOSED &&
                        bufferFormatted !== undefined &&
                        currentBufferSizeSecs !== undefined && (
                            <SessionBufferSize>
                                <EvaIcon
                                    name="wifi-outline"
                                    size={24}
                                    fill={
                                        currentBufferSizeSecs > 60
                                            ? 'green'
                                            : currentBufferSizeSecs > 20
                                            ? 'orange'
                                            : 'red'
                                    }
                                />
                                <span style={{ width: '100px' }}>{bufferFormatted}</span>
                            </SessionBufferSize>
                        )}
                </SessionHeaderInfo>
            }
            center={<Timer>{timeElapsedMs > 0 ? formatDuration(timeElapsedMs) : '00:00'}</Timer>}
            right={
                <div className="tour-sessionOptions" style={{ display: 'flex', gap: 40 }}>
                    {/* <LongBufferSwitch connection={connection} /> */}
                    <VolumeMeter />
                    <SessionOptionButtons>
                        <ShareSessionButton
                            variant="clear"
                            onClick={() => playerChoiceContext.openDialog()}
                            icon={<ShareSessionButtonIcon src={SettingsIcon} />}
                            aria-label="Volume"
                        />
                        <Menu
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            options={menuActions}
                        />
                    </SessionOptionButtons>
                    <Dialog
                        classes={{ paper: classes.paper }}
                        open={sessionInfoOpen}
                        onClose={() => setSessionInfoOpen(false)}
                    >
                        <DialogContent>
                            <SessionInfo closeDialog={() => setSessionInfoOpen(false)} session={session} />
                        </DialogContent>
                    </Dialog>
                </div>
            }
        />
    );
});
