import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { isAdmin as checkIsAdmin } from 'wavepaths-shared/domain/user';

import { Button } from '@/component-library';
import Confirm from '@/component-library/components/Dialog';
import DashboardPageGuidance from '@/components/Dashboard/DashboardPageGuidance';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';

import { useAuthContext } from '../../auth';
import { ResourceUpsertData } from '../../common/api/resourcesApi';
import { LayoutContainer } from '../../LayoutContainer';
import { Resource } from './Resource';
import { ResourceForm, ResourceFormState } from './ResourceForm';
import {
    useResources,
    useResourcesArchive,
    useResourcesCreate,
    useResourcesUnarchive,
    useResourcesUpdate,
} from './useResources';

const Container = styled.div``;

const Section = styled.div`
    margin-bottom: 50px;
`;

const Description = styled.div`
    margin-bottom: 32px;
`;

const AdminActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    gap: 16px;
`;

const Tag = styled.div`
    display: flex;
    align-items: center;
    background-color: #ff9800;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
`;

const Content = styled.div``;

const openResource = (id: string) => ({ isOpen: true, resourceId: id });
const closed = () => ({ isOpen: false });

const sections = [
    {
        id: 'section1',
        title: 'Getting Started',
        description:
            'Short explanations and directions to start with. We strongly advise reviewing these resources before running your first sessions.',
    },
    {
        id: 'section2',
        title: 'Deep Dives',
        description: 'In-depth explanations. These resources will help you unlock the full potential of the platform.',
    },
    {
        id: 'section3',
        title: 'Educational Materials',
        description:
            'Theory, guidelines and recommendations for best practices. Designed for providers or as materials that can be directly shared with clients.',
    },
    {
        id: 'section4',
        title: 'Additional Resources',
        description: 'Learn more about Wavepaths.',
    },
];

const initialIsSectionResourcesArchivedIncluded = sections.reduce((acc, section) => {
    acc[section.id] = true;
    return acc;
}, {} as { [key: string]: boolean });

function ResourcesContainer() {
    const { userData } = useAuthContext();
    const isAdmin = !!userData && checkIsAdmin(userData);
    const [formDialogState, setFormDialogState] = useState<{
        isOpen: boolean;
        resourceId?: string;
        sectionId?: string;
    }>(closed);
    const [confirmArchiveState, setConfirmArchiveState] = useState<{ isOpen: boolean; resourceId?: string }>(closed);
    const [confirmUnarchiveState, setConfirmUnarchiveState] = useState<{ isOpen: boolean; resourceId?: string }>(
        closed,
    );
    const [isIncludeArchived] = useState(isAdmin);

    const [isSectionResourcesArchivedIncluded, setIsSectionResourcesArchivedIncluded] = useState(
        initialIsSectionResourcesArchivedIncluded,
    );

    const { resources, isLoading, refresh } = useResources({
        includeArchived: isIncludeArchived,
    });
    const { mutate: create } = useResourcesCreate({
        onSuccess: refresh,
    });
    const { mutate: update } = useResourcesUpdate({
        onSuccess: refresh,
    });
    const { mutate: archive } = useResourcesArchive({
        onSuccess: refresh,
    });
    const { mutate: unarchive } = useResourcesUnarchive({
        onSuccess: refresh,
    });

    const [resource, setResource] = useQueryParam<string | undefined>('resource');

    const resourceSelected = (resources || []).find((n) => n.id === resource);

    const onSubmitDialog = (resource: ResourceFormState) => {
        const data: ResourceUpsertData = {
            tag: resource.tag,
            description: resource.description,
            shortDescription: resource.shortDescription,
            publishedAt: resource.date?.toISOString(),
            sectionId: formDialogState?.sectionId,
        };
        const promise = formDialogState.resourceId ? update(formDialogState.resourceId, data) : create(data);
        promise.then(() => setFormDialogState(closed)).catch((e) => console.error(e));
    };

    const onArchive = (id: string) => {
        archive(id)
            .then(() => setConfirmArchiveState(closed))
            .catch((e) => console.error(e));
    };

    const onUnarchive = (id: string) => {
        unarchive(id)
            .then(() => setConfirmUnarchiveState(closed))
            .catch((e) => console.error(e));
    };

    const resourceInForm = useMemo(() => {
        if (!formDialogState.isOpen) {
            return undefined;
        }
        return resources?.find((n) => n.id === formDialogState.resourceId) || undefined;
    }, [formDialogState.isOpen, formDialogState.resourceId, resources]);

    let resourcesBySection = useMemo(() => {
        return sections.map((s) => {
            return {
                section: s,
                resources: resources.filter((r) => r.sectionId === s.id),
            };
        });
    }, [resources]);

    if (resourceSelected) {
        resourcesBySection = resourcesBySection.filter(({ section }) => section.id === resourceSelected.sectionId);
    }

    return (
        <Content>
            <DashboardPageHeading pageTitle="Resources" />
            <DashboardPageGuidance
                pageTitle="Resources"
                guidanceText="Here you’ll find a variety of resources to help you get the best out of Wavepaths; from getting started to becoming a more advanced user."
            />
            {resourcesBySection.map(({ section, resources }) => (
                // eslint-disable-next-line react/jsx-key
                <Section>
                    <div key={section.id}>
                        <h2>{section.title}</h2>
                        <Description>
                            <p>{section.description}</p>
                        </Description>
                        {isAdmin && (
                            <AdminActions>
                                <Button
                                    variant="solid-blue"
                                    onClick={() =>
                                        setFormDialogState({
                                            isOpen: true,
                                            resourceId: undefined,
                                            sectionId: section.id,
                                        })
                                    }
                                >
                                    Add new
                                </Button>

                                {isSectionResourcesArchivedIncluded[section.id] ? (
                                    <Button
                                        variant="solid-blue"
                                        onClick={() =>
                                            setIsSectionResourcesArchivedIncluded((prev) => ({
                                                ...prev,
                                                [section.id]: false,
                                            }))
                                        }
                                    >
                                        Hide archived
                                    </Button>
                                ) : (
                                    <Button
                                        variant="solid-blue"
                                        onClick={() =>
                                            setIsSectionResourcesArchivedIncluded((prev) => ({
                                                ...prev,
                                                [section.id]: true,
                                            }))
                                        }
                                    >
                                        Show archived
                                    </Button>
                                )}

                                {formDialogState.isOpen ? (
                                    <Dialog
                                        open={true}
                                        onClose={() =>
                                            setFormDialogState({
                                                isOpen: false,
                                                resourceId: undefined,
                                                sectionId: section.id,
                                            })
                                        }
                                        fullWidth={true}
                                        maxWidth={'md'}
                                    >
                                        <DialogTitle>Add new Resource</DialogTitle>
                                        <DialogContent>
                                            <ResourceForm initialState={resourceInForm} onSubmit={onSubmitDialog} />
                                        </DialogContent>
                                    </Dialog>
                                ) : (
                                    <></>
                                )}

                                <Confirm
                                    fullWidth={true}
                                    open={confirmArchiveState.isOpen}
                                    message={'Are you sure you want to archive this resource?'}
                                    onClose={() => setConfirmArchiveState(closed)}
                                    onConfirm={() =>
                                        confirmArchiveState.resourceId && onArchive(confirmArchiveState.resourceId)
                                    }
                                    confirmText={'Yes, ARCHIVE'}
                                />

                                <Confirm
                                    fullWidth={true}
                                    open={confirmUnarchiveState.isOpen}
                                    message={'Are you sure you want to restore this resource?'}
                                    onClose={() => setConfirmUnarchiveState(closed)}
                                    onConfirm={() =>
                                        confirmUnarchiveState.resourceId &&
                                        onUnarchive(confirmUnarchiveState.resourceId)
                                    }
                                    confirmText={'Yes, RESTORE'}
                                />
                            </AdminActions>
                        )}
                        <Container>
                            {resourceSelected && (
                                <Resource
                                    key={resourceSelected.id}
                                    resource={resourceSelected}
                                    onClick={() => setResource(undefined)}
                                    isExpanded
                                />
                            )}

                            {isLoading && <>Loading...</>}

                            {!resourceSelected &&
                                !isLoading &&
                                resources.map((n) => {
                                    if (!isSectionResourcesArchivedIncluded[section.id] && n.isArchived) return null;

                                    return (
                                        <Resource key={n.id} resource={n} onClick={() => setResource(n.id)}>
                                            {isAdmin && (
                                                <AdminActions>
                                                    {n.isArchived && <Tag>Archived</Tag>}

                                                    <Button
                                                        variant="solid-blue"
                                                        onClick={() => setFormDialogState(openResource(n.id))}
                                                    >
                                                        Edit
                                                    </Button>

                                                    {n.isArchived ? (
                                                        <Button
                                                            variant="solid-blue"
                                                            onClick={() => setConfirmUnarchiveState(openResource(n.id))}
                                                        >
                                                            Restore
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="solid-blue"
                                                            onClick={() => setConfirmArchiveState(openResource(n.id))}
                                                        >
                                                            Archive
                                                        </Button>
                                                    )}
                                                </AdminActions>
                                            )}
                                        </Resource>
                                    );
                                })}
                            {resources.filter((resource) => resource.sectionId === section.id).length === 0 &&
                                !isLoading &&
                                !resourceSelected && <p>No resources available</p>}
                        </Container>
                    </div>
                </Section>
            ))}
        </Content>
    );
}

function ResourcesWithNav() {
    return (
        <LayoutContainer>
            <ResourcesContainer />
        </LayoutContainer>
    );
}

export default ResourcesWithNav;
