import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import IconClose from '../../../../src/images/icons/icon-close.svg';

const Container = styled(motion.div)`
    position: relative;
    width: 100%;
    padding: 24px 68px 24px 24px;
    border-radius: 8px;
    background: var(--color-background-highlight);
    margin-bottom: 24px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
`;

const Icon = styled.span`
    display: block;
    width: 16px;
    height: 16px;
    img {
        display: block;
        width: 100%;
    }
`;

const Content = styled.p`
    width: 100%;
    max-width: 600px;
    font-size: var(--font-size-small);
    margin: 0;
`;

export interface DashboardPageGuidanceProps {
    pageTitle: string;
    guidanceText: string;
}

const DashboardPageGuidance: React.FC<DashboardPageGuidanceProps> = ({ pageTitle, guidanceText }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const dismissed = localStorage.getItem(`dashboardPageGuidanceDismissed_${pageTitle}`);
        if (!dismissed) {
            setIsVisible(true);
        }
    }, [pageTitle]);

    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem(`dashboardPageGuidanceDismissed_${pageTitle}`, 'true');
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <Container
                    initial={{ opacity: 1, height: 'auto' }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <CloseButton onClick={handleClose} role="button" aria-label="Hide">
                        <Icon>
                            <img src={IconClose} alt="Hide" />
                        </Icon>
                    </CloseButton>
                    <Content>{guidanceText}</Content>
                </Container>
            )}
        </AnimatePresence>
    );
};

export default React.memo(DashboardPageGuidance);
