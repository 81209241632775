import styled from '@emotion/styled';
import React from 'react';
import { useQueryParam } from 'use-query-params';
import { SessionScoreModality } from 'wavepaths-shared/core';

import DashboardPageGuidance from '@/components/Dashboard/DashboardPageGuidance';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';

import { LayoutContainer } from '../../LayoutContainer';
import { TemplatesContainer } from '../templates/all';
import { Program } from './Program';
import { useTemplatePrograms } from './useTemplatePrograms';

const Programs = styled.div``;

const Content = styled.div``;

function ProgramsContainer() {
    const { programs } = useTemplatePrograms();
    const [program, setProgram] = useQueryParam<string | undefined>('program');
    const [, setModality] = useQueryParam<SessionScoreModality | undefined>('modality');

    return (
        <Content>
            <DashboardPageHeading pageTitle="Programs" />
            <DashboardPageGuidance
                pageTitle="Programs"
                guidanceText="Our programs are collections of musical templates curated to offer a suggested sequence
                     that you can select and tailor to your client's needs."
            />
            <Programs>
                {programs.map((p) =>
                    !!program && program !== p.id ? (
                        <></>
                    ) : (
                        <>
                            <Program
                                key={p.id}
                                program={p}
                                onClick={() => {
                                    if (program == p.id) {
                                        setProgram(undefined);
                                        setModality(undefined);
                                    } else {
                                        setProgram(p.id);
                                        setModality(p.modality);
                                    }
                                }}
                                isExpandedDefault={!!program && p.id === program}
                            >
                                {!!program ? <TemplatesContainer program={program} /> : <></>}
                            </Program>
                        </>
                    ),
                )}
            </Programs>
        </Content>
    );
}

function ProgramsWithNav() {
    return (
        <LayoutContainer>
            <ProgramsContainer />
        </LayoutContainer>
    );
}

export default ProgramsWithNav;
